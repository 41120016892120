import React, {  useEffect, useState,} from 'react';

import { DataStore } from '@aws-amplify/datastore';
import { User ,ClubUser,Club} from '../../models';

export default  async function StatusChecks (userID){
    console.log(userID)
    try{
      const u = await  DataStore.query(User,u => u.UserID("eq", userID))
     
       return (u[0].onlineStatus ? u[0].onlineStatus : false)
    }catch(err){
            console.log(err)
            return false
    }
 

}