import { useEffect, useState } from 'react';
import Image from 'react-bootstrap/Image';
import { DataStore } from '@aws-amplify/datastore';
import { User, Club, ClubUser,ClubPost,NFTCollection ,NewPost} from '../models';
import { NavLink } from 'react-router-dom';
import awsconfig from '../aws-exports';
import "../assets/fonts/Poppins-Regular.ttf"
import "../assets/fonts/Poppins-Bold.ttf"
import "../assets/fonts/Poppins-Black.ttf"
import { CDBBtn, CDBCard } from "cdbreact";
import { Amplify, Auth, Storage } from "aws-amplify";
import { Modal } from 'react-bootstrap';
import '../styles/fonts/fonts.css'
import { TwitterTimelineEmbed, } from 'react-twitter-embed';
import twitter from '../assets/icons/Solid/white/twitter.svg'

import Card from 'react-bootstrap/Card';
import { Divider } from '@aws-amplify/ui-react';
import { 
  ZeplinApi, 
  Configuration 
}  from "@zeplin/sdk";
import SplashScreen from '../SplashScreen';
import { FiGlobe } from 'react-icons/fi';
import BlueButton from '../screens/components/BlueButton';

const zeplin = new ZeplinApi(
  new Configuration({ 
    accessToken: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoicGVyc29uYWxfYWNjZXNzX3Rva2VuIiwiY2xpZW50X2lkIjoiNjM3OTNmNWIzMjlkZDAxNDQ0OTg3N2JhIiwic2NvcGUiOiIiLCJpYXQiOjE2Njg4OTA0NTksImV4cCI6MTk4NDQ1OTcxOSwiaXNzIjoiemVwbGluOmFwaS56ZXBsaW4uaW8iLCJzdWIiOiI2MzQ5YmY1YTllMTY4NTE4NWViMDI5OTQiLCJqdGkiOiI0NzA3MjU5MC02NmU4LTQ3NTItYTkyMC05OWQzNjE1M2I3NjIifQ.1NwbZ3UN-ClQNwdT4QyKyto7l7Yn7DF_z400omxDkX4"
  })
);


Storage.configure({ track: true, level: "public" });
Amplify.configure({ ...awsconfig, ssr: true });





export default function MobileClubPage(props){
    const [CurrentUser, setUser] = useState(null);
    const[loading,setLoading] = useState(true);
    const[isMember,setMember]=useState(false);
    const[memberView,setMemberModal]=useState(false);
    const[clubID,setClubID]=useState(null);
    const [clubBanner,getBanner]=useState();
    const [clubNFTS,getNFTS]=useState([]);
    const [clubBio,setBio]=useState('');
    const[members,GetMembers]=useState([]);
    const[posts,GetPosts]=useState([]);
    const[newposts,GetNewPosts]=useState([]);
    const [nftCollections,getCollection]=useState([{"name":"No Upcoming Collections"}]);
    const [liveCollections,getLiveCollection]=useState([]);
    const [modalShow, setModalShow] = useState(false);
    useEffect(()=>{
      setLoading(true)
       getClubData();
      
        getUserData();

  

    },[]);

    async function getClubData(){

        try{
      
   
            setBio(props.clubInfo.bio)
            Storage.get("Clubs/"+props.clubInfo.ClubStorageID+"/ClubBanner.png",{

            }).then((ban)=>{
                getBanner(ban)
            })
            Storage.list("Clubs/"+props.clubInfo.ClubStorageID+"/NFTimages/",{

            }).then((nfts)=>{
                let t2 = []
         
                nfts.forEach((n)=>{
                  if(n.key.includes(".png") ||n.key.includes(".jpg" ) || n.key.includes(".gif" )){
                    Storage.get(n.key).then((nftImage)=>{
                        t2.push(nftImage)
                        getNFTS(t2)
                       
                    })}
                })
            }).catch((err)=>{
            
              let tempCollection =[]
              Storage.get("ComingSoon.png").then((comingSoon)=>{
                tempCollection.push(comingSoon)
                getNFTS(tempCollection)
              })
            })
            DataStore.query(ClubUser).then((mem)=>{
                
              
               let tempUserArray = []
               mem.filter(c => c.club.name == props.clubInfo.name).forEach((userPic)=>{
                let fileLink  = "Users/"+userPic.user.UserID+"/Pro.png"
                let bannerLink  = "Users/"+userPic.user.UserID+"/Banner.png"
                Storage.get(fileLink,{

                }).then((pro)=>{
                  Storage.get(bannerLink,{

                  }).then((bann)=>{
                    let joi = new Date(userPic.createdAt)
                    tempUserArray.push({
                      "img":pro,
                      "banner":bann,
                      "bio":userPic.user.bio,
                      "username":userPic.user.preferred_username,
                      "link":userPic.user.preferred_username.replaceAll(' ',''),
                      "createdAt":joi
                     })
                     GetMembers(tempUserArray)
                  }).catch((err)=>console.log(err))
                 
               
                }).catch((err)=>console.log(err))
                 
                
                })
            })

            DataStore.query(NewPost,(u)=>u.clubID('eq',props.clubInfo.id)).then((np)=>{
              if(np.length > 0){
                let tempNew = []

                np.forEach((indPost)=>{
                  var timeCreated = new Date(indPost.createdAt);
                  var currentdate = new Date();
                
       
                        let tp =''
                    
                     if(timeCreated.getHours() >= 12){
                      let hourPM = timeCreated.getHours()-12
                      let mins = parseInt(timeCreated.getMinutes()) < 10 ? "0"+ timeCreated.getMinutes() : timeCreated.getMinutes();
                    tp=  timeCreated.toLocaleDateString() +' - '+hourPM +":"+mins+" pm"
                     }else{
                      let mins = parseInt(timeCreated.getMinutes()) < 10 ? "0"+ timeCreated.getMinutes() : timeCreated.getMinutes();

                      tp=  timeCreated.toLocaleDateString() +' - '+timeCreated.getHours() +":"+mins+" am"
                  
                     }
                  if(indPost.video != null){
                
                Storage.get(indPost.video,{

                }).then((vid)=>{
                  tempNew.push({...indPost,"videoLinks":vid,"time":tp})
                }).catch(err=>console.log(err))
                    
                  }else if(indPost.image != null){
                    
                    Storage.get(indPost.image,{

                    }).then((imgLink)=>{
                      tempNew.push({...indPost,"imageLink":imgLink,"time":tp})
                    }).catch(err=>console.log(err))
                  }else{
                    tempNew.push({...indPost,"time":tp})
                  }
                  console.log(tempNew)
                    GetNewPosts(tempNew)

                })
              }      
            
                    

      }).catch((err)=>{
        console.log(err)
      })



            DataStore.query(ClubPost).then((p)=>{
                    let tempPost = []
                    if( p.filter(c => c.club.name == props.clubInfo.name).length == 0){
                      Storage.get("noPost.png").then((noPost)=>{
                        let tempPost =[{
                          "message":'Club has no post yet.',
                          "title":null,
                          "media":noPost
                        }]
                        GetPosts(tempPost)
                        setLoading(false)
                      })
                  
        
                    }else{
                      p.filter(c => c.club.name == props.clubInfo.name)
                      .sort((a,b) => b._lastChangedAt -a._lastChangedAt).forEach((mes)=>{
                 
                        var timeCreated = new Date(mes.createdAt);
                        var currentdate = new Date();
                      
             
                              let tp =''
                          
                           if(timeCreated.getHours() >= 12){
                            let hourPM = timeCreated.getHours()-12
                          tp=  timeCreated.toLocaleDateString() +' - '+hourPM +":"+timeCreated.getMinutes()+" pm"
                           }else{
                            tp=  timeCreated.toLocaleDateString() +' - '+timeCreated.getHours() +":"+timeCreated.getMinutes()+" am"
                        
                           }
                             
                          Storage.get(mes.post.media,{
                             
                          }).then((res)=>{
                          
                            tempPost.push({...mes.post,"media":mes.post.media ? res : null,time:tp})
                            GetPosts(tempPost)
                         
                          }).catch((err)=>{
                            tempPost.push({...mes.post,"time":tp})
                            GetPosts(tempPost)
                          })
                          
                  
                    
                      
                          
                          
                         
                      })}
             
            }).catch((err)=>{
              console.log(err)
            
            })

            DataStore.query(NFTCollection, cID => cID.clubID('eq',props.clubInfo.id)).then((clubCollections)=>{
              let tempCollection =[]
              clubCollections.forEach((clubColl)=>{
           
                if(clubColl != null && clubColl.signupLive){
                  let collName= clubColl.name.replace(/ /g, '');
                
                  Storage.get("Clubs/"+props.clubInfo.ClubStorageID+"/Collections/"+collName+"/Secret.png").then((cList)=>{
                    
             
                    
                      tempCollection.push({
                        "name":clubColl.name,
                        "description":clubColl.description,
                        "image":cList
                       })
                       getCollection(tempCollection)
                 
                  }).catch((err)=>{
                    let tempCollection =[]
                    Storage.get("ComingSoon.png").then((comingSoon)=>{
                      tempCollection.push({
                        "name":"Coming Soon!",
                        "description":"No Upcoming Collections",
                        "image":comingSoon
                       })
                       getCollection(tempCollection)
                    })
                  })
                }
              })
              
         
            }).catch((err)=>{
              let tempCollection =[]
        
              Storage.get("ComingSoon.png").then((comingSoon)=>{
                tempCollection.push({
                  "name":"Coming Soon!",
                  "description":"No Upcoming Collections",
                  "image":comingSoon
                 })
                 getCollection(tempCollection)
              })
            })
        }catch(err){
            setLoading(false)
        }
    }
     function getUserData(){
      
        
      
   
            if(props.props!==null){
              setUser(props.props)
              checkUserMembership() 
            }else if(props.props!==null || CurrentUser !== null){
              Auth.currentAuthenticatedUser({ bypassCache: true }).then((userInfo)=>{
             
                if(userInfo){ 
                           setUser(userInfo)
                         
                          
                       }else{
                        setUser(null)
                        setLoading(false)
             
                       }
                       checkUserMembership(); 
              }).catch((err)=>{
                setLoading(false)
              })
            }else{
             setLoading(false)
            }
          
            
                   
        }
        
     
               /*
    async  function getDuplicates(){
      setLoading(true)
      try{
        
        const dupUser = await DataStore.query(User)
        const dClub = await  DataStore.query(ClubUser)
           dupUser.forEach((dU)=>{
            
            
              let tempDup = dClub.filter((u)=>u.user.UserID.toLowerCase()==dU.UserID.toLowerCase()&& u.club.id.toLowerCase()=="be357ced-b89c-4e80-99b2-a4765bc3c7b3")
      
              if(tempDup[1]){
              console.log(tempDup)
               tempDup.forEach(async (td,index)=>{
              
                 if(index != 0){
                  
      // await  DataStore.delete(td)
                 }
              
               })
              
           //    be357ced-b89c-4e80-99b2-a4765bc3c7b3 
             //  addef8b4-7c6e-403d-a1b5-ba922e375894
              }
           
           })
         
         
      
      }catch(err){
          console.log(err)
          setLoading(false)
      }
      setLoading(false)
  
    }
**/
    
        
  async function checkUserMembership(){
    setLoading(true)
    console.log(props.props)
    try{
      const ans = await DataStore.query(ClubUser)
  
      let temp =CurrentUser? ans.filter((u)=>u.user.UserID.toLowerCase()==CurrentUser.username.toLowerCase()&& u.club.id.toLowerCase()==props.clubInfo.id.toLowerCase()) 
      : ans.filter((u)=>u.user.UserID.toLowerCase()==props.props.username.toLowerCase()&& u.club.id.toLowerCase()==props.clubInfo.id.toLowerCase())
      console.log(temp)
      if(temp[0]){
      
        setMember(true)
      }else{
        setMember(false)
      }
      if(temp[1]){
        temp.forEach(async (td,index)=>{
          if(index != 0){
          await  DataStore.delete(td);
          }
       
        })
  
      }
    }catch(err){
      
        console.log(err)
        setMember(false)
        setLoading(false)
     
    }
 
    
          setLoading(false)
     
    
     
              



         
       /*      DataStore.query(ClubUser).then((checker)=>{
              if(props.props != null){
                checker.forEach((k)=>{
                  if(k.club.ClubStorageID == props.clubInfo.ClubStorageID){
                  if(k.user.UserID == props.props.username){
                      
                      setMember(true)

                  
                      
                  }}
              })
              setLoading(false)
              }else if(CurrentUser !== null){
                DataStore.query(User, u => u.UserID("eq", CurrentUser.username)).then((c)=>{
                
                  checker.forEach((k)=>{
                    if(k.club.ClubStorageID == props.clubInfo.ClubStorageID){
                      if(k.user.UserID == c[0].UserID){
                     
                          setMember(true)
                          setLoading(false)
                          
                      }}
                  })
                  
                 }).catch((err)=>{
                  setLoading(false)
    
                })
              }else{
                setLoading(false)
              }
                
              
              
              setLoading(false)
            }).catch((err)=>{
              setLoading(false)

            })
**/
        

    }
    
  
 
    const addUser = ()=>{
      setLoading(true)
      if((CurrentUser !== null || props.props !== null) && isMember == false){
        addUserToClub()
      }

      else{
        alert('Please SignUp/Login to Join'+ props.clubInfo.name)
        setLoading(false)
      }
      
    } 
    const leaveUser = ()=>{
      setLoading(true)
      if((CurrentUser !== null || props.props !== null) ){
        removeUserClub()
      }

      else{
        alert('Please SignUp/Login to Join'+ props.clubInfo.name)
        setLoading(false)
      }
      
    }

    async function removeUserClub (){
      
     
  const mem = await  DataStore.query(ClubUser)
  const toDelete =  mem.filter((x)=>x.user.UserID == CurrentUser.username && x.club.id == props.clubInfo.id)
 
              DataStore.delete(toDelete[0]);
                getClubData()
     setMember(false)
 
      setLoading(false)
    
  }
    async function addUserToClub (){
      setLoading(true)
    try{
      const c = await DataStore.query(User, u => u.UserID("eq", props.props.username));

      const check = await DataStore.query(Club, clubinfo => clubinfo.name("eq",props.clubInfo.name))
      
      await DataStore.save(
        new ClubUser({
          club: check[0],
          user: c[0]
        })
      );
     
  const mem = await  DataStore.query(ClubUser)
        
  getClubData()
      checkUserMembership()
 
    }catch(err){

      setLoading(false)
    }
     
       
      
    }
    const setModalShower=(val)=>{
       
      setModalShow(val)
    }

    const styles={
      main:{
        width:window.innerWidth,
        maxWidth:window.innerWidth,
        margin:'auto',
        display:'block',
        minHeight:'500px',
        height:'100%',
        overflowY:'auto',
        overflowX:'hidden'
      },
      header:{
        maxWidth:window.innerWidth,
        width:window.innerWidth,
        background:'#1c1f35',
        display:'block',
        margin:'auto',
        border:'0px'
      },
      buttonsmall: {
        "width": "109px",
        "height": "46px",
    
        "display": "flex",
        "flexDirection": "row",
        "justifyContent": "flex-start",
        "alignItems": "flex-start",
        "gap": "8px",
        "padding": "6px 16px",
        "borderRadius": "8px",
        marginRight:'5px',
        border:'0px',
        backgroundColor: "#0e16d9"
      },
  

      buttonmedium:{
        width:'150px',
        "height": "50px",
        "flexGrow": "1",
        "display": "flex",
        "flexDirection": "row",
        flexWrap:'nowrap',
        "justifyContent": "center",
        "alignItems": "center",
        "gap": "10px",
        margin:'8px',
        "borderRadius": "8px",
        "boxShadow": "0 2px 8px 0 rgba(0, 0, 0, 0.25)",
      backgroundColor:'#fff',
      color:'#0e16d9'
    },
    }
    if(loading){
        return(
           <SplashScreen />
        )
    }


    if(loading === false){
  
      function ShowMemberModal  (v){
       
        if(!loading){
         return(
           <Modal 
           {...v}
           
           centered
           scrollable
           className="px-4"
           style={{background:'#000000E5',width:'100%'}}
           >
    <Modal.Header  closeVariant='white' closeButton style={{background:'#000000E5'}}>
    
       </Modal.Header>
       <div style={{background:'#000000E5',width:'100%',textAlign:'center'}}>
       <p className="ParagraphP2Regular mx-auto mt-4 mb-2" style={{width:'200px',fontWeight:'700',fontSize:'24px',color:'white',textAlign:'center'}}>Members</p>
 
       </div>
       <Modal.Body className="mx-auto px-2" style={{display:'block',background:'#000000E5',overflowX:'hidden',maxHeight:'580px',height:'500px',width:'100%',}}>
        
         <div className='mx-auto' style={{display:'flex',maxWidth:'700px',flexWrap:'wrap',justifyContent:'space-evenly',}}>  
         {members.sort(function(a,b){
   // Turn your strings into dates, and then subtract them
   // to get a value that is either negative, positive, or zero.
   
   return b.createdAt - a.createdAt;
 }).map((mems)=>{
   return(
     <div className="mx-auto my-2" style={{display:'block',height:'120px',width:'100px',}}>
 
 <div className="mx-2 my-2" 
    style={{textDecoration:'none',borderRadius:'100%',overflowY:'hidden',overflowX:'hidden',height:'75px',maxHeight:'75px',display:'flex',width:'75px',maxWidth:'75px'}}>
   <NavLink  to={"/"+mems.link} 
   style={{textDecoration:'none',}}>
             <Image
            
            src={mems.img}
            
            style={{height:'100%',width:'75px',display:'flex',}}
          />
       </NavLink>
     </div>
     <p className=' mx-auto my-auto' 
     style={{color:'white',textAlign:'center',fontSize:'12px',fontSizeAdjust:'0.5',maxWidth:'100%',}}>{mems.username.replaceAll(' ','').substring(0,14)}</p>
         </div>
 
 
 
   
   )
 })
       }
           </div>
       </Modal.Body>
       
           </Modal>
         )
        }
        
       
       }
 

    

   
       return(
        <div style={styles.main}>
         
            <CDBCard className="mb-2"style={styles.header}>
            <Image src={clubBanner} className="mx-auto rounded"  style={{display:'block',maxHeight:'328px',width:'100%',margin:'auto'}} />
                      <div className="my-2 mx-auto py-2"style={{maxHeight:'503px',width:'348px',borderBottom:'0.5px solid #00D4C5'}}>
                      <h1 className="ParagraphP1Regular mt-2"style={{width:"95%",textAlign:'left',color:'white',fontWeight:'700',fontSize:'25px',}}> 
                      {props.clubInfo.name}</h1>
                      <p  style={{width:'90%',color:'#00D4C5', }}>
                        {props.clubInfo.Website != null?  
                    <a className="mx-2 my-auto" href={props.clubInfo.Website.includes('//') ? props.clubInfo.Website :  `//${props.clubInfo.Website}`} target="_blank" rel="noopener noreferrer" style={{display:'flex',color:'#00D4C5',fontSize:'13px',fontWeight:'800px',}} >
                   <BlueButton className="me-2"background='#00000040' width='120px'>
                   <FiGlobe  size="1.05rem" className="me-2 my-auto"/> Play Now
                   </BlueButton>
                     
                      </a>

                        : ''}
                        
                        </p>

                      <p className="ParagraphP1Regular" 
                      style={{color:'white',fontSize:'16px',lineHeight:'24px',fontWeight:'normal',textAlign:'left',maxWidth:'348px',width:'100%', }}>
          {props.clubInfo.bio}
    </p>
       
           <div style={{display:'flex'}}>
           {
            (isMember  
            && 
          <CDBBtn onClick={()=>leaveUser()}  className="mt-4 mx-auto" 
          style={{background:'rgba(210, 43, 43,.8)',display:'flex', border:'0px',borderRadius:'100px',width:'80px',height:'45px'}} >
      
          <p className=" mx-auto my-auto" style={{...styles.leave,display:'flex'}}>
               
          Leave
            </p>
          </CDBBtn>)
          ||

          (!isMember  
            && 
          <div>
            
            <CDBBtn onClick={()=>addUser()} className="mt-4  mx-auto" 
            style={{background:'#00D4C5',display:'flex', border:'0px',borderRadius:'100px',width:'112px',height:'45px'}}  >
      
      <p className=" mx-auto my-auto" style={{...styles.leave,display:'flex'}}>
         
      Join
        </p>
      </CDBBtn>
       
        
       </div>)
          }

{  (isMember
            && 
            <NavLink  to={"/"+props.clubInfo.name.replaceAll(' ','')+"/Chat"} className="mx-auto mt-4"
            style={{textDecoration:'none',display:'flex'}}>
     <button className=" ParagraphP1Semibold mx-auto my-auto" style={{background:'#2E2D59',color:'white',
     textAlign:'center',fontWeight:'700',fontSize:'14px',lineHeight:'21px', border:'0px',borderRadius:'20px',width:'112px',height:'45px'}}>
     Club Chat
     </button>
    </NavLink>)
    ||
    <CDBBtn onClick={()=>{alert('Please Join the club to enter chatroom')}}className="ParagraphP1Semibold mx-auto mt-4" style={{background:'#2E2D59',color:'white',
    textAlign:'center',fontWeight:'700',fontSize:'14px',lineHeight:'21px', border:'0px',borderRadius:'20px',width:'112px',height:'45px'}} >
    Club Chat
    </CDBBtn>

        }
        <CDBBtn className="ParagraphP1Semibold mx-auto mt-4" onClick={() => setModalShower(true)} style={{background:'#2E2D59',color:'#00D4C5',
      textAlign:'center',fontWeight:'700',fontSize:'14px',fontSizeAdjust:'.5', border:'0px',width:'112px',borderRadius:'20px',height:'45px',flexWrap:'noWrap'}}>

            Members
            </CDBBtn>
          </div>
      
     




     <ShowMemberModal 
       show={modalShow}
       onHide={() => setModalShower(false)}
       />
        
                      </div>
            </CDBCard>
<div className="mt-2 mb-4 mx-auto px-2" style={{width:'100%',maxWidth:'410px', display:'block', height:"auto",}}>

<h5 className=" ParagraphP1Semibold mx-auto my-4" style={{color:'white',maxWidth:'345px',textAlign:'left',fontSize:'28px',fontWeight:'700'}}>Events</h5>
<div className="my-2 mx-auto" style={{display:'flex', maxWidth:'348px',width:'348px',overflowX:'scroll',flexDirection:'row'}}>

           
       
           <div style={{
           maxWidth:'348px',
            width:'348px',
             display:'flex',
             overflowX:nftCollections.length >1 ? 'auto':'hidden',
             margin:'auto',
            overflowY:'hidden'  
           }}>
          
          {nftCollections.map((collInfo)=>{
if(collInfo.name == "No Upcoming Collections"){
  return(
    <div className="my-2 px-2 mx-auto"
                   style={{textDecoration:'none', color:'white'}}>
                  <Image src={require("../assets/images/BlueStore.png")}  height="100%" style={{ display:'flex',maxWidth:'245px',maxHeight:'245px',borderRadius:'20px',margin:'auto'}}/>
                 
                 <Divider orientation='horizontal' size="small" className='my-4'/>
                  <p className="my-2 ParagraphP1Regular" style={{color:'white',fontWeight:'700'}}>Coming Soon</p>
                 </div>
                )
}else{
           return(
<NavLink  className={nftCollections.length > 1 ? "mx-2 " :""} to={"/"+collInfo.name.replaceAll(' ','') }
               style={{textDecoration:'none', color:'white'}}>
<Image src={collInfo.image}  style={{ display:'flex',maxWidth:'345px',borderRadius:'8px',margin:'auto'}}/>
<Divider orientation='horizontal' size="small" className='my-4' border="0.5px solid #00D4C5"/>

             <p className="my-2 ParagraphP1Regular" style={{color:'#fff',fontWeight:'bold',maxWidth:'345px',lineHeight:'36px',}}>{collInfo.name}</p>

             <p className="ParagraphP1Regular" 
                                style={{color:'white',fontSize:'15px',fontWeight:'400',textAlign:'left',maxWidth:'344px',width:'100%',height:'100%',maxHeight:'102px', }}>
  {collInfo.description.length> 160 ? collInfo.description.substring(0, 97) + "..." : collInfo.description}</p>
                               
</NavLink>
             
           
           )
}
          })

          }
          
</div>   
</div>  
  
  
  <p className=" ParagraphP1Semibold mx-auto my-4" style={{color:'white',maxWidth:'345px',textAlign:'left',fontSize:'28px',fontWeight:'700'}}>
        Feed
          </p>
      
  
  {newposts.length != 0 ? 
        newposts.map((mes)=>{

          return(
            <Card className="my-2 mx-auto pb-2"  style={{ width: '100%',maxWidth:'340px',display:'block',overflowY:'hidden',overflowX:'hidden',borderRadius:'20px',background:'#00000040'}}>
<div className='mt-2 mb-2  h7 mx-auto ' style={{color:'#00D4C5',maxWidth:'314px'}}>   
       
       {mes.time}
       </div>
       <div className='mx-auto h7' style={{color:'#00D4C5',width:'314px'}}>   
       
       @{props.clubInfo.name}
       <Divider orientation='horizontal' size="small" className='mx-auto' border="0.5px solid #00D4C5"/>
       </div>
     


   
      <Card.Body >
    
     
<Card.Title className='HeadingH6Semibold my-2 ' style={{color:'white'}}>  {mes.title}</Card.Title>
    
        <Card.Text className='ParagraphP2Regular' style={{color:'white'}}>
         {mes.message}
        
        </Card.Text>
     
      </Card.Body>
      {
        (  mes.image != null &&
          <Card.Img className="mx-auto"variant="bottom" src={mes.imageLink} width="314px"style={{maxWidth:'314px',maxHeight:'345px',borderRadius:'20px',display:'flex'}} />
   )
          ||
          
         ( mes.video != null &&
          <video  src={mes.videoLinks} controls autoplay width="100%"className="mb-2 mx-auto"  style={{maxHeight:'200px',border:'1px solid black',background:'black',display :'flex'}} />
         )
         
          ||
          mes.youtubeLink !=  null &&
          <iframe  src={mes.youtubeLink} width="314px" height="315px"  title="YouTube video player" 
          frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
          allowfullscreen style={{maxWidth:'314px',margin:'auto',maxHeight:'315px',display:'flex'}} />
          ||
''
          }
    </Card>

          )
        })

:
''
  
  
  
  }
  
  {
    posts.map((mes,index)=>{
      if(mes.message == 'Club has no post yet.'&& newposts.length == 0 ){
        return(
          <Card className="my-2 mx-auto"  style={{  width: '100%',maxWidth:'345px',display:'block',}}>
        <Card.Img variant="top" className="my-2 mx-auto"  src={mes.media} width="100%"style={{display:'flex',maxWidth:'300px',}} />
          <Card.Body>
          <Card.Title className='HeadingH6Semibold my-2 '>  {mes.message}</Card.Title>
            
      
          
          </Card.Body>
        </Card>
        )
        
      }else if(posts.length == 1 && mes.message != 'Club has no post yet.'){
return (
<>
<Card className="my-2 mx-auto pb-2"  style={{ width: '100%',maxWidth:'340px',display:'block',overflowY:'hidden',overflowX:'hidden',borderRadius:'20px',background:'#00000040'}}>
<div className='mt-2 mb-2  h7 mx-auto ' style={{color:'#00D4C5',maxWidth:'314px'}}>   
       
       {mes.time}
       </div>
       <div className='mx-auto h7' style={{color:'#00D4C5',width:'314px'}}>   
       
       @{props.clubInfo.name}
       <Divider orientation='horizontal' size="small" className='mx-auto' border="0.5px solid #00D4C5"/>
       </div>
     


   
      <Card.Body >
    
      {mes.message
          &&
<Card.Title className='HeadingH6Semibold my-2 ' style={{color:'white'}}>  {mes.message}</Card.Title>
      }
        <Card.Text className='ParagraphP2Regular' style={{color:'white'}}>
         {mes.title}
        
        </Card.Text>
     
      </Card.Body>
      {mes.media 
          &&
          mes.mediaType != 'video/mp4' &&
          <Card.Img className="mx-auto"variant="bottom" src={mes.media} width="314px"style={{maxWidth:'314px',maxHeight:'345px',borderRadius:'20px',display:'flex'}} />
   
          ||mes.mediaType == "video/mp4" &&
          <video  src={mes.media} controls width="100%"className="mb-2 mx-auto"  style={{maxHeight:'200px',border:'1px solid black',background:'black',display :'flex'}} />
          ||mes.youtubeLink !=  null &&
          <iframe  src={mes.youtubeLink} width="314px" height="315px"  title="YouTube video player" 
          frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
          allowfullscreen style={{maxWidth:'314px',margin:'auto',maxHeight:'315px',display:'flex'}} />
          ||
''
          }
    </Card>


   


</>

)
      }else{
if(mes.message != 'Club has no post yet.'){
  return (
    <>
    <Card className="my-2 mx-auto pb-2"  style={{ width: '100%',maxWidth:'340px',display:'block',overflowY:'hidden',overflowX:'hidden',borderRadius:'20px',background:'#00000040'}}>
    <div className='mt-2 mb-2  h7 mx-auto ' style={{color:'#00D4C5',maxWidth:'314px'}}>   
           
           {mes.time}
           </div>
           <div className='mx-auto h7' style={{color:'#00D4C5',width:'314px'}}>   
           
           @{props.clubInfo.name}
           <Divider orientation='horizontal' size="small" className='mx-auto' border="0.5px solid #00D4C5"/>
           </div>
         
    
    
       
          <Card.Body >
        
          {mes.message
              &&
    <Card.Title className='HeadingH6Semibold my-2 ' style={{color:'white'}}>  {mes.message}</Card.Title>
          }
            <Card.Text className='ParagraphP2Regular' style={{color:'white'}}>
             {mes.title}
            
            </Card.Text>
         
          </Card.Body>
          {mes.media 
              &&
              mes.mediaType != 'video/mp4' &&
              <Card.Img className="mx-auto"variant="bottom" src={mes.media} width="314px"style={{maxWidth:'314px',maxHeight:'345px',borderRadius:'20px',display:'flex'}} />
       
              ||mes.mediaType == "video/mp4" &&
              <video  src={mes.media} controls width="100%"className="mb-2 mx-auto"  style={{maxHeight:'200px',border:'1px solid black',background:'black',display :'flex'}} />
              ||mes.youtubeLink !=  null &&
              <iframe  src={mes.youtubeLink} width="314px" height="315px"  title="YouTube video player" 
              frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
              allowfullscreen style={{maxWidth:'314px',margin:'auto',maxHeight:'315px',display:'flex'}} />
              ||
    ''
              }
        </Card>
    
    
    
    
    
    </>
    
    )
}
        
      }
    })
  }

</div>
           
        </div>
       )
    }
    
}
