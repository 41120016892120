import React, { useEffect, useState } from 'react';
import Image from 'react-bootstrap/Image';
import { DataStore } from '@aws-amplify/datastore';
import { User, Club, ClubUser,ClubPost,NFTCollection, NewPost } from '../models';
import {  NavLink } from 'react-router-dom';
import awsconfig from '../aws-exports';
import "../assets/fonts/Poppins-Regular.ttf"
import "../assets/fonts/Poppins-Bold.ttf"
import "../assets/fonts/Poppins-Black.ttf"
import 'reactjs-popup/dist/index.css';
import { CDBBtn, CDBBtnGrp,} from "cdbreact";
import { Amplify, Auth,  Storage } from "aws-amplify";

import {  Modal } from 'react-bootstrap';

import links from '../assets/icons/links.svg'
import twitter from '../assets/icons/Solid/white/twitter.svg'

import '../styles/fonts/fonts.css'
import noPost from '../assets/icons/noPost.svg'
import {Divider,} from '@aws-amplify/ui-react';
import { TwitterTimelineEmbed, } from 'react-twitter-embed';


import SplashScreen from '../SplashScreen';
import ClubData from './components/ClubData';
import AvatarBannerGenerator from './components/AvatarBannerGenerator';
import BlueButton from './components/BlueButton';




Storage.configure({ track: true, level: "public" });
Amplify.configure({ ...awsconfig, ssr: true });





export default function ClubLanding(props){
    const [CurrentUser, setUser] = useState(null);
    const[loading,setLoading] = useState(true);
    const[isMember,setMember]=useState(false);
    const[clubID,setClubID]=useState(null);
    const [clubBanner,getBanner]=useState();
    const [clubNFTS,getNFTS]=useState([]);
    const [clubBio,setBio]=useState('');
    const[members,GetMembers]=useState([]);
    const [activeMems,getActiveMems]=useState(0)
    const[posts,GetPosts]=useState([]);
    const[newposts,GetNewPosts]=useState([]);
    const [nftCollections,getCollection]=useState([{"name":"No Upcoming Collections"}]);
    const [liveCollections,getLiveCollection]=useState([]);
    const [modalShow, setModalShow] = useState(false);
    useEffect(()=>{
      
  
      setLoading(true)
       getClubData();
   
        getUserData();
      
    

    },[]);

    async function getClubData(){

        try{
      
   
            setBio(props.clubInfo.bio)
       
            Storage.get("Clubs/"+props.clubInfo.ClubStorageID+"/ClubBanner.png",{

            }).then((ban)=>{
                getBanner(ban)
            })
            Storage.list("Clubs/"+props.clubInfo.ClubStorageID+"/NFTimages/",{

            }).then((nfts)=>{
                let t2 = []
         
                nfts.forEach((n)=>{
                  if(n.key.includes(".png") ||n.key.includes(".jpg" ) || n.key.includes(".gif" )){
                    Storage.get(n.key).then((nftImage)=>{
                        t2.push(nftImage)
                        getNFTS(t2)
                       
                    })}
                })
            }).catch((err)=>{
            
              let tempCollection =[]
              Storage.get("ComingSoon.png").then((comingSoon)=>{
                tempCollection.push(comingSoon)
                getNFTS(tempCollection)
              })
            })

            DataStore.query(ClubUser).then((mem)=>{
                
              
               let tempUserArray = []
               mem.filter(c => c.club.name == props.clubInfo.name).forEach((userPic)=>{
                let fileLink  = "Users/"+userPic.user.UserID+"/Pro.png"

               
                Storage.get(fileLink,{
                  validateObjectExistence: true
                }).then((pro)=>{
             
                  let joi = new Date(userPic.createdAt)
                  tempUserArray.push({
                   "img":pro,
                   "bio":userPic.user.bio,
                   "username":userPic.user.preferred_username,
                    "link":userPic.user.preferred_username.replaceAll(' ',''),
                    "createdAt":joi,
                    "UserID":userPic.user.UserID
                  })
                  GetMembers(tempUserArray)
              
                }).catch((err)=>{
                  console.log("image err")
              
                }
              
                )
                 
                
                })
            })

            DataStore.query(NewPost,(u)=>u.clubID('eq',props.clubInfo.id)).then((np)=>{
                    if(np.length > 0){
                      let tempNew = []

                      np.forEach((indPost)=>{
                        var timeCreated = new Date(indPost.createdAt);
                        var currentdate = new Date();
                      
             
                              let tp =''
                          
                           if(timeCreated.getHours() >= 12){
                            let hourPM = timeCreated.getHours()-12
                            let mins = parseInt(timeCreated.getMinutes()) < 10 ? "0"+ timeCreated.getMinutes() : timeCreated.getMinutes();
                          tp=  timeCreated.toLocaleDateString() +' - '+hourPM +":"+mins+" pm"
                           }else{
                            let mins = parseInt(timeCreated.getMinutes()) < 10 ? "0"+ timeCreated.getMinutes() : timeCreated.getMinutes();

                            tp=  timeCreated.toLocaleDateString() +' - '+timeCreated.getHours() +":"+mins+" am"
                        
                           }
                        if(indPost.video != null){
                      
                      Storage.get(indPost.video,{

                      }).then((vid)=>{
                        tempNew.push({...indPost,"videoLinks":vid,"time":tp})
                      }).catch(err=>console.log(err))
                          
                        }else if(indPost.image != null){
                          
                          Storage.get(indPost.image,{

                          }).then((imgLink)=>{
                            tempNew.push({...indPost,"imageLink":imgLink,"time":tp})
                          }).catch(err=>console.log(err))
                        }else{
                          tempNew.push({...indPost,"time":tp})
                        }
                        console.log(tempNew)
                          GetNewPosts(tempNew)

                      })
                    }      
                  
                          

            }).catch((err)=>{
              console.log(err)
            })
            DataStore.query(ClubPost).then((p)=>{
                    let tempPost = []
                    if( p.filter(c => c.club.name == props.clubInfo.name).length == 0){
                      Storage.get("noPost.png").then((noPost)=>{
                        let tempPost =[{
                          "message":'Club has no post yet.',
                          "title":null,
                          "media":noPost
                        }]
                        GetPosts(tempPost)
                        setLoading(false)
                      })
                  
        
                    }else{
                    p.filter(c => c.club.name == props.clubInfo.name)
                    .sort((a,b) => b._lastChangedAt -a._lastChangedAt).forEach((mes)=>{
               
                      var timeCreated = new Date(mes.createdAt);
                      var currentdate = new Date();
                    
           
                            let tp =''
                        
                         if(timeCreated.getHours() >= 12){
                          let hourPM = timeCreated.getHours()-12
                          let mins = parseInt(timeCreated.getMinutes()) < 10 ? "0"+ timeCreated.getMinutes() : timeCreated.getMinutes();
                        tp=  timeCreated.toLocaleDateString() +' - '+hourPM +":"+mins+" pm"
                         }else{
                          let mins = parseInt(timeCreated.getMinutes()) < 10 ? "0"+ timeCreated.getMinutes() : timeCreated.getMinutes();
                          tp=  timeCreated.toLocaleDateString() +' - '+timeCreated.getHours() +":"+mins+" am"
                      
                         }
                           
                        Storage.get(mes.post.media,{
                           
                        }).then((res)=>{
                        
                          tempPost.push({...mes.post,"media":mes.post.media ? res : null,time:tp})
                          GetPosts(tempPost)
                       
                        }).catch((err)=>{
                          tempPost.push({...mes.post,"time":tp})
                          GetPosts(tempPost)
                        })
                        
                
                  
                    
                        
                        
                       
                    })}
             
            }).catch((err)=>{
            
              

  
            })

            DataStore.query(NFTCollection, cID => cID.clubID('eq',props.clubInfo.id)).then((clubCollections)=>{
              let tempCollection =[]
              clubCollections.forEach((clubColl)=>{
        
                if(clubColl != null && clubColl.signupLive != false){
                  let collName= clubColl.name.replace(/ /g, '');
                
                  Storage.get("Clubs/"+props.clubInfo.ClubStorageID+"/Collections/"+collName+"/Secret.png").then((cList)=>{
                    
             
                    
                      tempCollection.push({
                        "name":clubColl.name,
                        "description":clubColl.description,
                        "image":cList
                       })
                       getCollection(tempCollection)
                 
                  }).catch((err)=>{
                    let tempCollection =[]
                    Storage.get("ComingSoon.png").then((comingSoon)=>{
                      tempCollection.push({
                        "name":"Coming Soon!",
                        "description":"No Upcoming Collections",
                        "image":comingSoon
                       })
                       getCollection(tempCollection)
                    })
                  })
                }
              })
              
         
            }).catch((err)=>{
              let tempCollection =[]
        
              Storage.get("ComingSoon.png").then((comingSoon)=>{
                tempCollection.push({
                  "name":"Coming Soon!",
                  "description":"No Upcoming Collections",
                  "image":comingSoon
                 })
                 getCollection(tempCollection)
              })
            })
        }catch(err){
            setLoading(false)
        }
    }
     function getUserData(){
      
        
      
   
            if(props.props!==null){
              setUser(props.props)
              checkUserMembership() 
            }else if(props.props!==null || CurrentUser !== null){
              Auth.currentAuthenticatedUser({ bypassCache: true }).then((userInfo)=>{
             
                if(userInfo){ 
                           setUser(userInfo)
                         
                          
                       }else{
                        setUser(null)
                        setLoading(false)
             
                       }
                       checkUserMembership(); 
              }).catch((err)=>{
                setLoading(false)
              })
            }else{
             setLoading(false)
            }
          
            
                   
        }
        
     
           
    

 async  function checkUserMembership(){
    
  
    setLoading(true)
    
    try{
      const ans = await DataStore.query(ClubUser)
  
      let temp =CurrentUser? ans.filter((u)=>u.user.UserID.toLowerCase()==CurrentUser.username.toLowerCase()&& u.club.id.toLowerCase()==props.clubInfo.id.toLowerCase()) 
      : ans.filter((u)=>u.user.UserID.toLowerCase()==props.props.username.toLowerCase()&& u.club.id.toLowerCase()==props.clubInfo.id.toLowerCase())
    
      if(temp[0]){
      
        setMember(true)
      }else{
        setMember(false)
      }
      if(temp[1]){
        temp.forEach(async (td,index)=>{
          if(index != 0){
          await  DataStore.delete(td);
          }
       
        })
  
      }
    }catch(err){
      
        console.log(err)
        setMember(false)
        setLoading(false)
     
    }
 
    
          setLoading(false)
     

        

    }
    
  
    const styles={
        main:{
            width:window.innerWidth,
            maxWidth:'1320px',
            margin:'auto',
           padding:'10px',
           maxHeight:window.innerHeight,
            display:'block'
        },
        header:{
            width:window.innerWidth,
            maxWidth:'1320px',
            margin:'auto',
            height:"15%",

            display:'block'
        },
        buttonsmall: {
          "width": "109px",
          "height": "46px",
          "border":"0px",
          "display": "flex",
          "flexDirection": "row",
          "justifyContent": "flex-start",
          "alignItems": "flex-start",
          "gap": "8px",
          "padding": "6px 16px",
          "borderRadius": "8px",
          marginRight:'5px',
          backgroundColor: "#0e16d9"
        },
        buttonlarge:{
            width:'414px',
            "height": "50px",
            "flexGrow": "1",
            "border":"0px",
            "display": "flex",
            "flexDirection": "row",
            flexWrap:'nowrap',
            "justifyContent": "center",
            "alignItems": "center",
            "gap": "10px",
            margin:'8px',
            "borderRadius": "8px",
            "boxShadow": "0 2px 8px 0 rgba(0, 0, 0, 0.25)",
          backgroundColor:'#fff',
          color:'#0e16d9'
        },

        buttonmedium:{
          width:'250px',
          "height": "50px",
          "border":"0px",
          "flexGrow": "1",
          "display": "flex",
          "flexDirection": "row",
          flexWrap:'nowrap',
          "justifyContent": "center",
          "alignItems": "center",
          "gap": "10px",
          margin:'8px',
          "borderRadius": "8px",
          "boxShadow": "0 2px 8px 0 rgba(0, 0, 0, 0.25)",
        backgroundColor:'#fff',
        color:'#0e16d9'
      },
        leave: {
    
          fontFamily: "Poppins-Regular",
          fontSize: "18px",
          fontWeight: "500",
          fontStretch: "normal",
          fontStyle: "normal",
          marginRight:'10px',
          letterSpacing: "normal",
          textAlign: "center",
          color: "#fff"
        },
        content:{
            width:"100%",
            margin:'auto',
            maxWidth:'1120px',
            
            height:"100%",
            display:'flex',
            flexDirection:'row',
            justifyContent:'space-between'

        },
        feed:{
            width:'70%',
            margin:'auto',
            display:'block',
          
            maxHeight:'800px',
            overflowY:'scroll',
           justifyContent:'space-between',
           flexDirection:'column'
            
        },
        post:{
            width:'90%',
            margin:'auto',
            marginTop:'15px',
            background:'#00000040',
        },
        h7 :{
         
          display:'block',
          marginBottom:'20px',
          maxWidth:'960px',
         
          "letterSpacing": "normal",
          "textAlign": "left",
          color:'white'
          },
          h5:{
           
            color:'white',
            flexGrow: "0",
            fontFamily: "Poppins-Regular",
            fontSize: "33.2px",
            fontWeight: "bold",
            fontStretch: "normal",
            fontStyle: "normal",
            lineHeight: "1.21",
            letterSpacing: "normal",
            textAlign: "left"
          },
          nfts:{
         
          width:'100%'

          }
        

    }

    const addUser = ()=>{
      setLoading(true)
      if((CurrentUser !== null || props.props !== null) ){
        addUserToClub()
      }

      else{
        alert('Please SignUp/Login to Join'+ props.clubInfo.name)
        setLoading(false)
      }
      
    }
    const leaveUser = ()=>{
      setLoading(true)
      if((CurrentUser !== null || props.props !== null) ){
        removeUserClub()
      }

      else{
        alert('Please SignUp/Login to Join'+ props.clubInfo.name)
        setLoading(false)
      }
      
    }

    async function removeUserClub (){
      
     
  const mem = await  DataStore.query(ClubUser)
  const toDelete =  mem.filter((x)=>x.user.UserID == CurrentUser.username && x.club.id == props.clubInfo.id)
 
              DataStore.delete(toDelete[0]);
                getClubData()
     setMember(false)
 
      setLoading(false)
    
  }
    async function addUserToClub (){
      
       
      setLoading(true)
      try{
        const c = await DataStore.query(User, u => u.UserID("eq", props.props.username));
  
        const check = await DataStore.query(Club, clubinfo => clubinfo.name("eq",props.clubInfo.name))
        
        await DataStore.save(
          new ClubUser({
            club: check[0],
            user: c[0]
          })
        );
       
    const mem = await  DataStore.query(ClubUser)
          
    getClubData()
        checkUserMembership()
   
      }catch(err){
  
        setLoading(false)
      }
       
         
      
    }
    if(loading){
        return(
           <SplashScreen />
        )
    }

    if(!loading ){


     const addDefaultSrc =(ev)=>{
      AvatarBannerGenerator(ev.target.src.slice(108,144))
      ev.target.src = "https://api.dicebear.com/6.x/adventurer/svg?seed=Annie"
      //ev.target.src.slice(108,144)


     }
 

      function ShowMemberModal  (v){
  
       if(!loading){

        return(
          <Modal 
          {...v}
          
          centered
          scrollable
          className="px-4"
          style={{background:'#000000E5',width:'100%'}}
          >
   <Modal.Header  closeVariant='white' closeButton style={{background:'#000000E5'}}>
   
      </Modal.Header>
      <div style={{background:'#000000E5',width:'100%',textAlign:'center'}}>
      <p className="ParagraphP2Regular mx-auto mt-4 mb-2" style={{width:'200px',fontWeight:'700',fontSize:'24px',color:'white',textAlign:'center'}}>Members</p>

      </div>
      <Modal.Body className="mx-auto px-2" style={{display:'block',background:'#000000E5',overflowX:'hidden',maxHeight:'580px',height:'500px',width:'100%',}}>
       
        <div className='mx-auto' style={{display:'flex',maxWidth:'700px',flexWrap:'wrap',justifyContent:'space-evenly',}}>  
        {members.sort(function(a,b){
  // Turn your strings into dates, and then subtract them
  // to get a value that is either negative, positive, or zero.
  
  return b.createdAt - a.createdAt;
}).map((mems)=>{
  console.log(mems.UserID.length)
  return(
    <div className="mx-auto my-2" style={{display:'block',height:'120px',width:'100px',}}>

<div className="mx-2 my-2" 
   style={{textDecoration:'none',borderRadius:'100%',overflowY:'hidden',overflowX:'hidden',height:'75px',maxHeight:'75px',display:'flex',width:'75px',maxWidth:'75px'}}>
  <NavLink  to={"/"+mems.link} 
  style={{textDecoration:'none',}}>
            <Image
        
           src={mems.img}
           onError={addDefaultSrc} 
           style={{height:'100%',width:'75px',display:'flex',}}
         />
      </NavLink>
    </div>
    <p className=' mx-auto my-auto' 
    style={{color:'white',textAlign:'center',fontSize:'12px',fontSizeAdjust:'0.5',maxWidth:'100%',}}>{mems.username.replaceAll(' ','').substring(0,14)}</p>
        </div>



  
  )
})
      }
          </div>
      </Modal.Body>
      
          </Modal>
        )
       }
       
      
      }

      const setModalShower=(val)=>{
       
        setModalShow(val)
      }

    function CopiedToboard(){
      navigator.clipboard.writeText("Chieveapp.xyz/"+props.clubInfo.name.replaceAll(' ','') )
       alert("link copied to clipboard")
     }

     /*
     const subscription = DataStore.observeQuery(ClubUser).subscribe(snapshot=>{
      const { items, isSynced } = snapshot;
      console.log(items.filter(c=>c.club.id == props.clubInfo.id))
      getActiveMems(items.filter(c=>c.club.id == props.clubInfo.id).length)
//  console.log(`[Snapshot] item count: ${items.length}, isSynced: ${isSynced}`);
     })
     **/
        return(
            <div styles={styles.main}>
            


            <div style={styles.header}>
            <div style={{display:'block',height:'328px',background:'transparent',overflow:'hidden',}}>
            <Image src={clubBanner} className="mx-auto"  style={{display:'block',maxHeight:'403px',width:'100%',margin:'auto'}} />

            </div>
            
            <div className='mx-auto' style={{display:'flex',marginTop:'40px',marginBottom:'40px', maxWidth:'1120px',width:'1120px',height:'227px',borderBottom:'1px solid #00D4C5',borderTop:'1px solid #00D4C5'}}>
              <div  className='me-5'style={{display:'block',maxWidth:'800px',width:'100%'}}> 
                <h1 className="HeadingH3Semibold mt-2"style={{width:"513px",textAlign:'left',color:'white',fontWeight:'700',fontSize:'36px',}}> {props.clubInfo.name}</h1>
                <p  style={{width:'90%',fontSize:'12px',fontWeight:'400px',color:'#00D4C5', 
  }}>Gaming</p>

<p className="ParagraphP1Regular" style={{color:'white',fontSize:'16px',lineHeight:'20px',fontWeight:'normal',textAlign:'left',maxWidth:'800px',width:'100%', 
  }}>
{props.clubInfo.bio}
    </p>


    <p className="ParagraphP1Regular mt-2 mb-4" style={{color:'white',fontSize:'16px',fontWeight:'normal',textAlign:'left',maxWidth:'770px',width:'100%', 
  }}>
    {props.clubInfo.Website? 
  
    <a href={props.clubInfo.Website.includes('//') ? props.clubInfo.Website :  `//${props.clubInfo.Website}`}  target="_blank" rel="noopener noreferrer"style={{display:'flex'}}>
     <CDBBtn style={{background:'#2E2D59',color:'white',
    textAlign:'center',fontWeight:'700',fontSize:'14px',lineHeight:'21px', border:'0px',borderRadius:'20px',width:'112px',height:'45px'}}>
     
   
       Play Now
     </CDBBtn>
     
    </a>
  
  
  
  
  : ''}</p>
    
              </div>
              <div className='ms-5 mt-2'style={{display:'block',maxWidth:'300px',width:'30%',height:'90%'}}> 
            
            
              {
            (isMember  
            && 
          <CDBBtn onClick={()=>leaveUser()}  className="mt-3 mx-auto ParagraphP1Semibold " style={{background:'rgba(210, 43, 43,.8)',display:'flex', border:'0px',borderRadius:'100px',width:'112px',height:'45px'}} >
      
          <p className=" mx-auto my-auto" style={{textAlign:'center',fontWeight:'700',fontSize:'14px',lineHeight:'21px',}}>
               
          Leave
            </p>
          </CDBBtn>)
          ||

          (!isMember  
            && 
          <div>
            
            <CDBBtn onClick={()=>addUser()} className="mt-4  mx-auto ParagraphP1Semibold" style={{background:'#00D4C5',display:'flex', border:'0px',borderRadius:'100px',width:'112px',height:'45px'}}  >
      
      <p className=" mx-auto my-auto" style={{textAlign:'center',fontWeight:'700',fontSize:'14px',lineHeight:'21px',}}>
         
      Join
        </p>
      </CDBBtn>
       
        
       </div>)
          }

{  (isMember
            && 
            <NavLink  to={"/"+props.clubInfo.name.replaceAll(' ','')+"/Chat"} className="mx-auto mt-4"
            style={{textDecoration:'none',display:'flex'}}>
     <button className=" ParagraphP1Semibold mx-auto my-auto" style={{background:'#2E2D59',color:'white',
     textAlign:'center',fontWeight:'700',fontSize:'14px',lineHeight:'21px', border:'0px',borderRadius:'20px',width:'112px',height:'45px'}}>
     Club Chat
     </button>
    </NavLink>)
    ||
    <CDBBtn onClick={()=>{alert('Please Join the club to enter chatroom')}}className="ParagraphP1Semibold mx-auto mt-4" style={{background:'#2E2D59',color:'white',
    textAlign:'center',fontWeight:'700',fontSize:'14px',lineHeight:'21px', border:'0px',borderRadius:'20px',width:'112px',height:'45px'}} >
    Club Chat
    </CDBBtn>

        }
   <CDBBtn className="ParagraphP1Semibold mx-auto mt-4" onClick={() => setModalShower(true)} style={{background:'#2E2D59',color:'#00D4C5',
      textAlign:'center',fontWeight:'700',fontSize:'14px',fontSizeAdjust:'.5', border:'0px',width:'112px',borderRadius:'20px',height:'45px',flexWrap:'noWrap'}}>

            Members
            </CDBBtn>

     <ShowMemberModal 
       show={modalShow}
       onHide={() => setModalShower(false)}
       />
      
            </div>
            
            </div>
            
              </div>





        {/** start of side panel */}
<div style={styles.content} >
<div className="col-md ">
        
          <ul className="list-group list-group-flush ">
        
        
       
       
     
           
            <li className="list-group-item" style={{background:'transparent'}}>
            <h5 className="HeadingH5Semibold" style={{"color":'white'}}>Events</h5>
           
       
            <div className="mx-auto"style={{
              maxWidth:'350px',
              maxHeight:'800px',
              display:'block',
              overflowY:'auto',
              
              overflowX:'hidden',
              "borderRadius":"4px",
            }}>
           
           {nftCollections.map((collInfo)=>{
              if(collInfo.name == "No Upcoming Collections"){
                return(
                  <div className="my-2 px-2 mx-auto"
                                 style={{textDecoration:'none', color:'white'}}>
                                <Image src={require("../assets/images/BlueStore.png")}  height="100%" style={{ display:'flex',maxWidth:'345px',maxHeight:'345px',borderRadius:'20px',margin:'auto'}}/>
                               
                               <Divider orientation='horizontal' size="small" className='my-4'/>
                                <p className="my-2 ParagraphP1Regular" style={{color:'white',fontWeight:'700'}}>Coming Soon</p>
                               </div>
                              )
              }else{
                return(
                  <>
                   <NavLink  to={"/"+collInfo.name.replaceAll(' ','') } className="my-2 px-2 mx-auto"
                                 style={{textDecoration:'none', color:'white'}}>
                                   
                                <Image src={collInfo.image} height="100%" style={{ display:'flex',maxWidth:'345px',maxHeight:'345px',borderRadius:'20px',margin:'auto'}}/>
                               
                                <Divider orientation='horizontal' size="small" className='my-4'/>
                                <p className="my-2 ParagraphP1Regular" style={{color:'white',fontWeight:'700'}}>{collInfo.name}</p>
                             
                                <p className="ParagraphP1Regular" 
                                style={{color:'white',fontSize:'16px',fontWeight:'normal',textAlign:'left',maxWidth:'344px',width:'100%',height:'100%',maxHeight:'102px', }}>
  {collInfo.description.length> 160 ? collInfo.description.substring(0, 97) + "..." : collInfo.description}</p>
                                 
                               </NavLink>
                          
                            
                             
                  </>
                 
                              )
              }
           
           })

           }
           
</div>   
         
                </li>
  
                
       
          </ul>
      

  

   
      
      </div>
{/** start of feed*/}

<div style={styles.feed} >


    <h5 className="HeadingH5Semibold" style={{textAlign:'left',color:'white',margin:'auto',width:'90%'}}>Feed</h5>
    

  

      {newposts.length != 0 ? newposts.sort((a,b)=>{
          let x = new Date(a.createdAt)
          let y = new Date(b.createdAt)
          return y -x
      }).map((mes)=>{

              return(
             
                  <div className="card gedf-card" style={styles.post}>
                  <div className="card-header mx-auto" style={{borderBottom:'1px solid #00d4c5' ,width:'95%'}}>
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="d-flex justify-content-between align-items-center">
                       
                        <div className="">
                          <div className="h5 mx-0 my-2" style={{fontWeight:'600',color:'white'}}>
                          @{props.clubInfo.name}
                          </div>
                          <div className="h7 text-muted"> </div>
                        </div>
                      </div>
                   
                    </div>
                  </div>
                  <div className="card-body mx-2"> 
                  <div className="h7 mb-2 " style={{color:'#00D4C5'}}>
                      {" "}
                      <i className="fa fa-clock-o"></i>{mes.time}
                    </div>
                  
                    <h5 className="card-title" style={{color:'white',width:'95%',flexWrap:'wrap',fontWeight:'600',fontSize:'18px'}}>
                      {mes.title}
                   </h5>
                
                  {(mes.video != null  &&
           
                  <video  src={mes.videoLinks} controls height="100%" className="mx-auto"style={{maxWidth:'400px',margin:'auto',maxHeight:'400px',display:'flex'}} autoplay={true}/>)
                ||
                
             (mes.image != null &&

          <Image className="mx-auto "src={mes.imageLink} height="100%" style={{maxWidth:'400px',display:'flex',margin:'auto',maxHeight:'400px'}}/>
             )   

                
              ||

              ( mes.youtubeLink != null &&

                <iframe  src={mes.youtubeLink} width="400px" height="315px"  title="YouTube video player" 
          frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
          allowfullscreen style={{maxWidth:'90%',margin:'auto',display:'flex',maxHeight:'315px'}} />

              )
                  }
               
               <p className='ParagraphP2Regular my-2 mx-2 px-2 py-2'  style={{width:'90%',lineHeight:'1.5',flexWrap:'wrap',color:'white'}}>
                      {mes.message}
                      </p>
                    
                  </div>
                  <div className="card-footer">
                 
                  
                  
                  </div>
                </div>
        
        
        
              
              )

      })
      :
      ''
      }
{
    posts.map((mes,index)=>{
      if(mes.message == 'Club has no post yet.' && newposts.length == 0 ){
        return(
          <div className="card gedf-card" style={styles.post}>
                  <div className="card gedf-card" style={{width:"100%",height:'400px',background:'transparent',display:'flex'}}>
          <Image src={noPost } className='my-auto mx-auto' style={{display:'flex',height:'50px'}} />
          <p className='my-auto mx-auto ParagraphP1Semibold' style={{display:'flex',color:'white',fontWeight:'600',fontFamily:'monospace'}}>Club Has not posted yet</p>
        </div>
            </div>
        )
        
      }else{
        if(mes.message != 'Club has no post yet.'){
          return(

          
            <div className="card gedf-card" style={styles.post}>
            <div className="card-header mx-auto" style={{borderBottom:'1px solid #00d4c5' ,width:'95%'}}>
              <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex justify-content-between align-items-center">
                 
                  <div className="">
                    <div className="h5 mx-0 my-2" style={{fontWeight:'600',color:'white'}}>
                    @{props.clubInfo.name}
                    </div>
                    <div className="h7 text-muted"> </div>
                  </div>
                </div>
             
              </div>
            </div>
            <div className="card-body mx-2"> 
            <div className="h7 mb-2 " style={{color:'#00D4C5'}}>
                {" "}
                <i className="fa fa-clock-o"></i>{mes.time}
              </div>
            
              <h5 className="card-title" style={{color:'white',width:'95%',flexWrap:'wrap',fontWeight:'400',fontSize:'16px'}}>
                {mes.message}
             </h5>
          
            {mes.media 
            &&
           mes.mediaType != 'video/mp4' &&
            <Image className="mx-auto "src={mes.media} height="100%" style={{maxWidth:'400px',display:'flex',margin:'auto',maxHeight:'400px'}}/>
            ||mes.mediaType == "video/mp4" &&
            <video  src={mes.media} controls height="100%" style={{maxWidth:'400px',margin:'auto',maxHeight:'400px'}} />
            ||mes.youtubeLink !=  null &&
            <iframe  src={mes.youtubeLink} width="400px" height="315px"  title="YouTube video player" 
            frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
            allowfullscreen style={{maxWidth:'90%',margin:'auto',display:'flex',maxHeight:'315px'}} />
             
  ||
  ''
            }
         
         <p className='ParagraphP2Regular my-2 mx-2 px-2 py-2'  style={{width:'90%',lineHeight:'1.5',flexWrap:'wrap',color:'white'}}>
                {mes.title}
                </p>
              
            </div>
            <div className="card-footer">
              {mes.message == 'Club has no post yet.' ?
              
              <CDBBtnGrp className="w-100  mx-auto " style={{display:'flex',justifyContent:'space-between'}}>
            
            </CDBBtnGrp>
              
               :
              
                 ''
                
            }
            
            
            </div>
          </div>
  
  
  
        )
        }
       
      }
       
    })
}

<div className="card gedf-card" style={{...styles.post,border:'transparent'}}>
           <div>
            <br />
            <br />
            <br />
            <br />
            <br />
            </div>
          </div>

</div>

</div>
        </div>
        )
    }
    
}

/**
 *   {props.clubInfo.Twitter ? 
   
   <div className="mx-auto card gedf-card" style={styles.post}>
        <TwitterTimelineEmbed
 sourceType="profile"
 screenName={props.clubInfo.Twitter}
        tweetLimit={4}
 autoHeight={true}
 theme="dark"
 transparent={true}
 noHeader
      noScrollbar={true}
  placeholder={

      <div className="card gedf-card" style={{width:"100%",height:'400px',background:'#1DA1F2',display:'flex'}}>
          <Image src={twitter } className='my-auto mx-auto' style={{display:'flex',height:'50px'}} />
        </div>
  }
 noBorders={true}
 />
     </div>
 
 
    
    :
    ''
   }
 */
/**
 * 
 *       <div style={styles.header}>
            <div style={{display:'block',height:'328px',background:'transparent',overflow:'hidden',padding:'5px',margin:'40px 60px 10px 60px'}}>
            <Image src={clubBanner} className="rounded border"  style={{display:'block',maxHeight:'328px',width:'100%',margin:'auto'}} />

            </div>

<div className="card mx-auto" style={{maxWidth:'1320px',background:'transparent',width:'90%',margin:'auto',padding:'10px',marginTop:'15px'}}>
          <div className="card-body" style={{background:'transparent'}}>
            <div style={{width:'100%',background:'transparent', display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
            <h5 style={styles.h5} className="HeadingH4Bold">{props.clubInfo.name}</h5>

            <CDBBtnGrp >
            {
            (isMember  
            && 
          <CDBBtn onClick={()=>leaveUser()}  className="my-auto" style={styles.buttonsmall} >
      
          <p className=" mx-auto my-auto" style={{...styles.leave,display:'flex'}}>
               <Image src={Icon_logout} className=" my-auto" />
          Leave
            </p>
          </CDBBtn>)
          ||

          (!isMember  
            && 
          <div>
            
            <CDBBtn onClick={()=>addUser()} className=" my-auto" style={styles.buttonsmall} >
      
      <p className=" mx-auto my-auto" style={{...styles.leave,display:'flex'}}>
           <Image src={join} className=" my-auto" />
      Join
        </p>
      </CDBBtn>
       
        
       </div>)
          }

{
            props.clubInfo.Website ?
            <CDBBtn style={{border:'0px',marginRight:'5px',background:'transparent'}}>
            <a href={props.clubInfo.Website} target="_blank" rel="noopener noreferrer"style={{background:'transparent',textDecoration:'none'}}>
          
          <Image src={Icon_globe} />
       
        
            </a>
            </CDBBtn>
            :
            ''
          }
          {
            props.clubInfo.Instagram ?
            <CDBBtn style={{border:'0px',marginRight:'5px',background:'transparent'}}>
            <a href={props.clubInfo.Instagram} target="_blank" rel="noopener noreferrer"style={{background:'transparent',textDecoration:'none'}}>
          
          <Image src={Icon_instagram} />
       
        
            </a>
            </CDBBtn>
            :
            ''
          }
           {
            props.clubInfo.Facebook ?
            <CDBBtn style={{border:'0px',marginRight:'5px',background:'transparent'}}>
            <a href={props.clubInfo.Facebook} target="_blank" rel="noopener noreferrer"style={{background:'transparent',textDecoration:'none'}}>
          
          <Image src={Icon_facebook} />
       
        
            </a>
            </CDBBtn>
            :
            ''
          }
           {
            props.clubInfo.Twitter ?
            <CDBBtn style={{border:'0px',marginRight:'5px',background:'transparent'}}>
            <a href={props.clubInfo.Twitter} target="_blank" rel="noopener noreferrer"style={{background:'transparent',textDecoration:'none'}}>
          
          <Image src={Icon_twitter} />
       
        
            </a>
            </CDBBtn>
            :
            ''
          }
        {
            props.clubInfo.tiktok ?
            <CDBBtn style={{border:'0px',marginRight:'5px',background:'transparent'}}>
            <a href={props.clubInfo.tiktok} target="_blank" rel="noopener noreferrer"style={{background:'transparent',textDecoration:'none'}}>
          
          <Image src={tiktok} />
       
        
            </a>
            </CDBBtn>
            :
            ''
          }
            </CDBBtnGrp>
            </div>
            
            

            <div className="mb-4 mt-2 ParagraphP2Regular"style={styles.h7}>
           <p className="ParagraphP2Regular">
           {clubBio}
            </p> 
            </div>

       <div style={{ display:'block'}}>
       <CDBBtnGrp className="w-100 p-3 mx-auto " style={{display:'flex',justifyContent:'space-between'}}>
        {
          props.polls.length > 0 ?
          <CDBBtn  style={styles.buttonlarge} >
          
          <NavLink  to={"/"+props.clubInfo.name.replaceAll(' ','')+"/"+props.polls[0].polls.title.replaceAll(' ','') }
              style={{textDecoration:'none'}}>
          <p className='mx-auto my-auto Paragraphp1Semibold' >
          Tournament Schedule
          </p>
          </NavLink>
          
   
   
 
             
              
              
               </CDBBtn>
               :
               <CDBBtn  style={styles.buttonlarge} onClick={()=>{alert("Club has No Events Just Yet")}}>
          
          
               <p className='mx-auto my-auto Paragraphp1Semibold' >
               Tournament Schedule
               </p>
        
        
      
                  
                   
                   
                    </CDBBtn>
        }
          

        {  (isMember
            && 
            <NavLink  to={"/"+props.clubInfo.name.replaceAll(' ','')+"/Chat"} className="me-2"
            style={{textDecoration:'none', color:'Black'}}>
     <CDBBtn style={styles.buttonlarge} className='ParagraphP1Semibold'>
     Club Chat
     </CDBBtn>
    </NavLink>)
    ||
    <CDBBtn onClick={()=>{alert('Please Join the club to enter chatroom')}}style={styles.buttonlarge} className='ParagraphP1Semibold'>
    Club Chat
    </CDBBtn>

        }
  
       
    
      
        
       
      
  
    
     
   
    
     <Popup
    trigger={ 
      <CDBBtn style={styles.buttonlarge} className='ParagraphP1Semibold'>

            {members.length }{' '} Members
            </CDBBtn>
   }
   style={{maxWidth:'600px'}}
    modal
    nested
  >
    
    {MemberModal}
  
  
  
   
  </Popup>
           </CDBBtnGrp>
          
       </div>
          
          </div>
        
        </div>
</div>
 */