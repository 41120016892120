import React from 'react';
import "./styles/Navbar.css"
import { Navigate, NavLink } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Image from 'react-bootstrap/Image';
import { FiKey } from 'react-icons/fi';
import SignUp from './screens/SignUp'
import { signUpFieldsWithDefault } from '@aws-amplify/ui';
import {Routes, Route, useNavigate} from 'react-router-dom';


const Navigation = () => {
  
    return (
    <div className="main">

   
   <Navbar bg="light" variant="dark" >
   
      <Navbar.Brand id="logoContainter"> 
          <Image
            alt=""
            src={require('./assets/images/main_logo.png')}
            width="auto"
            height="auto"
            className="d-inline-block float-right align-top "
            id="logo"
            responsive
          />
          </Navbar.Brand>
          <ButtonGroup id="links">
  <Button id="text">Explore</Button>
  <Button id="text">Search</Button>
  <Button id="text">Create</Button>
  <Button id="text">About</Button>
  <Button type ="button" id="sign" ><FiKey size = '1.5em'id="icon"/>Sign up</Button>
  <Button id="text">Login</Button>
</ButtonGroup>
     
    </Navbar>
    </div>
   
    );
}
 
export default Navigation;