import Popup from 'reactjs-popup';
import React, { useState, useEffect } from 'react';
import awsconfig from '../aws-exports';
import '../styles/setUp.css';
import { Amplify, Auth,API, Hub ,Logger,Storage } from "aws-amplify";
import Image from 'react-bootstrap/Image';
import { BrowserRouter as Router, Route, Routes, useNavigate, Redirect, Link, Navigate,NavLink } from 'react-router-dom';
import MobileAccountSetUp from './MobileAccountSetUp';
import { DataStore } from '@aws-amplify/datastore';
import { User,Errors } from '../models';
import mobileSignUpSubmit from '../mobileAssets/images/mobileSignUpSubmit.svg'
import PasswordChecklist from "react-password-checklist"
import{BiHide,BiShow} from 'react-icons/bi'
import{AiOutlineClose,AiOutlineCloseCircle,AiOutlineCheck,AiOutlineCheckCircle,AiFillCheckCircle,AiFillCloseCircle}from 'react-icons/ai'
import { CDBBtn } from 'cdbreact';
import BlueButton from '../screens/components/BlueButton';
import AvatarGenerator from '../screens/components/AvatarGenerator';

Amplify.configure(awsconfig);
export default function MobileSignUp(screenSize) {
  const navigate = useNavigate();
  const [formState, setFormState] = useState("signUp");

  const [formInputState, setformInputState] = useState({ email: '', password: '', confirmPassword:'',preferred_username: '', name:'',verificationCode: '' });
  const [details,CheckSignIn] = useState({username: '', password: ''})
  const [Olddetails,returnSignIn] = useState()
  const [passwordShown, setPasswordShown] = useState(false);
  const[windowSize,setSize]= useState(screenSize.screenSize)
  useEffect(()=>{
    setSize(screenSize.screenSize)
  },[])
  function togglePassword  ()  {
    // When the handler is invoked
    // inverse the boolean state of passwordShown
    setPasswordShown(!passwordShown);
  };
function onChange(e) {
  setformInputState({ ...formInputState, [e.target.name]: e.target.value })
  CheckSignIn({...details, [e.target.name]: e.target.value })
  
}

function onChange2(e) {
 
returnSignIn({...Olddetails, [e.target.name]: e.target.value })
  
}


async function signUp() {
  
  if(!(formInputState.preferred_username.indexOf(' ') >= 0)){
    if(formInputState.password === formInputState.confirmPassword){
          if(formInputState.name == ' ' || formInputState.name == '' ||formInputState.preferred_username == ' '||formInputState.preferred_username == ''){
              alert("Username and Name fields cannot be empty")
          }else{

          
      try {
 
        await Auth.signUp({
          username: formInputState.email,
          password: formInputState.password,
          attributes: {
            preferred_username: formInputState.preferred_username,
            name: formInputState.name
          },
          autoSignIn: { // optional - enables auto sign in after user is confirmed
            enabled: true,
        }
        
        }
        );
     
        setFormState("confirmSignUp");
      } catch (err) { 
    
        DataStore.save(
          new Errors({
          "message": err.message,
          "userID": "failed to confirm",
          "userEmail": formInputState.email
        })
      ).then(()=>{
        if(err.message=="Username should be an email."){
          alert("Please enter a valid email")
        }else{
          alert(err.message );
        }
      }).catch((error)=>{
        if(err.message=="Username should be an email."){
          alert("Please enter a valid email")
        }else{
          alert(err.message );
        }
        
      })
      }
    
          }

    }else{
      alert('Passwords must match')
    }
  }else{
    alert('Username cannot contain a space')
  }

  
}


async function confirmSignUp() {

  try {
    await Auth.confirmSignUp(formInputState.email, formInputState.verificationCode);
    
   {signIn()}
    
  } catch (err) {
     alert(err.message)
   
     setFormState("confirmSignUpFail")
     }

}



 function signIn() {
  var d = new Date();
  Auth.signIn(details.email, details.password).then(async (newUserAdded)=>{
    await DataStore.save(
      new User({
   
      "name": newUserAdded.attributes.name,
      "preferred_username": newUserAdded.attributes.preferred_username,
      "UserID":newUserAdded.username,
      "userEmail":newUserAdded.attributes.email,
      "bio":' ' ,
      "earned":0,
      "wins":0,
      "staked":0,
      "isAdmin":false,
      "Wallet":"",
      "lastLogin":d.toLocaleDateString() +"-"+d.getHours()+":"+ d.getMinutes() 
      
     
    }));

    Storage.get("tempBanner.png", {
    
      download:true
  }).then((tempBanner)=>{
    console.log(tempBanner)
    Storage.put("Users/"+newUserAdded.username+"/Banner.png", tempBanner.Body, {
      
      contentType: tempBanner.Body.type
    })
      .then((result) =>{
        AvatarGenerator(newUserAdded.username)
  
      })
      .catch(err => console.log(err));
  }).catch((err)=>{console.log(err)})
  
    setFormState("accountSetUP");
   }).catch((err)=> console.log(err))

 
}


if (formState === "signUp") {
  return (
    <div className="mx-auto my-4"style={{height:window.innerHeight,width:window.innerWidth,overflowX:'hidden',overflowY:'auto'}}>

      
    <div  className="modal-area mx-4 mt-4 mb-2"  style={{background:'#00000040',height:"100%",width:"90%",
    maxWidth:window.innerWidth,overflowX:'hidden',overflowY:'auto',}} >
          <NavLink  to="/"   style={{textDecoration:'none'}}>

       <button
         
            aria-label="Close Modal"
            aria-labelledby="close-modal"
            className="_mobile-modal-close"
          
          >
            <span id="close-modal" className="_hide-visual">
              Close
            </span>
            <svg className="_mobile-modal-close-icon" viewBox="0 0 40 40">
              <path d="M 10,10 L 30,30 M 30,10 L 10,30" />
            </svg>
          </button>
          </NavLink>
          <div className="modal-body mx-auto">


 
<div className="my-auto" style={{width:'100%', justifyContent:'space-between',}} >

<p style={{color:'white', fontFamily:'Poppins-Regular',fontSize:'24px',lineHeight:'36px',
margin:'auto',fontWeight:'700',width:'263px'}}>Create an account</p>
<p style={{fontFamily: 'Poppins-Regular',color: 'white',  fontWeight: '400', fontSize: '14px',margin:'auto', width:'263px'}}>
Please fill in your details.
  </p>
    
      
      <div className="form-group mx-auto mt-4" style={{maxWidth:'90%'}}>
     
        <input   className="form-control"
           style={{borderBottom:'1px solid #00D4C5',borderTop:'0px',borderRight:'0px',borderLeft:'0px',borderRadius:'0px',background:'transparent',color:'white'}}
            name="name"  onChange={onChange}   placeholder="Name" autoComplete="new-password"/>
      </div>


      <div className="form-group mx-auto " style={{maxWidth:'90%'}}>        
    
        <input   className="form-control"
           style={{borderBottom:'1px solid #00D4C5',borderTop:'0px',borderRight:'0px',borderLeft:'0px',borderRadius:'0px',background:'transparent',color:'white'}}
           name="preferred_username" onChange={onChange}   placeholder="Username" autoComplete="new-password"/>
      </div>



      <div className="form-group mx-auto " style={{maxWidth:'90%'}}>       
   
        <input
      name="email"
      type="email"
      className="form-control"
      style={{borderBottom:'1px solid #00D4C5',borderTop:'0px',borderRight:'0px',borderLeft:'0px',borderRadius:'0px',background:'transparent',color:'white'}}
      autoComplete="new-password"
          onChange={onChange}
          placeholder="Email"
        />
        
      </div>



      <div className="form-group mx-auto " style={{maxWidth:'90%',borderBottom:'1px solid #00D4C5',borderTop:'0px',borderRight:'0px',borderLeft:'0px',borderRadius:'0px',}}>

    <div className="mobileFormgroupInput " style={{border:'0px',background:'transparent'}}>
        <input
        type={passwordShown ? "text" : "password"}
        className="form-control"
        style={{border:'0px',borderRadius:'0px',background:'transparent',color:'white'}}
        name="password"
          placeholder="password"
          onChange={onChange}
        />
          <button  style={{border:'0px',width:'24px',height:'24px',margin:'auto',background:'transparent'}} onClick={togglePassword}>
      {passwordShown ? 
        <BiHide  style={{color:"white", width:'24px',height:'24px',margin:'auto'}}/>
        :
        <BiShow style={{ color:"white",width:'24px',height:'24px',margin:'auto'}}/>
      }
        
        </button>
        </div>
      </div>
      <div className="mobileFormgroup mx-auto " style={{maxWidth:'90%'}}>

        <input
          type="password"
          className="form-control"
          style={{borderBottom:'1px solid #00D4C5',borderTop:'0px',borderRight:'0px',borderLeft:'0px',borderRadius:'0px',background:'transparent',color:'white'}}
         name="confirmPassword"
          placeholder="confirm password"
          onChange={onChange}
        />
      </div>
      <PasswordChecklist
				rules={["minLength","number","capital","match"]}
				minLength={8}
				value={formInputState.password}
				valueAgain={formInputState.confirmPassword}
				onChange={(isValid) => {}}
        style={{color:'rgb(255,255,255)',marginTop:'15px', width:'95%',textAlign:'left', flexWrap:'noWrap',display:'block',padding:'5px',}}
        iconSize={15}
        iconComponents={{ValidIcon: <AiOutlineCheckCircle style={{margin:'auto',color:'green',width:'1.5em',height:'24px'}}/>, 
        InvalidIcon: <AiOutlineCloseCircle style={{margin:'auto',color:'red', width:'1.5em',height:'24px'}} />}}
      />
      <div className='mobileTerms mx-auto'>
      <p className="mx-auto"style={{display:'flex',maxWidth:'90%',}} > 
        By logging in, I agree to the Chieve Terms of Service and Chieve Privacy Policy.
        </p>
      </div>
       

   <BlueButton  onClick={signUp} 
            className='ParagraphP2Semibold mx-auto my-2'>
  Sign Up
   </BlueButton>
    </div>
   
 
    </div>
    </div>
    </div>
  )
}




if (formState === "confirmSignUp") {
  return (
<div className="mx-auto"style={{height:window.innerHeight,width:window.innerWidth,overflowX:'hidden'}}>

      
<div  className="modal-area mx-4 mt-4" style={{background:'#00000040',height:'439px',width:"90%",
maxWidth:window.innerWidth,overflowX:'hidden',overflowY:'auto',}} >
<div className="modal-body mx-auto">


 
<div className="my-auto" style={{width:'100%', justifyContent:'space-between',}} >
 
<p style={{color:'white', fontFamily:'Poppins-Regular',fontSize:'24px',lineHeight:'36px',margin:'auto',fontWeight:'700',width:'263px'}}>Confirm Email!</p>
   <p style={{fontFamily: 'Poppins-Regular',color: 'white',  fontWeight: '400', fontSize: '14px',margin:'auto', width:'263px'}}>
   Please Check your email inbox for the code. Please, DO NOT EXIT this tab.
     </p>

<div className="mobileFormgroup" style={{margin:'auto',marginBottom:'28px',marginTop:'70px'}}>
  <label htmlFor="email">Verification Code</label>
  <input
name="verificationCode"
className="form-control "
style={{borderBottom:'1px solid #00D4C5',color:'white',borderTop:'0px',borderRight:'0px',borderLeft:'0px',borderRadius:'0px',background:'rgba(251,251,251,.8)'}}
    onChange={onChange}
    placeholder=""
  />
  
</div>

<BlueButton style={{margin:'auto',marginBottom:'28px',marginTop:'70px'}} onClick={confirmSignUp} >
Confirm
</BlueButton >



</div>

</div>
</div>
</div>
  )
}
if (formState === "confirmSignUpFail") {
  return (
    <div style={{height:'736px',width:'410px',margin:'auto',marginBottom:'116px',marginTop:'80px'}}>
    <div  className="mobile-modal-area-confirm" >
   
<div >


<div style={{width:'90%', margin:'auto',justifyContent:'space-between',marginTop:'1%',paddingBottom:'1%',}}>

<h2 style={{color:'white', fontFamily:'Poppins-Regular'}}>Confirm Email Failed</h2>
<p style={{fontFamily: 'Poppins-Regular',color: 'white',  fontWeight: '400', fontSize: '18px',display: 'flex', }}>
  Please make sure you typed the right code in!.
  </p>

<div className="form-group" style={{display: 'flex',width:'60%',marginTop:'2%', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', padding: '0px', gap: '8px',}}>
  <label >Verification Code</label>
  <input
name="verificationCode"
    className="form-control inputs "
    onChange={onChange}
    placeholder=""
  />
  
</div>

<div className="form-group" style={{display: 'flex',width:'60%', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', padding: '0px', gap: '8px',}}>
  <button className="form-control btn subMitButton"  onClick={confirmSignUp}>
  <Image 
          src={mobileSignUpSubmit}
          height="56px"
          width="330px"
        
        />
  </button>
</div>

</div>


</div>
</div>
</div>
  )
}


if (formState === "signIn") {
  return (
    <div>
      <input
        name="username"
        onChange={onChange}
      />
      <input
        name="password"
        onChange={onChange}
      />
      <button onClick={signIn}>Sign In</button>
    </div>
  )
}


if (formState === "accountSetUP") {

  return (
    <div className="mx-auto"style={{height:window.innerHeight,width:window.innerWidth,overflowX:'hidden'}}>

      
    <div  className="modal-area mx-4 mt-4" style={{background:'#00000040',height:'439px',width:"90%",
    maxWidth:window.innerWidth,overflowX:'hidden',overflowY:'auto',}} >
    <div className="modal-body mx-auto">
    
    
     
    <div className="my-auto" style={{width:'100%', justifyContent:'space-between',}} >
     
    <p style={{color:'white', fontFamily:'Poppins-Regular',fontSize:'24px',lineHeight:'36px',margin:'auto',fontWeight:'700',width:'263px'}}>Email was Verified</p>
  
   
 
    
    <NavLink  to="/"   style={{textDecoration:'none'}}>
     <BlueButton className="mx-auto my-4">
      Continue
     </BlueButton>
      </NavLink>
    </div>
  
    
    </div>
    </div>
    </div>
  )
}



}