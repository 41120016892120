import React, {useState, useEffect} from 'react';
import { DataStore } from '@aws-amplify/datastore';
import { Club, ClubUser, Post, ClubPost,NewPost } from '../models';
import { Amplify, Auth, Storage } from "aws-amplify";
import awsconfig from '../aws-exports';
import SplashScreen from '../SplashScreen'
import { Button, Form, Image, InputGroup } from 'react-bootstrap';
import '../styles/fonts/fonts.css'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ProgressBar from 'react-bootstrap/ProgressBar';

import { CDBBtn, CDBBtnGrp, CDBInput,} from "cdbreact";
import 'bootstrap/dist/css/bootstrap.min.css';

import { Placeholder, SelectField } from '@aws-amplify/ui-react';


import '@aws-amplify/ui-react/styles.css';
Amplify.configure({...awsconfig, ssr: true});
Storage.configure({ track: true, level: "public" });


export default function AdminSettings(props){

const [loading,setLoading] =useState(true)
const [allClubs, setClubs] = useState([])
const [title, setTitle] = useState('');
const [message, setMessage] = useState('');
const [youtubeLink, setYoutubeLink] = useState('');
const [imageData, setImageData] = useState('');
const [videoData, setVideo] = useState('');
const [link, setWebLink]=useState('')
const [selectedFile, setSelectedFile]=useState(null)
const [selectedImageFile,setSelectedImageFile]=useState(null)
const [selectedClub,setSelectedClub]=useState(null)
const [clubName,setSelectedClubName]=useState(null)
const [newposter, setNewPosts]=useState([])
const [mediaType, setMediaType]=useState(null)
const [clubDetails, setClubDetails]=useState(null)
const [uploadProg, setProg]=useState({'done':0,'total':0})
useEffect(()=>{
    setLoading(true)
  
    getClubData()
  
  
},[])


async function getClubData(){
  setLoading(true)
    
  try{
      const tempClubs = await DataStore.query(Club)
      const clu = tempClubs.filter((c)=> c.category == 'Gaming')
      const tempPost = await DataStore.query(NewPost)
      const po = tempPost
     setClubs(clu)
     let tempNew = []
     po.forEach((indPost)=>{
      var timeCreated = new Date(indPost.createdAt);
      var currentdate = new Date();
    

            let tp =''
        
         if(timeCreated.getHours() >= 12){
          let hourPM = timeCreated.getHours()-12
          let mins = parseInt(timeCreated.getMinutes()) < 10 ? "0"+ timeCreated.getMinutes() : timeCreated.getMinutes();
        tp=  timeCreated.toLocaleDateString() +' - '+hourPM +":"+mins+" pm"
         }else{
          let mins = parseInt(timeCreated.getMinutes()) < 10 ? "0"+ timeCreated.getMinutes() : timeCreated.getMinutes();

          tp=  timeCreated.toLocaleDateString() +' - '+timeCreated.getHours() +":"+mins+" am"
      
         }
      if(indPost.video != null){
    
    Storage.get(indPost.video,{

    }).then((vid)=>{
      tempNew.push({...indPost,"videoLinks":vid,"time":tp})
    }).catch(err=>console.log(err))
        
      }else if(indPost.image != null){
        
        Storage.get(indPost.image,{

        }).then((imgLink)=>{
          tempNew.push({...indPost,"imageLink":imgLink,"time":tp})
        }).catch(err=>console.log(err))
      }else{
        tempNew.push({...indPost,"time":tp})
      }


      setNewPosts(tempNew)
      
     })
       
      
  }catch(err){

    setLoading(false)

  }
  setLoading(false)

}

if(loading){
    return(
      <div className="mx-auto my-auto" style={{display:'flex',width:window.innerWidth,height:window.innerHeight}}>
    
      <ProgressBar max={uploadProg.total} now={uploadProg.done}  className="mx-auto my-auto w-50"/>
      </div>
    )
}
const styles={
  post:{
    width:'40%',
    margin:'auto',
    marginTop:'15px',
    background:'#00000040',
    },
  }

const handleChange =(e)=>{
  console.log(e.target.value.length)
      if(e.target.value == '' || e.target.value.length == 0){
        setSelectedClub(null)
      setClubDetails(null)
         
        setSelectedClubName('')
      }else{
        setSelectedClub(e.target.value)
        console.log(allClubs)
        let t = allClubs.filter((c)=> c.id == e.target.value)
          setClubDetails(t[0])
        setSelectedClubName(t[0].name)
      }



 
}
const handleChangeMedia =(e)=>{
  setMediaType(e.target.value)
}
const handleTitleChange = (e) => {
  setTitle(e.target.value);
};

const handleMessageChange = (e) => {
  setMessage(e.target.value);
};

function getId(url) {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const match = url.match(regExp);

  return (match && match[2].length === 11)
    ? match[2]
    : null;
}
const handleYoutubeLink = (e) => {
  const videoId = getId(e.target.value);
  setYoutubeLink("//www.youtube.com/embed/"+videoId);
  console.log("https//www.youtube.com/embed/"+videoId)
  setSelectedFile(null)
  setImageData('')
  setVideo('')
};
const handleImageChange = (e) => {
  const file = e.target.files[0];
  const reader = new FileReader();

  reader.onload = () => {
    setImageData(reader.result);
    setSelectedImageFile(file)
    setSelectedFile(null)
    setYoutubeLink('')
    setVideo('')
  };

  if (file) {
    reader.readAsDataURL(file);
  }
};
const handleVideoChange = (e) => {
  const file = e.target.files[0];
  const reader = new FileReader();

  reader.onload = () => {
    setVideo(reader.result);
    setSelectedFile(file)
    setSelectedImageFile(null)
    setYoutubeLink('')
    setImageData('')
   console.log(file)
  };

  if (file) {
    reader.readAsDataURL(file);
  }
};
const ClearForm = ()=>{
  setTitle('');
  setMessage('');
  setImageData('');
  setVideo('')
  setYoutubeLink('');
  setMediaType(null);
  setSelectedClub(null);
  setSelectedClubName('');
  setNewPosts([])
}
const handleSubmit =  (e) => {
  e.preventDefault();

  // Here, you can perform any desired action with the form data,
  // such as sending it to an API or storing it in a database.
  setLoading(true)
 if(mediaType =='video'){
  let rando = Math.random()*Math.random()+Math.random()
  let randoName = rando+''+selectedFile.name;
  let path2 = "Clubs/"+clubDetails.ClubStorageID +"/PostImage/"+rando+"/"+randoName
    Storage.put(path2,selectedFile,{
      contentType: selectedFile.type,
      progressCallback(progress) {
      //  console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
        setProg({'done':progress.loaded,'total':progress.total})
      },
    }).then((res)=>{

      DataStore.save(
        new NewPost({
        "title": title,
        "message": message,
        "image": null,
        "link": link,
        "youtubeLink": null,
        "clubID": selectedClub,
        "video":  res.key,
      })
    ).then(()=>{
      setTitle('');
      setMessage('');
      setImageData('');
      setVideo('');
      setSelectedImageFile(null);
      setSelectedClub(null);
      setSelectedFile(null);
      setNewPosts([])
      setLoading(false)
      
    getClubData()
    }).catch((err)=>{
      console.log(err)
      alert(err)
      setTitle('');
      setMessage('');
      setImageData('');
      setVideo('');
      setSelectedImageFile(null);
      setSelectedClub(null);
      setSelectedFile(null);
      setNewPosts([])
      setLoading(false)
    })


     
    }).catch((err)=>{
      setTitle('');
      setMessage('');
      setImageData('');
      setVideo('');
      setSelectedImageFile(null);
      setSelectedClub(null);
      setSelectedFile(null);
      setNewPosts([])
      setLoading(false)
    })
 }else if(mediaType =='image'){
  let rando = Math.random()*Math.random()+Math.random()
  let randoName = rando+''+selectedImageFile.name;
  let path2 = "Clubs/"+clubDetails.ClubStorageID +"/PostImage/"+rando+"/"+randoName
  Storage.put(path2,selectedImageFile,{
    contentType: selectedImageFile.type,
    
  }).then((res)=>{

    DataStore.save(
      new NewPost({
      "title": title,
      "message": message,
      "image": res.key,
      "link": link,
      "youtubeLink": null,
      "clubID": selectedClub,
      "video":  null,
    })
  ).then(()=>{
    setTitle('');
    setMessage('');
    setImageData('');
    setVideo('');
    setSelectedImageFile(null);
    setSelectedClub(null);
    setSelectedFile(null);
    setLoading(false)
    setNewPosts([])
    getClubData()
  }).catch((err)=>{
    console.log(err)
   
    setTitle('');
    setMessage('');
    setImageData('');
    setVideo('');
    setSelectedImageFile(null);
    setSelectedClub(null);
    setSelectedFile(null);
    alert(err)
    setNewPosts([])
    setLoading(false)
  })

  }).catch((err)=>{
    
    setTitle('');
    setMessage('');
    setImageData('');
    setVideo('');
    setSelectedImageFile(null);
    setSelectedClub(null);
    setSelectedFile(null);
    alert(err)
    setNewPosts([])
    setLoading(false)
  })
 }
    else{

      
  DataStore.save(
    new NewPost({
		"title": title,
		"message": message,
		"image": null,
		"link": link,
		"youtubeLink":mediaType =='youtube'? youtubeLink : null,
		"clubID": selectedClub,
    "video":  null,
	})
).then(()=>{
  setTitle('');
  setMessage('');
  setImageData('');
  setVideo('');
  setSelectedImageFile(null);
  setSelectedClub(null);
  setSelectedFile(null);
  setLoading(false)
  
  getClubData()
}).catch((err)=>{
  console.log(err)
  
  setTitle('');
  setMessage('');
  setImageData('');
  setVideo('');
  setSelectedImageFile(null);
  setSelectedClub(null);
  setSelectedFile(null);
  alert(err)
  setLoading(false)
  
})
 }


  // Reset form fields
 
};
const delPosts = async(p)=>{
  console.log(p)
 
  try{
    const deldel = await DataStore.query(NewPost,(u)=>u.id('eq',p.id))
    console.log(deldel)
    DataStore.delete(deldel[0]);
    getClubData()
  }catch(err){
    console.log(err)
  }
 
}
const editPosts = async(p)=>{
  console.log(p)
  try{
    const toEdit = await DataStore.query(NewPost,(u)=>u.id('eq',p.id))
  
    await DataStore.save(
      NewPost.copyOf(toEdit[0], updated => {
        updated.message= p.message.toString()
        updated.title= p.title.toString()

      })
      );
    getClubData()
  }catch(err){
    console.log(err)
  }
 
}
const formInputs = ()=>{
  
if(selectedClub != null){
  console.log(newposter.filter((x)=>x.clubID == selectedClub))
  return(
    
    <Container className='mx-auto my-4' >
      <Row>

      <Col>
      
      
 <Form  onSubmit={handleSubmit} className='bg-dark p-2' style={{borderRadius:'10px'}}>
      <Form.Group className="mb-3" >
      <Form.Label htmlFor="title">Title:</Form.Label>
        <Form.Control  type="text"
              className="form-control"
              id="title"
              value={title}
              onChange={handleTitleChange}
               />
      
      </Form.Group>

      <Form.Group className="mb-3" >
      <Form.Label htmlFor="message">Message:</Form.Label>
        <Form.Control  as="textarea"
              className="form-control"
              id="message"
              row={3}
              value={message}
              onChange={handleMessageChange}
               />
      
      </Form.Group>

      <Form.Group className="mb-3" >
      <Form.Select onChange={(e)=>{handleChangeMedia(e)}}>
      <option value={null} >Add media</option>
          <option value="image" >Image</option>
          <option value="video" >video</option>
          <option value="youtube" >Youtube Embedded Link</option>
    </Form.Select>
      </Form.Group>


      {mediaType !== null && mediaType== "image" ?
                <Form.Group className="mb-3" >
            <Form.Label htmlFor="image">Image:</Form.Label>
           
            <Form.Control type="file"   id="image"
              accept="image/*"
              onChange={handleImageChange}
              required/>
            
            </Form.Group>

            :
            ''
            }


{mediaType !== null && mediaType== "video" ?
             <Form.Group className="mb-3" >
            <Form.Label htmlFor="video">Video:</Form.Label >
        
            <Form.Control type="file"   
            id="video"
              accept="video/*"
              onChange={handleVideoChange}
              required
              
              />
            <div>
              
            </div>
              </Form.Group>

            :
            ''
            }
            {mediaType !== null && mediaType== "youtube" ?
             <Form.Group className="mb-3" >
            <Form.Label htmlFor="link">Youtube Link (COPY AND PASTE THE LINK! not the embeded link, the link on the browser):</Form.Label >
        
            <Form.Control type="text"   
        id="youtubeLink"
        value={youtubeLink}
        onChange={handleYoutubeLink}
              
              />
         

              </Form.Group>

            :
            ''
            }
      <Button variant="primary" type="submit">
        Submit
      </Button>
    
      <Button variant="danger" type="cancel" className="ms-4" onClick={()=>ClearForm()}>
      Cancel
      </Button>
    </Form>

    </Col>
      
    <Col>
    <div className="mx-auto my-4" style={{display:'block',width:'90%'}}>

    <p className="ParagraphP1Regular" style={{color:'white',fontWeight:'600',fontSize:'18px'}}>Preview</p>

    <div className="card gedf-card mx-auto"  style={{width:'90%',margin:'auto', marginTop:'15px',background:'#00000040',
        }}>
     
    <div className="card-header mx-auto" style={{borderBottom:'1px solid #00d4c5' ,width:'95%'}}>
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex justify-content-between align-items-center">
               
                <div className="">
                  <div className="h5 mx-0 my-2" style={{fontWeight:'600',color:'white'}}>
                  @{clubName}
                  </div>
                  <div className="h7 text-muted"> </div>
                </div>
              </div>
           
            </div>
          </div>
                     <div className="card-body mx-2"> 
          <div className="h7 mb-2 " style={{color:'#00D4C5'}}>
              {" "}
             
            </div>
          
            <h5 className="card-title" style={{color:'white',width:'95%',flexWrap:'wrap',fontWeight:'600',fontSize:'18px'}}>
              {title}
           </h5>
           {mediaType !== null && mediaType== "image"  && imageData!= ''?
           
           <Image className="mx-auto "src={imageData} height="100%" style={{maxWidth:'400px',display:'flex',margin:'auto',maxHeight:'400px'}}/>

          :
         ''
        
        }

        {mediaType !== null && mediaType== "video" && videoData!= '' && selectedFile != null? 
        <video className="mx-auto img-fluid" style={{maxWidth:'400px',display:'flex',margin:'auto',maxHeight:'400px'}} controls>
        <source src={videoData} type="video/mp4" />

        </video>
              :
             ''
        }

      {mediaType !== null && mediaType== "youtube" && youtubeLink !==''?
      <iframe  src={youtubeLink} width="400px" height="315px"  title="YouTube video player" 
          frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
          allowfullscreen style={{maxWidth:'90%',margin:'auto',display:'flex',maxHeight:'315px'}} />
      :
      ''
          }

          {mediaType !== null && youtubeLink==''&& imageData==''&& videoData=='' ?
             <Placeholder className="mx-auto " style={{width:'400px',display:'flex',margin:'auto',height:'400px'}} />
             :
             ''
        
        }
          
          <p className='ParagraphP2Regular my-2 mx-2 px-2 py-2'  style={{width:'90%',lineHeight:'1.5',flexWrap:'wrap',color:'white'}}>
              {message}
              </p>
          </div>
                     

            </div>

    </div>
    </Col>
    </Row>

    {/* edit post section **/}
    <Row >
    <Col >

    {newposter.filter((x)=>x.clubID == selectedClub).map((p)=>{
      return(
        <div className="mx-auto mt-2" style={{display:'block',width:'90%'}}>

        
    
        <div className="card gedf-card mx-auto"  style={{width:'90%',margin:'auto', marginTop:'15px',background:'#00000040',
            }}>
         
        <div className="card-header mx-auto" style={{borderBottom:'1px solid #00d4c5' ,width:'98%',display:'flex'}}>
                
                
                   
                    <div className="me-2" stlye={{width:'90%'}}>
                      <div className="h5  my-2" style={{fontWeight:'600',color:'white'}}>
                      @{clubName}

                      </div>

                   
                  
                    </div>
                    <CDBBtn onClick={()=>delPosts(p)} style={{display:'flex'}}>
                          Delete
                      </CDBBtn>
                     
              </div>
                         <div className="card-body mx-2"> 
              <div className="h7 mb-2 " style={{color:'#00D4C5'}}>
                  {" "}
                 
                </div>
              
                <h5 className="card-title" style={{color:'white',width:'95%',flexWrap:'wrap',fontWeight:'600',fontSize:'18px'}}>
                  {p.title}
               </h5>
               {p.image!=null?
               
               <Image className="mx-auto "src={p.imageLink} height="100%" style={{maxWidth:'400px',display:'flex',margin:'auto',maxHeight:'400px'}}/>
    
              :
             ''
            
            }
    
            {p.video!=null? 
            <video className="mx-auto img-fluid" style={{maxWidth:'400px',display:'flex',margin:'auto',maxHeight:'400px'}} controls>
            <source src={p.videoLinks} type="video/mp4" />
    
            </video>
                  :
                 ''
            }
    
          {p.youtubeLink != null?
          <iframe  src={p.youtubeLink} width="400px" height="315px"  title="YouTube video player" 
              frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
              allowfullscreen style={{maxWidth:'90%',margin:'auto',display:'flex',maxHeight:'315px'}} />
          :
          ''
              }
    
              
              <p className='ParagraphP2Regular my-2 mx-2 px-2 py-2'  style={{width:'90%',lineHeight:'1.5',flexWrap:'wrap',color:'white'}}>
                  {p.message}
                  </p>
              </div>
                         
    
                </div>
    
        </div>
      )
    })}
   
    </Col>
    <Col>
    </Col>
    </Row>
  </Container>

  )
}
if(selectedClub == null){
  return (
    <div>

    </div>
  )
}


}
const renderPosts =()=>{
  if(newposter.length > 0){
    return(
      newposter.map((x)=>{
        if(x.image.includes('video')){
          console.log(x)
        }
      
          return(
            <div className="my-4"style={{maxWidth:'200px',maxHeight:"300px",color:"white"}}>
              title:{x.title} <br/>
            {x.image.includes('video')?
            <video width="750" height="500" controls >
            <source src={x.image} type="video/mp4"/>
           </video>
:

<Image className="img-fluid" style={{display:'flex'}}src={x.image} />

            }
           
            </div>
          )
      })
    )
  }
  if(newposter.length == 0){
    return(
      <div>
        
      </div>
    )
  }
}
 //const subscription = DataStore.observe(NewPost).subscribe(msg => {newposter.push(msg.element)});
if(!loading){


  return(
    <div className="mx-auto w-100 " style={{display:'flex',width:window.innerWidth, height:window.innerHeight, }}>
        <div className="mx-auto w-75" style={{display:'block',width:window.innerWidth, overflowY:"scroll"}}>
       
          <SelectField className="mx-auto w-75 mt-4"style={{height:'90%',display:'flex',background:'white',}} label="Clubs" 
          descriptiveText="Pick A Club" placeholder="Choose a Club" onChange={(e)=>{handleChange(e)}}>
            
            {allClubs.map((c,index)=>{
              return(
                <option value={c.id} key={index}>{c.name}</option>
              )
            })}
          </SelectField>
            

         {formInputs()}

  
        </div>
    </div>
  )
}

}

/*

  <form onSubmit={handleSubmit}>
          <div className="form-group">
            <Form.Label htmlFor="title">Title:</Form.Label>
            <input
              type="text"
              className="form-control"
              id="title"
              value={title}
              onChange={handleTitleChange}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="message">Message:</label>
            <textarea
              className="form-control"
              id="message"
              value={message}
              onChange={handleMessageChange}
              required
            />
          </div>
          <SelectField className="mx-auto w-75 mt-4"style={{height:'90%',display:'flex',background:'white',}}  placeholder="Add Media" onChange={(e)=>{handleChangeMedia(e)}}>
          <option value="image" >Image</option>
          <option value="video" >video</option>
          <option value="youtube" >Youtube Embedded Link</option>
        </SelectField>
         
            {mediaType !== null && mediaType== "image" ?
             <div className="form-group" style={{display:'block',maxWidth:'800px'}}>
            <label htmlFor="image">Image:</label>
           
            <Form.Control type="file"   id="image"
              accept="image/*"
              onChange={handleImageChange}
              required/>
            <div>
            
            </div>
              </div>

            :
            ''
            }
              {mediaType !== null && mediaType== "video" ?
             <div className="form-group" style={{display:'block',maxWidth:'800px'}}>
            <label htmlFor="video">Video:</label>
        
            <Form.Control type="file"   
            id="video"
              accept="video/*"
              onChange={handleVideoChange}
              required
              
              />
            <div>
              
            </div>
              </div>

            :
            ''
            }
             {mediaType !== null && mediaType== "youtube" ?
             <div className="form-group">
            <label htmlFor="link">Youtube Embedded Link:</label>
            <textarea
              className="form-control"
              id="youtubeLink"
              value={youtubeLink}
              onChange={handleYoutubeLink}
              required
            />
              </div>

            :
            ''
            }
            
            
    
        
          <button type="submit" className="btn btn-primary">Submit</button>
          <button type="cancel" className="btn btn-danger ms-4" onClick={()=>ClearForm()}>cancel</button>
        </form>

        **/

       