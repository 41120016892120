

import { useState, useEffect } from 'react';
import { Auth, Storage } from 'aws-amplify';
import { NavLink } from 'react-router-dom';
import Image from 'react-bootstrap/Image';
import { Network, Alchemy } from "alchemy-sdk";
import 'reactjs-popup/dist/index.css';
import { DataStore } from '@aws-amplify/datastore';
import '../styles/fonts/fonts.css'
import { User, ClubUser } from '../models';
import { CDBCard, CDBCardBody } from 'cdbreact';
import StatusChecks from './components/StatusChecks';
import { Divider } from '@aws-amplify/ui-react';
import Loader from './Loader2';
import Popup from 'reactjs-popup';

Storage.configure({ track: true, level: "public" });
const settings = {
  apiKey: "judyKIq12rtyO-zasR-253fFj13tXf6S",
    network: Network.MATIC_MAINNET,
};

const alchemy = new Alchemy(settings);


const UserPage =(props)=>{

   const [error, setError] = useState(null);
   const [loading, setLoading] = useState(true);
   const [username, setUsername] = useState('');
   const [userFullname, setUserFullname] = useState('');
   const [CurrentUser, setUser] = useState();
   const [wallet, setWallet] = useState(null);
   const [Userbio, setBio] = useState(null);
   const [UserImage, setImage] = useState(null);
   const [UserNFTS, getNFTS] = useState(null);
   const [nftCount, MaxNFT] = useState(null);
   const [UserBanner, setBannerImage] = useState(null);
   const [UserMainNFTS, getMainNFTS] = useState([]);
  const [admin, checkAdmin]= useState(false);
 const[walletConnected,walletConnectButton] = useState(false)
 const [windowSize, setWindowSize] = useState(getWindowSize());
 const [clubInfoList, getjoinedClubs]=useState([])
 const [mouseHover,setHover] = useState(false)
 const [isOnline,checkOnline] = useState(false)
 const setColor = ()=>{
   setHover(true)
 }
 const fixColor = ()=>{
   setHover(false)
 }
 const tester = async (w) => {
   //  const contractAddress = "0x818830D10979A21a7C061359AF7257C378E2a5E0";
 

   var NftOwned = await alchemy.nft.getNftsForOwner(w)
   let temp =  NftOwned.ownedNfts.filter((n) => n.contract.address.toLowerCase() == "0x4e682181ff8c6a3107add2abc108c9f19f68f3fa"  )
     
   if (temp.length !== 0) {
     
       MaxNFT(temp.length)
     
     return temp
   } else {
     return null
   }
 
 
 
   }

   const checkUserOnlineStatus = async (id)=>{
     
      
       const  cUser = await StatusChecks(id)
      
       return cUser
 
  
      
   }
useEffect(()=>{


   try {
     setError(null);
     setLoading(true);
     setUsername(props.Info.preferred_username);
       setUserFullname(props.Info.name);
       setUser(props.Info)

     Auth.currentAuthenticatedUser({
       bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
     }).then(user => {
      
      console.log(props.Info)
         const checkerStatus = checkUserOnlineStatus(props.Info.UserID)
         checkerStatus.then((y)=>{
      
           if(y){
             checkOnline(y)
           }else{
             checkOnline(false)
           }
      
         })
         DataStore.query(ClubUser).then((info)=>{
           let tempClubs=[]
         info.filter((u)=> u.user.UserID== props.Info.UserID).forEach((clubGet)=>{
         if(  clubGet.club.category =="Gaming"){

    
           Storage.get("Clubs/"+clubGet.club.ClubStorageID+"/ClubLogo.png")
           .then((bannerLink)=>{
               let urlCLub = clubGet.club.name.replace(/ /g, '');
               
               
               tempClubs.push({
                   "img":bannerLink,
                   "clubName":clubGet.club.name,
                   "clubURL":urlCLub,
               })
               getjoinedClubs(tempClubs)
            
           }).catch(err=>console.log(err))
         }
         })
       
         }).catch((err)=> console.log(err))


         DataStore.query(User, u => u.UserID("eq", props.Info.UserID)).then((userWallet)=>{
          if(userWallet[0].Wallet){
            setWallet(userWallet[0].Wallet);
            var owned = tester(userWallet[0].Wallet) 
            owned.then((y) => {
              getMainNFTS(y)
            })
          }
         
         })
         Storage.get("Users/"+props.Info.UserID+"/Pro.png").then((r) => {
           if (r) {
             setImage(r)
          
            
           }
         });

         Storage.get("Users/"+props.Info.UserID+"/Banner.png", {
           level: "public",
           
         }).then((r) => {
           if (r) {
             setBannerImage(r)

           }
         }).catch((error)=>console.log('error ', error));

       
       if (props.Info.bio) {
         setBio(props.Info.bio);
       }
       // TBD
     }).catch(err => {

       setError(err)
       setUsername(props.Info.preferred_username);
       setUserFullname(props.Info.name);
       setUser(props.Info)
   
         const checkerStatus = checkUserOnlineStatus(props.Info.UserID)
         checkerStatus.then((y)=>{
      
           if(y){
             checkOnline(y)
           }else{
             checkOnline(false)
           }
      
         })
         DataStore.query(ClubUser).then((info)=>{
           let tempClubs=[]
         info.filter((u)=> u.user.UserID== props.Info.UserID).forEach((clubGet)=>{
         if(  clubGet.club.category =="Gaming"){

    
           Storage.get("Clubs/"+clubGet.club.ClubStorageID+"/ClubLogo.png")
           .then((bannerLink)=>{
               let urlCLub = clubGet.club.name.replace(/ /g, '');
               
               
               tempClubs.push({
                   "img":bannerLink,
                   "clubName":clubGet.club.name,
                   "clubURL":urlCLub,
               })
               getjoinedClubs(tempClubs)
            
           }).catch(err=>console.log(err))
         }
         })
       
         }).catch((err)=> console.log(err))


         DataStore.query(User, u => u.UserID("eq", props.Info.UserID)).then((userWallet)=>{
           setWallet(userWallet[0].Wallet);
           var owned = tester(userWallet[0].Wallet) 
           owned.then((y) => {
             getMainNFTS(y)
           })
         })
         Storage.get("Users/"+props.Info.UserID+"/Pro.png").then((r) => {
           if (r) {
             setImage(r)
          
            
           }
         });

         Storage.get("Users/"+props.Info.UserID+"/Banner.png", {
           level: "public",
           
         }).then((r) => {
           if (r) {
             setBannerImage(r)

           }
         }).catch((error)=>console.log('error ', error));

       
       if (props.Info.bio) {
         setBio(props.Info.bio);
       }
     });


   }
   catch (e) {
     setError(e);
   }
   finally {
     setLoading(false);
   }
 
},[])


useEffect(() => {
   function handleWindowResize() {
       setWindowSize(getWindowSize());
      
     }
     
   window.addEventListener('resize', handleWindowResize);
   return () => {
       window.removeEventListener('resize', handleWindowResize);
     
     };
 }, []);



 const styles={
   main:{
     height:"fit-content",
     minHeight:window.innerHeight,
     width:windowSize.innerWidth,
     maxWidth:windowSize.innerWidth,
     margin:'auto',
     display:'block'
 },
   header:{
       display:'flex',
       flexDirection:'row',
       maxWidth:'1320px',
       overflow:'hidden',
       width:'100%',
       margin:'auto',
       marginTop:'20px',
       height:'fit-content',
      
   },
   bannerSection:{
       display:'block',
       margin:'32px 5px 16px 40px',
       
      overflow:'hidden',
      height:'100%',
       maxWidth:'800px',
       width:'800px'
   },
   walletSection:{
       margin:'32px 40px 16px 20px',
       
       display:'block',
    
       overflow:'hidden',
       width:'440px',
       height:'384px',
       background:'linear-gradient(131deg, #aeffff 0%, #72b3ff 100%)',
       borderRadius:'12px',
       display:'block'
   },
   bio:{
       display:'block',
       marginBottom:'20px',
       maxWidth:'960px',
       margin:'12px 5px 16px 40px',

   },
   content:{
       display:'flex',
       flexDirection:'row',
       maxWidth:'1320px',
       overFlow:'hidden',
       width:'100%',
       margin:'auto',
       marginTop:'20px',
       padding:'10px'
   },
   clubList:{
       display:'block',
       width:'20%',
       maxWidth:'130px',
   
       height:'600px',
       
  
   },

   collectionList:{
       display:'block',
       width:'80%',
       maxWidth:'670px',
  
       height:'600px',
   }
 }


  
 if(loading){
   return(
     <Loader />
   )
 }
if(!loading){
 
 function nftGallery(nft) {
  
     return(
       
       <Popup trigger={ 
       <CDBCard 
       className="p-2" style={{display:'block',
       background:mouseHover? '#1c1f35':'#151b4e',
       width: "300px",borderRadius:'4px',border:'0px',overFlow:'hidden'}} onMouseEnter={setColor} onMouseLeave={fixColor} >
       <Image src={nft.rawMetadata.image} className="img-fluid mx-auto mb-2 mt-2" style={{borderRadius:'4px',maxWidth:'250px',  display:'flex'}}/>
       <CDBCardBody className="mx-auto ">
           <p className="ParagraphP1Semibold"style={{color:'white'}}>{nft.title}</p>
       </CDBCardBody>
     </CDBCard>} 
     style={{background:'transparent'}}
       modal
       nested>
           {close => ( 

<CDBCard className="p-2 mx-auto" style={{display:'block',width: "100%",overFlow:'hidden', height:'fit-content'}} >
<p className="my-2 ms-2 p-2 ParagraphP1Semibold" style={{textAlign:'center',borderBottom:'1px solid gray'}}>{nft.title}</p>
<Image src={nft.rawMetadata.image} className="img-fluid mx-auto mb-2" style={{maxWidth:'250px',  display:'flex'}}/>
<CDBCardBody className="mx-auto ">

<p className="my-2 ms-2">About: <br/>{nft.description}</p>
<p className="my-2 ms-2">Contract: <strong>{nft.contract.address}</strong></p>
<p className="my-2 ms-2">Token: <strong>{nft.tokenType}</strong></p>
</CDBCardBody>
</CDBCard>

           )}
      
     </Popup>
   
    
     
       
     )
   
    
 }
 const checkerStatus = checkUserOnlineStatus(props.Info.UserID)
 checkerStatus.then((y)=>{
 
   if(y){
     checkOnline(y)
   }else{
     checkOnline(false)
   }

 })

 return(
   <div style={styles.main}>
       <div style={{width:window.innerWidth,maxHeight:'153px',height:'153px',overflowY:'hidden',overflowX:'hidden'}}>
       <Image className="rounded  mx-auto" src={UserBanner} width="100%"
        style={{maxWidth:window.innerWidth,margin:'auto' }}/>
       </div>
         <div className="mx-auto" style={{display:'block',width:'50%'}}>
         <Image src={UserImage} className="mx-auto"style={{height:'150px',width:'150px',borderRadius:'100%',border:'1px solid white',display:'block',marginTop:'-5rem',display:'flex'}}/>
         <div className="mx-auto mt-4" style={{display:'block',width:'340px'}}>

       
           <p className="HeadingH5Semibold mx-auto" style={{color:'white',textAlign:'center',maxWidth:'100%',fontSizeAdjust:'0.5',fontSize:'16px',fontWeight:'700'}}>
           @{username}
               </p>
             
               {isOnline ?
            <p className="HeadingH5Semibold mx-auto" style={{color:'white',textAlign:'center',maxWidth:'100%',fontSizeAdjust:'0.5',fontSize:'16px',fontWeight:'700'}}>
         Status: <strong style={{color:'#81F34B'}}>Online </strong>
          </p>
          :
          <p className="HeadingH5Semibold mx-auto" style={{color:'white',textAlign:'center',maxWidth:'100%',fontSizeAdjust:'0.5',fontSize:'16px',fontWeight:'700'}}>
          Status: <strong  style={{color:'#D22B2B'}}>Offline </strong>
          </p>
          }

       <div className="mx-auto my-2"style={{display:'flex',width:'200px',maxHeight:'70px',}}>
               <div className="mx-auto mt-2"style={{display:'block',width:'50px',color:'white'}}>
                           <p className="mx-auto" style={{width:'49px',fontSize:'14px',textAlign:'center'}}>Earned: <br/>${CurrentUser.earned ? CurrentUser.earned :"0"}</p>
                         
               </div>
               <Divider className="mx-2 my-auto" style={{height:'50px'}} border="1px solid white"
orientation="vertical" />
               <div className="mx-auto mt-2"style={{display:'block',width:'50px',height:'30px',color:'white'}}>
                           <p className="mx-auto" style={{width:'49px',fontSize:'14px',textAlign:'center'}}>Staked: <br/> $0</p>
                        
               </div>
               <Divider className="mx-2 my-auto" style={{height:'50px'}} border="1px solid white"
orientation="vertical" />

               <div className="mx-auto mt-2"style={{display:'block',width:'50px',color:'white',height:'30px'}}>
                           <p className="mx-auto" style={{width:'49px',fontSize:'14px',textAlign:'center'}}>Wins: <br/> {CurrentUser.wins? CurrentUser.wins : 0}</p>
               </div>
       </div>
       
               <p className="ParagraphP2Regular mx-auto" style={{color:'white',fontWeight:'bold',fontSize:'12px',width:'100%',textAlign:'center'}}>Bio</p>
      <p className="ParagraphP2Regular mx-auto" style={{color:'white',width:'90%',textAlign:'center',fontSize:'14px'}}>
     {Userbio ? Userbio : '...'}
       </p>
</div>

         </div>



           <div className="mx-auto my-4" style={{maxWidth:'1120px',display:'flex'}}>

                   <div className="mx-auto" style={{maxWidth:'345px',overflowY:'hidden',overflowX:'hidden',width:'345px',maxHeight:'437px',height:'437px',display:'block',background:'#00000040',borderRadius:'20px'}}>
                           
                           <p className="ParagraphP1Regular " style={{fontWeight:'700',fontSize:'24px',color:'white',marginTop:'18px',marginLeft:'29px'}}>Clubs</p>
                   <div className="mx-auto" style={{display:'flex',maxWidth:'330px',maxHeight:'437px',overflowY:'scroll',overflowX:'hidden',flexWrap:'wrap',}}>
                   { clubInfoList.length != 0 ?
           clubInfoList.map((cI)=>{
                   return(
                     <div className="mx-auto my-2" 
                     style={{textDecoration:'none',borderRadius:'100%',overflowY:'hidden',overflowX:'hidden',height:'85px',maxHeight:'85px',display:'flex',width:'85px',maxWidth:'85px',background:'#00000040',}}>
                    
                                              <NavLink   to={"/"+cI.clubURL}  style={{textDecoration:'none'}}>
                                              <Image
                             
                             src={cI.img}
                            
                             height="85px"
                             style={{objectFit:'contain',maxWidth:'85px',display:'flex',}}
                           />
                                                   </NavLink>          
                                                   </div>
                           
                          
                            
                      
                     
                   )
           })

                   :
                   <div className="ms-2 my-2" 
                   style={{textDecoration:'none',borderRadius:'100%',overflowY:'hidden',overflowX:'hidden',height:'85px',maxHeight:'85px',display:'flex',width:'85px',maxWidth:'85px',background:'#00000040'}}>
                  
                                                 
                                                 </div>

                   }
                   </div>
                
                   </div>

                   <div className="mx-auto" style={{maxWidth:'345px',width:'345px',maxHeight:'437px',height:'437px',display:'block',background:'#00000040',borderRadius:'20px'}}>
                   <p className="ParagraphP1Regular " style={{fontWeight:'700',fontSize:'24px',color:'white',marginTop:'18px',marginLeft:'29px'}}>Assets</p>
                   <div className="mx-auto" style={{display:'flex',maxWidth:'330px',maxHeight:'437px',overflowY:'scroll',overflowX:'hidden',flexWrap:'wrap',}}>
                   { UserMainNFTS ?
           UserMainNFTS.map((nft)=>{
                   return(
                       <div className="mx-auto my-2" 
style={{textDecoration:'none',borderRadius:'20px',overflowY:'hidden',overflowX:'hidden',height:'105px',maxHeight:'105px',background:'#00000040',display:'flex',width:'105px',maxWidth:'105px'}}>

                     
                       <Image
      
      src={nft.rawMetadata.image}
      width='105px'
      style={{height:'100%',maxHeight:'105px',maxWidth:'105px',display:'flex',}}
    />
                                  
                            </div>
                   )
           })

                   :
                   <div className="ms-2 my-2" 
                   style={{textDecoration:'none',
                   borderRadius:'100%',overflowY:'hidden',overflowX:'hidden',height:'85px',maxHeight:'85px',display:'flex',width:'85px',maxWidth:'85px',background:'#00000040'}}>
                  
                                                 
                                                 </div>

                   }
                   </div>
                   </div>


                   <div className="mx-auto" style={{maxWidth:'345px',width:'345px',maxHeight:'437px',height:'437px',display:'block',background:'#00000040',borderRadius:'20px'}}>
                   <p className="ParagraphP1Regular " style={{fontWeight:'700',fontSize:'24px',color:'white',marginTop:'18px',marginLeft:'29px'}}>Wallet</p>
              <p className="ParagraphP2Regular " style={{fontWeight:'400',maxWidth:'90%',fontSize:'14px',color:'white',marginTop:'18px',marginLeft:'29px'}}>
             ...
               </p>  
                   </div>
           </div>


   </div>
)
}
 
}
function getWindowSize() {
    const { innerWidth, innerHeight } = window;
  
    return { innerWidth, innerHeight };
  }


export default UserPage;