

import { useState, useEffect } from 'react';
import { Auth, Storage } from 'aws-amplify';
import { NavLink } from 'react-router-dom';
import Image from 'react-bootstrap/Image';
import { Network, Alchemy } from "alchemy-sdk";
import 'reactjs-popup/dist/index.css';
import { DataStore } from '@aws-amplify/datastore';
import '../styles/fonts/fonts.css'
import 'bootstrap/dist/css/bootstrap.css';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { User, ClubUser, NFTCollection } from '../models';
import { CDBCard, CDBCardBody } from 'cdbreact';
import { Divider } from '@aws-amplify/ui-react';

import { ConnectWallet, useAddress } from "@thirdweb-dev/react";
import SplashScreen from '../SplashScreen';

Storage.configure({ track: true, level: "public" });
const settings = {
  apiKey: "judyKIq12rtyO-zasR-253fFj13tXf6S",
    network: Network.MATIC_MAINNET,
};

const alchemy = new Alchemy(settings);


const MobileMyAcc =(props)=>{

   const [error, setError] = useState(null);
   const [loading, setLoading] = useState(true);
   const [username, setUsername] = useState('');
   const [userFullname, setUserFullname] = useState('');
   const [CurrentUser, setUser] = useState();
   const [wallet, setWallet] = useState(null);
   const [Userbio, setBio] = useState(null);
   const [UserImage, setImage] = useState(null);
   const [UserNFTS, getNFTS] = useState(null);
   const [nftCount, MaxNFT] = useState(null);
   const [UserBanner, setBannerImage] = useState(null);
   const [UserMainNFTS, getMainNFTS] = useState(null);
  const [admin, checkAdmin]= useState(false);
 const[walletConnected,walletConnectButton] = useState(false)
 const [windowSize, setWindowSize] = useState(getWindowSize());
 const [clubInfoList, getjoinedClubs]=useState([])
 const [clubTokenCount, getClubTokens]=useState([])
 const [allContracts, getAllContracts]=useState([])

 const walletAddress = useAddress()
 const tester = (w) => {
   //  const contractAddress = "0x818830D10979A21a7C061359AF7257C378E2a5E0";
   if(walletAddress){
     if(walletAddress != w ){
       w = walletAddress
       setWallet(w)
       newWallet()
     }
   }
   let tempToken = []

       

   alchemy.nft.getNftsForOwner(w).then((NftOwned)=>{
   
  
     let temp = NftOwned.ownedNfts.filter((n) => n.rawMetadata.image != null)
     temp = temp.filter((n) => n.rawMetadata.image.indexOf("chieve") >= 0  )
   
       temp= temp.filter((n) => n.contract.address.toLowerCase() == "0x4e682181ff8c6a3107add2abc108c9f19f68f3fa"  )
    console.log(temp)
     if(temp.length ==0 ){
     getMainNFTS(null)
     }else{
       if(clubInfoList){
         clubInfoList.filter((ci)=> ci.clubColls.length > 0).forEach((cil)=>{
           let tempTokenCount =cil.userTokens
      
           cil.clubColls.forEach((ctoken)=>{
             alchemy.nft
 .getOwnersForContract(ctoken.contractAddress,{
   withTokenBalances:true
 })
 .then((ow)=>{

       ow.owners.forEach((tempCount)=>{
         let tempClubList = [...clubInfoList]
        let tempChange = tempClubList.indexOf(cil)
        
         if(tempCount.ownerAddress === w.toLowerCase()){
           tempTokenCount = tempTokenCount + tempCount.tokenBalances.length
           tempClubList[tempChange].userTokens = tempTokenCount
           getjoinedClubs(tempClubList)
           tempToken.push({
             "clubName":tempClubList[tempChange].clubName,
             "clubID":tempClubList[tempChange].clubID,
             "userID":CurrentUser.username,
             "tokens":tempTokenCount 
           })
           getClubTokens(tempToken)
           
           newTokensCount()
         }
      
       })
   
 })
           })
         })
       }
       getMainNFTS(temp)
       newTokens()
   
      
     }
     
   })

 
 
 
 
   }
useEffect(()=>{

   if(walletAddress){
     tester(walletAddress)
   }
   try {
     setError(null);
     setLoading(true);
     console.log(props)
     setUser(props.props)
     Auth.currentAuthenticatedUser({
       bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
     }).then(user => {
       setUsername(user.attributes.preferred_username);
       setUserFullname(user.attributes.name);
       setUser(user)
       if (user) {
         DataStore.query(User, u => u.UserID("eq", user.username)).then((info)=>{
           if(info[0].isAdmin){
             checkAdmin(true)
           }
           
         }).catch((err)=> console.log(err))


         DataStore.query(ClubUser).then((info)=>{
           let tempClubs=[]
           let tempContracts = []
         info.filter((u)=> u.user.UserID== user.username).forEach((clubGet)=>{
           let cTokens = []
          if(clubGet.club.category == 'Gaming'){

          
           Storage.get("Clubs/"+clubGet.club.ClubStorageID+"/ClubLogo.png")
           .then((bannerLink)=>{
               let urlCLub = clubGet.club.name.replace(/ /g, '');
              
               DataStore.query(NFTCollection, cID => cID.clubID('eq',clubGet.club.id)).then((clubTokens)=>{
                
                 clubTokens.forEach((ct)=>{
                   if(ct.address){
                     cTokens.push({
                       "clubName":clubGet.club.name,
                       "clubID":clubGet.club.id,
                       "contractAddress":ct.address
                     })
                     tempContracts.push(ct.address)
                     getAllContracts(tempContracts)
                     
                   }
                 
                 })
                }).catch(err=>console.log(err))
              
               tempClubs.push({
                   "img":bannerLink,
                   "clubName":clubGet.club.name,
                   "clubURL":urlCLub,
                   "clubID":clubGet.club.id,
                   "clubColls":cTokens ,
                   "userTokens":0
               })
        
               getjoinedClubs(tempClubs)
             
           }).catch(err=>console.log(err))
         }
         })
           
         }).catch((err)=> console.log(err))


         DataStore.query(User, u => u.UserID("eq", user.username)).then((userWallet)=>{
           setWallet(userWallet[0].Wallet);
         tester(userWallet[0].Wallet) 
          
         })
         Storage.get("Users/"+user.username+"/Pro.png").then((r) => {
           if (r) {
             setImage(r)
          
            
           }
         });

         Storage.get("Users/"+user.username+"/Banner.png", {
           level: "public",
           
         }).then((r) => {
           if (r) {
             setBannerImage(r)

           }
         }).catch((error)=>console.log('error ', error));

       }
       if (user.attributes["custom:bio"]) {
         setBio(user.attributes["custom:bio"]);
       }
       // TBD
     }).catch(err => setError(err));


   }
   catch (e) {
     setError(e);
   }
   finally {
     setLoading(false);
   }
 
},[])


useEffect(() => {
   function handleWindowResize() {
       setWindowSize(getWindowSize());
      
     }
     
   window.addEventListener('resize', handleWindowResize);
   return () => {
       window.removeEventListener('resize', handleWindowResize);
     
     };
 }, []);
 const newWallet = async () => {

     try {
     
       setLoading(true)
       Auth.updateUserAttributes(CurrentUser, {
         'custom:wallet': walletAddress
       });
  
       const newWallet = await DataStore.query(User, u => u.UserID("eq", CurrentUser.username))
       await DataStore.save(
         User.copyOf(newWallet[0], updated => {
           updated.Wallet= walletAddress
         }));
         setWallet(walletAddress)
      
      
         
          setLoading(false)
        
          
     } catch (e) {
       setLoading(false)
     }
   
 }

 const newTokensCount = async () => {

   try {
   
     setLoading(true)
     

     const newCounter = await DataStore.query(User, u => u.UserID("eq", CurrentUser.username))
     await DataStore.save(
       User.copyOf(newCounter[0], updated => {
         updated.userTokens= clubInfoList
       }));
      
    
       
        setLoading(false)
      
        
   } catch (e) {
     
     setLoading(false)
   }
 
}
 const newTokens = async () => {

   try {
   
     setLoading(true)
     
 
     const newWallet = await DataStore.query(User, u => u.UserID("eq", CurrentUser.username))
     await DataStore.save(
       User.copyOf(newWallet[0], updated => {
         updated.WalletList= UserMainNFTS
       }));
      
    
       
        setLoading(false)
      
        
   } catch (e) {
     setLoading(false)
   }
 
}
 const styles={
   main:{
       height:"fit-content",
       minHeight:window.innerHeight,
       width:windowSize.innerWidth,
       maxWidth:windowSize.innerWidth,
       margin:'auto',
       display:'block'
   },
   header:{
       display:'flex',
       flexDirection:'row',
       maxWidth:'414px',
       overflow:'hidden',
       width:'100%',
       margin:'auto',
       marginTop:'20px',
       height:'fit-content',
      
   },
   bannerSection:{
       display:'block',
       margin:'32px 5px 16px 40px',
       
      overflow:'hidden',
      height:'100%',
       maxWidth:'800px',
       width:'800px'
   },
   walletSection:{
       margin:'32px 40px 16px 20px',
       
       display:'block',
    
       overflow:'hidden',
       width:'440px',
       height:'384px',
       background:'linear-gradient(131deg, #aeffff 0%, #72b3ff 100%)',
       borderRadius:'12px',
       display:'block',
       overflowY:'scroll'
   },
   bio:{
       display:'block',
       marginBottom:'20px',
       maxWidth:'960px',
       margin:'12px 5px 16px 40px',

   },
   content:{
       display:'flex',
       flexDirection:'row',
       maxWidth:'1320px',
     
       width:'100%',
       margin:'auto',
       marginTop:'20px',
       padding:'10px',
       height:'600px',
       marginBottom:'20px'
   },
   clubList:{
       display:'block',
       width:'300px',
       maxWidth:'200px',
       margin:'auto',
       height:'600px',
       
  
   },

   collectionList:{
       display:'block',
       width:'80%',
       maxWidth:'80%',
       margin:'auto',
       height:'600px',
       overflowY:'scroll',
   }
 }



 if(loading){
   return(
    <SplashScreen />
   )
 }
if(!loading){
 if(walletAddress){
   if(walletAddress != wallet){
     tester(wallet)
   }
 }
 
   return(
       <div style={styles.main}>
           <div classsName="mx-auto"style={{width:window.innerWidth,maxHeight:'84px',height:'84px',overflowY:'hidden',overflowX:'hidden',display:'flex'}}>
           <Image className="rounded  mx-auto" src={UserBanner} width="100%" 
            style={{maxWidth:window.innerWidth,margin:'auto' }}/>
           </div>
             <div className="mx-auto" style={{display:'block',width:'100%'}}>
             <Image src={UserImage} className="mx-auto"style={{height:'120px',width:'120px',borderRadius:'100%',border:'0px',marginTop:'-2rem',display:'flex'}}/>
             <div className="mx-auto mt-4 " style={{display:'block',width:'340px',maxWidth:'340px',}}>

           
               <p className="HeadingH5Semibold mx-auto" style={{color:'white',maxWidth:'103px',fontSize:'16px',fontWeight:'700',lineHeight:'24px'}}>
               @{username}
                   </p>

           <div className="mx-auto my-2"style={{display:'flex',width:'200px',maxHeight:'70px',}}>
                   <div className="mx-auto mt-2"style={{display:'block',width:'50px',color:'white'}}>
                               <p className="mx-auto" style={{width:'49px',fontSize:'14px',textAlign:'center'}}>Earned: <br/> ${CurrentUser.earned ? CurrentUser.earned : 0}</p>
                             
                   </div>
                   <Divider className="mx-2 my-auto" style={{height:'50px'}} border="1px solid white"
   orientation="vertical" />
                   <div className="mx-auto mt-2"style={{display:'block',width:'50px',height:'30px',color:'white'}}>
                               <p className="mx-auto" style={{width:'49px',fontSize:'14px',textAlign:'center'}}>Staked: <br/> $0</p>
                            
                   </div>
                   <Divider className="mx-2 my-auto" style={{height:'50px'}} border="1px solid white"
   orientation="vertical" />
                   <div className="mx-auto mt-2"style={{display:'block',width:'50px',color:'white',height:'30px'}}>
                               <p className="mx-auto" style={{width:'49px',fontSize:'14px',textAlign:'center'}}>Points: <br/> {CurrentUser.wins ? CurrentUser.wins : 0}</p>
                   </div>
           </div>
           
                   <p className="ParagraphP2Regular mx-auto" style={{color:'white',fontWeight:'bold',fontSize:'12px',width:'90%',textAlign:'center'}}>Bio</p>
          <p className="ParagraphP2Regular mx-auto" style={{color:'white',width:'90%',textAlign:'center',fontSize:'14px'}}>
         {Userbio}
           </p>
</div>

             </div>
 


               <div className="mx-auto my-4" style={{maxWidth:'345px',display:'block'}}>

                       <div className="mx-auto" style={{maxWidth:'345px',overflowY:'auto',overflowX:'hidden',width:'345px',maxHeight:'437px',height:'437px',display:'block',background:'#00000040',borderRadius:'20px'}}>
                               
                               <p className="ParagraphP1Regular mt-4" style={{fontWeight:'700',fontSize:'24px',color:'white',marginTop:'18px',marginLeft:'29px'}}>Clubs</p>
                       <div className="mx-auto" style={{display:'flex',maxWidth:'330px',maxHeight:'437px',overflowY:'auto',overflowX:'hidden',flexWrap:'wrap',}}>
                       { clubInfoList.length != 0 ?
               clubInfoList.map((cI)=>{
                       return(
                         
                             <div className="mx-auto my-2" 
  style={{textDecoration:'none',borderRadius:'100%',overflowY:'hidden',overflowX:'hidden',height:'85px',maxHeight:'85px',display:'flex',width:'85px',maxWidth:'85px',background:'#00000040',}}>
 
                           <NavLink activeClassName="active"  to={"/"+cI.clubURL}  style={{textDecoration:'none'}}>
                           <Image
          
          src={cI.img}
         
          height="85px"
          style={{objectFit:'contain',maxWidth:'85px',display:'flex',}}
        />
                                </NavLink>          
                                </div>
                               
                              
                                
                          
                         
                       )
               })

                       :
                       <div className="ms-2 my-2" 
                       style={{textDecoration:'none',borderRadius:'100%',overflowY:'hidden',overflowX:'hidden',height:'85px',maxHeight:'85px',display:'flex',width:'85px',maxWidth:'85px',background:'#00000040'}}>
                      
                                                     
                                                     </div>

                       }
                       </div>
                    
                       </div>

                       <div className="mx-auto" style={{maxWidth:'345px',width:'345px',maxHeight:'437px',height:'437px',display:'block',background:'#00000040',borderRadius:'20px'}}>
                       <p className="ParagraphP1Regular mt-4" style={{fontWeight:'700',fontSize:'24px',color:'white',marginTop:'18px',marginLeft:'29px'}}>Assets</p>
                       <div className="mx-auto" style={{display:'flex',maxWidth:'330px',maxHeight:'437px',overflowY:'scroll',overflowX:'hidden',flexWrap:'wrap',}}>
                       { UserMainNFTS  ?
               UserMainNFTS.map((nft)=>{
                       return(
                           <div className="mx-auto my-2" 
  style={{textDecoration:'none',borderRadius:'20px',overflowY:'hidden',overflowX:'hidden',height:'105px',maxHeight:'105px',display:'flex',width:'105px',maxWidth:'105px'}}>
 
                         
                           <Image
          
          src={nft.rawMetadata.image}
          width='105px'
          style={{height:'100%',maxHeight:'105px',maxWidth:'105px',display:'flex',}}
        />
                                      
                                </div>
                       )
               })

                       :
                       <div className="ms-2 my-2" 
                       style={{textDecoration:'none',borderRadius:'100%',overflowY:'hidden',overflowX:'hidden',height:'85px',maxHeight:'85px',display:'flex',width:'85px',maxWidth:'85px',background:'#00000040'}}>
                      
                                                     
                                                     </div>

                       }
                       </div>
                       </div>


                       <div className="mx-auto" style={{maxWidth:'345px',width:'345px',maxHeight:'437px',height:'437px',display:'block',background:'#00000040',borderRadius:'20px'}}>
                       <p className="ParagraphP1Regular mt-4" style={{fontWeight:'700',fontSize:'24px',color:'white',marginLeft:'29px'}}>Wallet</p>
                       {!loading ?
                   <ConnectWallet theme="dark"
                   btnTitle="Connect Wallet" className='mx-auto w-50'  style={{maxWidth:'200px',display:'flex',margin:'auto'}} />
                        :
                        <ConnectWallet theme="dark"
                        btnTitle="Connect Wallet" className='mx-auto w-75'  style={{maxWidth:'200px',display:'flex',margin:'auto'}} />
                       }


                     
                       </div>
               </div>


       </div>
   )
}
 
}
function getWindowSize() {
    const { innerWidth, innerHeight } = window;
  
    return { innerWidth, innerHeight };
  }


export default MobileMyAcc;