import { useState } from 'react';
import awsconfig from '../aws-exports';
import '../styles/setUp.css';
import { Amplify, Auth, Storage } from "aws-amplify";
import Image from 'react-bootstrap/Image';
import { useNavigate, NavLink } from 'react-router-dom';
import { DataStore } from '@aws-amplify/datastore';
import { User } from '../models';
import { CDBBtn } from 'cdbreact';
import PasswordChecklist from "react-password-checklist"
import { AiOutlineCloseCircle, AiOutlineCheckCircle } from 'react-icons/ai';
import BlueButton from './components/BlueButton';
import AvatarGenerator from './components/AvatarGenerator';

Amplify.configure(awsconfig);

export default function Login() {
  const [Olddetails,returnSignIn] = useState({email: '', password: '',verificationCode: '',confirmEmail:'',newCode:'',confirmNewPassword:'',NewPassword:''})
  const [formState, setFormState] = useState("LogIn");

  const [mouseHover,setHover] = useState(false)
  const navigate = useNavigate();
  const setColor = ()=>{
    setHover(true)
  }
  const fixColor = ()=>{
    setHover(false)
  }
function onChange2(e) {
 
    returnSignIn({...Olddetails, [e.target.name]: e.target.value })
      
    }
     function confirmSignUp() {

      var d = new Date();
        Auth.confirmSignUp(Olddetails.email, Olddetails.verificationCode).then((res)=>{
          
          alert("Email Confirmed!")
          Auth.signIn(Olddetails.email, Olddetails.password).then(async (newUserAdded)=>{
            console.log(newUserAdded)
            await DataStore.save(
              new User({
           
              "name": newUserAdded.attributes.name,
      "preferred_username": newUserAdded.attributes.preferred_username,
      "UserID":newUserAdded.username,
      "userEmail":newUserAdded.attributes.email,
      "bio":' ' ,
      "earned":0,
      "wins":0,
      "staked":0,
      "isAdmin":false,
      "Wallet":" ",
      "lastLogin":d.toLocaleDateString() +"-"+d.getHours()+":"+ d.getMinutes() 

             
            }));
            Storage.get("tempBanner.png", {
      
              download:true
          }).then((tempBanner)=>{
            console.log(tempBanner)
            Storage.put("Users/"+newUserAdded.username+"/Banner.png", tempBanner.Body, {
              
              contentType: tempBanner.Body.type
            })
              .then((result) =>{
                
                  AvatarGenerator(newUserAdded.username)
                  setFormState("Complete");
              })
              .catch(err => console.log(err));
          }).catch((err)=>{console.log(err)})
         

          })
      
         
        
     
        }).catch((err)=>{

          alert(err.message );
       
          setFormState("AccountConfirm");
        })
        
        
        
    
    }
    function forgotPass(){
      setFormState("resendEmail")
    }
    function senEmailCode(){
      Auth.forgotPassword(Olddetails.confirmEmail)
      .then(data => {
        alert("Please Check your email for your reset code")
        setFormState("forgotPass")
      })
      .catch(err => console.log(err));
    }
    function resetPass(){
      if(Olddetails.NewPassword == Olddetails.confirmNewPassword){
        Auth.forgotPasswordSubmit(Olddetails.confirmEmail, Olddetails.newCode,Olddetails.NewPassword)
        .then(data => {
          alert("Password Reset")
          setFormState("LogIn")
        })
        .catch(err => alert(err));
      }else{
        alert("Passwords do not match")
      }
     
    }
async function OldSignInForm() {
    try {
      const LoggedUser = await Auth.signIn(Olddetails.email, Olddetails.password);
      
                    console.log(LoggedUser)
         
      setFormState("Complete");
    } catch (err) { 
      if(err.message.indexOf("confirm") != -1){
        alert( err.message +"Please check your email to verify the account! Do NOT CLOSE THIS PAGE!") 
 
        Auth.resendSignUp(Olddetails.email).then((x)=>{
          setFormState("AccountConfirm");
        }).catch((err)=>{console.log(err)})
      }else{
        alert( err.message) 
      }
    
    }
  }
  if(formState === "Complete"){
        navigate("/")
  }

  if(formState === "AccountConfirm"){

    return(
      <div className="my-auto" style={{height:'750px',width:'100%',}}> 
      <div  className="modal-area-confirm mx-auto my-5 "  
      style={{background:'#00000040',height:'439px',width:'422px'}}>
     
  <div className="modal-body-confirm my-auto">
  
  
  <div style={{width:'90%', margin:'auto',justifyContent:'space-between',paddingBottom:'1%',}}>
  
  <h2 style={{color:'white', fontFamily:'Poppins-Regular'}}>Confirm Email</h2>
  <p style={{fontFamily: 'Poppins-Regular',color: 'white', 
   fontWeight: '400', fontSize: '16px',display: 'block', }}>
    Please fill in the code that was sent to your email. <br/>
    <strong>Check Spam folder if not seen in main inbox.</strong><br />
    <strong>PLEASE DO NOT CLOSE THIS TAB </strong>
    </p>
  
  <div className="form-group mx-auto" style={{display: 'flex',width:'60%',marginTop:'2%', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', padding: '0px', gap: '8px',}}>
    <label htmlFor="email">Verification Code</label>
    <input
  name="verificationCode"
      className="form-control inputs "
      onChange={onChange2}
      placeholder=""
    />
    
  </div>
  
  <div className="form-group mx-auto" style={{display: 'flex',width:'60%', flexDirection: 'column', 
  justifyContent: 'center',  padding: '0px', gap: '8px',}}>
    <button className="form-control  subMitButton my-auto mx-auto" 
     style={{background:'#00D4C5', border:'0px',color:'white',borderRadius:'100px',width:'156px',height:'45px'}}   onClick={confirmSignUp}>
      Submit
    </button>
  </div>
  </div>
  
  
  </div>
  </div>
  </div>
    )
  }
  if(formState === "resendEmail"){
    function cancelReset(){
      setFormState("LogIn")
    }
    return(
      <div style={{display:'block',margin:'auto',  position: 'relative',height:window.innerHeight,width:window.innerWidth*.86,padding:'8px',}}> 
      <div  className="modal-area" style={{background:'#00000040',height:'439px',width:'422px'}}>
            <NavLink  to="/"   style={{textDecoration:'none'}}>
  
         <button
           
              aria-label="Close Modal"
              aria-labelledby="close-modal"
              className="_modal-close"
            
            >
              <span id="close-modal" className="_hide-visual">
                Close
              </span>
              <svg className="_modal-close-icon" viewBox="0 0 40 40">
                <path d="M 10,10 L 30,30 M 30,10 L 10,30" />
              </svg>
            </button>
            </NavLink>
     <div className="modal-body" >
  
     
      <div style={{width:'100%', justifyContent:'space-between',marginTop:'1px',}}>
    
        <h2 style={{color:'white', fontFamily:'Poppins-Regular'}}>  Enter your email</h2>
       
       
  
  
  
  
  
        <div className="form-group" style={{display: 'flex',width:'263px', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', padding: '0px', gap: '8px',}}>
          <label >Email address</label>
          <input
        name="confirmEmail"
        type="email"
            className="form-control"
            style={{borderBottom:'1px solid #00D4C5',borderTop:'0px',borderRight:'0px',borderLeft:'0px',borderRadius:'0px',background:'transparent'}}

            onChange={onChange2}
            placeholder="Example@example.com"
            autoComplete="off"
          />
          
        </div>
  
    
    <CDBBtn className="mx-auto my-2" onClick={()=>{cancelReset()}} style={{background:'transparent',border:'0px'}} >
cancel
    </CDBBtn>
    
  <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
    
          <button className="form-group mx-auto" onMouseEnter={setColor} onMouseLeave={fixColor} onClick={()=>{senEmailCode()}} 
       style={{background:'#00D4C5', border:'0px',borderRadius:'100px',width:'156px',height:'45px',color:'white',fontWeight:'bold'}}>
          
              Submit
          
          </button>
     
       
  </div>
       
      </div>
     
    
      </div>
      </div>
      </div>
    )
  }
  if(formState === "forgotPass"){
    return(
      <div style={{display:'block',margin:'auto',  position: 'relative',height:window.innerHeight,width:window.innerWidth*.86,padding:'8px',}}> 
      <div  className="modal-area" style={{margin:'auto'}}>
            <NavLink  to="/"   style={{textDecoration:'none'}}>
  
         <button
           
              aria-label="Close Modal"
              aria-labelledby="close-modal"
              className="_modal-close"
            
            >
              <span id="close-modal" className="_hide-visual">
                Close
              </span>
              <svg className="_modal-close-icon" viewBox="0 0 40 40">
                <path d="M 10,10 L 30,30 M 30,10 L 10,30" />
              </svg>
            </button>
            </NavLink>
     <div className="modal-body" >
  
     
      <div style={{width:'50%', justifyContent:'space-between',marginTop:'1px',paddingBottom:'1%'}}>
      <p style={{fontFamily: 'Poppins-Regular',color: '#1226AA',  fontWeight: '600', fontSize: '18px',display: 'flex', alignItems: 'center', textAlign: 'center'}}>
        Enter New Password
        </p>
        <h2 style={{color:'white', fontFamily:'Poppins-Regular'}}>Reset Password</h2>
       
       
  
  
  
  
        <div className="form-group" style={{display: 'flex',width:'60%', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', padding: '0px', gap: '8px',}}>
          <label >Code</label>
          <input
            type="text"
            className="form-control inputs"
            name="newCode"
            placeholder="code"
            onChange={onChange2}
            autoComplete="off"
          />
        </div>
  
  
  
        <div className="form-group" style={{display: 'flex',width:'60%', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', padding: '0px', gap: '8px',}}>
          <label htmlFor="Password">Password</label>
          <input
            type="password"
            className="form-control inputs"
            name="NewPassword"
            placeholder="password"
            onChange={onChange2}
            autoComplete="off"
          />
        </div>
        <div className="form-group" style={{display: 'flex',width:'60%', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', padding: '0px', gap: '8px',}}>
          <label htmlFor="Password">Confirm Password</label>
          <input
            type="password"
            className="form-control inputs"
            name="confirmNewPassword"
            placeholder="confirm password"
            autoComplete="off"
            onChange={onChange2}
          />
        </div>
  
      <PasswordChecklist
          rules={["minLength","number","capital","match"]}
          minLength={8}
          value={Olddetails.NewPassword}
          valueAgain={Olddetails.confirmNewPassword}
          onChange={(isValid) => {}}
          style={{color:'rgb(255,255,255)',marginTop:'15px', width:'75%',textAlign:'left', flexWrap:'noWrap',display:'block',padding:'5px',}}
          iconSize={15}
          iconComponents={{ValidIcon: <AiOutlineCheckCircle style={{margin:'auto',color:'green',width:'1.5em',height:'24px'}}/>, 
          InvalidIcon: <AiOutlineCloseCircle style={{margin:'auto',color:'red', width:'1.5em',height:'24px'}} />}}
        />
        
  <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
    
          <button className="form-group" onMouseEnter={setColor} onMouseLeave={fixColor} style={{display: 'flex',width:'50%', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', padding: '0px', gap: '8px',}} onClick={()=>{resetPass()}}>
            <button className="form-control btn subMitButton" style={{background:mouseHover? 'rgba(137, 207, 240,.8)':'rgb(137, 207, 240)'}} >
              Submit
            </button>
          </button>
     
       
  </div>
       
      </div>
     
      <div className="formContainer">
      <Image  width="100%" src={require("../assets/images/mainLogo2.png")} />
      
      <Image  width="100%" src={require("../assets/images/signUpBackGround.png")} />
      </div>
      </div>
      </div>
      </div>
    )
  }

if(formState === "LogIn"){
 
    return (
   
      <div style={{height:window.innerHeight*.9,}}>

      
      <div  className="modal-area" style={{background:'#00000040',height:'439px',width:'422px'}} >
            <NavLink  to="/"   style={{textDecoration:'none'}}>
  
         <button
           
              aria-label="Close Modal"
              aria-labelledby="close-modal"
              className="_modal-close"
            
            >
              <span id="close-modal" className="_hide-visual">
                Close
              </span>
              <svg className="_modal-close-icon" viewBox="0 0 40 40">
                <path d="M 10,10 L 30,30 M 30,10 L 10,30" />
              </svg>
            </button>
            </NavLink>
     <div className="modal-body">
  
     
      <div className="mt-2" style={{width:'100%', justifyContent:'space-between',}} >
   
        <p style={{color:'white', fontFamily:'Poppins-Regular',fontSize:'24px',lineHeight:'36px',margin:'auto',fontWeight:'700',width:'263px'}}>Welcome Back!</p>
        <p style={{fontFamily: 'Poppins-Regular',color: 'white',  fontWeight: '400', fontSize: '14px',margin:'auto', width:'263px'}}>
        Please fill in your details to access your account.
          </p>
       
  
  
  
  
  
        <div className="form-group mx-auto" style={{display: 'flex',width:'263px', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', padding: '0px', gap: '8px',}}>
    
          <form autoComplete="off" style={{width:'100%'}}>
         
          <input
        name="email"
        type="email"
            className="form-control"
            style={{borderBottom:'1px solid #00D4C5',borderTop:'0px',borderRight:'0px',borderLeft:'0px',borderRadius:'0px',background:'transparent',color:'white'}}
            onChange={onChange2}
            placeholder="Email"
            autoComplete="new-password"
            
          />
</form>
         
          
        </div>
  
  
  
        <div className="form-group mx-auto" style={{display: 'flex',width:'263px', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', padding: '0px', gap: '8px',}}>
          
          <input
            type="password"
            className="form-control "
            style={{borderBottom:'1px solid #00D4C5',borderTop:'0px',borderRight:'0px',borderLeft:'0px',borderRadius:'0px',background:'transparent',color:'white'}}

            name="password"
            placeholder="password"
            
            onChange={onChange2}
          />
        </div>
  
        <button onClick={()=>{forgotPass()}} style={{background:'transparent',textAlign:'center',color:'white',border:'0px',width:'200px',fontSize:'12px',fontWeight:'400'}} 
        className="mb-2 mx-auto">
      Forgot password?
      </button>
         
      
    <BlueButton  onClick={OldSignInForm}  className="mx-auto">
    Submit
    </BlueButton>
       
      

      <div className="mx-auto mt-5" style={{display:'block',width:'300px'}}>
      <p className="mx-auto"style={{color:'white',fontSize:'12px',display:'flex',width:'150px'}}>Dont have an account?</p>
      <button onClick={()=>{navigate("/SignUp")}} style={{background:'transparent',display:'flex',textAlign:'center',color:'white',border:'0px',width:'200px',fontSize:'14px',fontWeight:'400'}} 
        className="mx-auto">
     <p className="mx-auto" style={{color:'#00D4C5',display:'flex',fontWeight:'700'}}>Create account </p> 
      </button>
      </div>
      
      </div>
     
  
      </div>
      </div>
      </div>
    )
  
}

}