import { ConsoleLogger } from '@aws-amplify/core';
import React, { Component, useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate, Redirect, Link, Navigate,NavLink } from 'react-router-dom';

import '../assets/fonts/Poppins-Regular.ttf'


export default function PrivacyPolicy(props){
console.log(props)
const [windowSize,setWindowSize] = useState(props.screenSize);
const[maxWide,setmax] = useState()
useEffect(()=>{
  setWindowSize(props.screenSize)
  if(windowSize.innerWidth < 440){
    setmax('414px')
  }else{
    setmax('1440px')
  }
},[])
  const styles={
    main:{
      maxWidth:maxWide,
      width:windowSize.innerWidth,
     
      margin:'auto',
      display:'block',
      padding:'10px',
  
    },
    body:{
      width:'100%',
      margin:'auto',
     
      padding:'10px',
      height:'90%'
    },
    header:{
      width:'100%',
      height:'150px',
      fontFamily:'Poppins-Regular',
      padding:'40px',
      margin:'auto',

      display:'flex',
      textAlign:'center',
      background:'transparent'
    },
    terms:{
      width:'90%',
     
      
      padding:'20px',
      margin:'auto',
      textAlign:'center',
     
    },
    sections:{
      width:'90%',
      fontWeight:'bold',
      textAlign:'left',
      color:'white',
      fontFamily:'Poppins-Regular',
      marginTop:'10px',
      marginBottom:'10px'
    },
    para:{
      width:'80%',
      fontSize:'17px',
      fontFamily:'Poppins-Regular',
      fontWeight:'500',
      textAlign:'left',
      color:'white',
      flexWrap:'wrap',
      
    }
  }
    return(
      <div style={styles.main}>
        <div style={styles.body}>
          <div  className='mx-auto w-100' style={styles.header}>
          <h1 className='mx-auto' style={{color:'white',bottom:'0'}}>Privacy Policy</h1>
          </div>
      <div style={styles.terms}>
      <h3 style={styles.sections}>
Symbiotic Wealth Privacy and Cookie Policy</h3>
       <br />
<p style={styles.para}>
        To provide Symbiotic Wealth (“SW”) products and services, including the Chieve website the
Chieve web application, (the “Services”) we collect information from and about you in
connection with your use of the Services. At this time, our services are only available in the
United States. You must be 18 years of age to use our Services.

        </p>
        <h3 style={styles.sections}>What do we collect?</h3>
       <br />
<p style={styles.para}>
        Information and Content You and Others Provide. We collect content and information you
provide while using our Services, including when you create or register an account, enter
contests, giveaways or competitions, attend or participate in one of our events, make
purchases, post and communicate with others. Such information may include your name,
email address, age, gender telephone number, wallet information, certain payment and identity
verification information, and other information you may provide to us or share while using our
Services. Sometimes, we may collect information you may provide about others as well.
<br />
<br />
Information Collected Automatically. We may collect certain types of information
automatically when you interact with our Services. This may include online identifiers, device
information, information associated with your network and information about your use of our
Services and your preferences as well as geolocation information. Such information may be
automatically collected through device-based tracking technologies such as cookies, pixels,
tags, beacons, scripts, or other technology. For more information about cookies or other
tracking technologies and the choices you have regarding the use of them, please refer to our
Cookie policy below.
<br />
<br />
Information From Our Partners. We may receive information about you and your activities on
and off our Services our third-party partners. This may include sponsors and services or events
where we may offer with others.

     </p>
        <h3 style={styles.sections}>How do we use your Information?</h3>
       <br />
<p style={styles.para}>
        Provide and improve our Services. We may use your information to create and manage your
profiles and registrations, complete transactions or otherwise allow you to interact with our
Services. We may also use your information to improve the operation and delivery of our
Services or to maintain the safety and security of such Services.
<br />
<br />
Marketing purposes. We may use your information to communicate with you and send
promotional messages about our offers, products or third-party offers or products we think you
might find interesting.
<br />
<br />
Advertising. We may use your information to provide you with ads or information that we
think would be of interest to you.

     </p>
     
        <h3 style={styles.sections}>How is the Information Shared?</h3>
       <br />
<p style={styles.para}>
        Legal, Affiliates and corporate transactions. We may share information we collect about you
with our affiliated companies or in the context of sale, merger or other similar transactions. We
may also share your information if obligated by law.
<br />
<br />
Our Service Providers. We may share your information with our commercial partners or other
individuals who provide services to us.
<br />
<br />
Business partners. When permitted, we may share your information with our trusted business
partners including our marketing and advertising partners and our sponsors.

     </p>
        <h3 style={styles.sections}>Managing Preferences</h3>
       <br />
<p style={styles.para}>
        Accessing, Updating, Correcting, and Deleting your Personal Information. Certain jurisdictions
provide you with certain rights with respect your personal information. You can access
personal information we may have collected about you, request deletion of your personal
information or review or correct such information by sending a request to 
{' '}<a href="mailto:privacy@chieve.xyz">
privacy@chieve.xyz.
  </a>{' '}
You can also request to opt out of our sharing with others.
<br />
<br />
Tracking Technologies. Regular cookies may generally be disabled or removed by tools
available as part of most commercial browsers, and in some instances blocked in the future by
selecting certain settings. For more information, please see the section entitled “Cookies
Policy” below.  
<br />
<br />
Google Analytics. You may exercise choices regarding the use of cookies from Google Analytics
by going to {' '}<a href="https://tools.google.com/dlpage/gaoptout" target="_blank" rel="noopener noreferrer" >
https://tools.google.com/dlpage
</a>{' '}and downloading the Google Analytics
Opt-out Browser Add-on.
<br />
<br />
Ad Choices. We, our affiliates, and third-party partners may collect information on our Services
and on third-party websites to help serve advertising that may be more relevant to you. For
more information on how to opt-out of receiving interest-based advertising on desktop and
mobile websites, please visit Digital Advertising Alliance 
{' '}<a href="https://www.aboutads.info/choices/" target="_blank" rel="noopener noreferrer">
https://www.aboutads.info/choices/.
</a>{' '}
     </p>
        <h3 style={styles.sections}>Cookie Policy</h3>
       <br />
<p style={styles.para}>
        What are cookies? A cookie (“Cookie”) is a small text file that is placed on your hard drive by a
web page server. Cookies contain information that can later be read by a web server in the
domain that issued the cookie to you. Some cookies are essential to the functionalities of our
Services while others are used to better understand your use of our Services and serve you with
more personalized experience.
<br />
<br />
How do we use Cookies? We and our partners use cookies and other similar technologies to
recognize you and help us better understand your behavior and preference while using our
Services. We may also use cookies and similar tracking technologies to customize our Services
to you, including provide recommendations and serve you with targeted advertising.
<br />
<br />
Managing Cookies Preference. You can generally activate or later deactivate the use of cookies
through a functionality built into your web browsers. To learn more about how to control
cookie settings through your browser please visit
{' '}<a href="http://www.aboutcookies.org" target="_blank" rel="noopener noreferrer">
http://www.aboutcookies.org
</a>{' '}for detailed

     </p>
      

     <h3 style={styles.sections}>Notice to Californian Residents</h3>
       <br />
<p style={styles.para}>
        The sections “What do we collect”; “How do we use your information”; and “How do we share
your information” describe the category and type of personal information we process. If you
are a California resident, you have specific privacy rights governed by the California Consumer
Privacy Act (CCPA). This includes the right to know, the right to opt out of the sale of your
personal information, the right to deletion and the right to non-discrimination. If you wish to
exercise your rights under the CCPA, please send your request at {' '}<a href="mailto:privacy@chieve.xyz">
privacy@chieve.xyz.
  </a>{' '}

     </p>
        <h3 style={styles.sections}>International Transfers</h3>
       <br />
<p style={styles.para}>
        We Transfer Personal Information across Country Borders in Accordance with Applicable Law.
     </p>
        <h3 style={styles.sections}>Reasonable security measures</h3>
       <br />
<p style={styles.para}>
        We aim to protect your information at all times, and we have adopted commercially reasonable
technical, administrative and physical security procedures. Please however note that no data
transmission or storage can be guaranteed to be 100% secure and we cannot ensure or warrant
to the security of any information that you provide to us.

     </p>
        <h3 style={styles.sections}>Agreement to Data Practice & Changes to this Policy</h3>
       <br />
<p style={styles.para}>
        By using our Services, you acknowledge and you agree to the data practices defined in this
policy. We may change this Privacy Policy and will provide notice to you if these changes are
material

     </p>

     <h3 style={styles.sections}>Contact Us</h3>
       <br />
<p style={styles.para}>
        If you have any questions about this Privacy Policy, you may contact us through by emailing us
at {' '}<a href="mailto:privacy@chieve.xyz">
privacy@chieve.xyz.
  </a>{' '}
  <br />
<br />
<p style={{...styles.para,fontWeight:'bolder',fontSize:'18px'}}>
The explanations and information provided herein are only general explanations, 
information and samples. You should not rely on this article as legal advice or as 
recommendations regarding what you should actually do. We recommend that you seek legal advice to help you 
understand and to assist you in the creation of your privacy policy.
</p>
     </p>
      </div>

        </div>
      </div>

    )

}