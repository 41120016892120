import React, { useState, useEffect } from 'react';

import '../styles/setUp.css';
import Image from 'react-bootstrap/Image';
import awsExports from '../aws-exports';
import { DataStore } from '@aws-amplify/datastore';
import { Amplify, Auth,API, Hub ,Logger,Storage } from "aws-amplify";
import { BrowserRouter as Router, Route, Routes, useNavigate, Redirect, Link, Navigate,NavLink } from 'react-router-dom';
import { User,Club,ClubUser } from '../models';
Storage.configure({ track: true, level: "public" });
Amplify.configure(awsExports);



const MobileAccountSetUp = (props) =>{
 
  const [formState, setFormState] = useState("SetUpBanner");
    const [CurrentUser, setUser] = useState();
    const [Newusername, setUsername] = useState('');
    const [wallet, setWallet] =useState(null);
    const [Userbio, setBio] =useState(null);
    const [UserImage, setImage] =useState(null);
    const [UserBanner, setBanner] =useState(null);
   
    const [GetUserImage, getImage] =useState(null);
    const[loading,setLoading] = useState("false");
    const[loading2,setLoading2] = useState("false");
    useEffect(() => {
      try {
     
  
        Auth.currentAuthenticatedUser({
          bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        }).then(user => {
          setUsername(user.attributes.preferred_username);
          setUser(user)
          if(wallet !== null){
          if(user.attributes["custom:wallet"]){
            setWallet(user.attributes["custom:wallet"]);

          }
        }
    
        if(formState ==="SetUpUserPic"){
          Storage.get("Users/"+user.username+"/Banner.png",{
                 level:'public'
             }).then((r) =>{
               if(r){
                 setBanner(r)
               }
             })}
             if(formState==="createBio"){
                 Storage.get("Users/"+user.username+"/Pro.png",{
                 
                 level:'public'
             
             }).then((r) =>{
               if(r){
                 setImage(r)
               }
             })}
        
        }).catch(err => console.log(err));
      }
      catch (e) {
        console.log(e);
      }
     
      
    }, []);
    function onChange(e) {
      
        setBio(e.target.value )
        console.log("submit ",Userbio)
      }
    

     
    let fileInput = React.createRef();
    let fileInput2 = React.createRef();
const onOpenFileDialog = () => {
  fileInput.current.click();
};
const onOpenFileDialog2 = () => {
    fileInput2.current.click();
  };

const onProcessFile2 = e2 => {
    e2.preventDefault();
    let reader = new FileReader();
    let file = e2.target.files[0];
    try {
      reader.readAsDataURL(file);
    } catch (err) {
      console.log(err);
    }
    reader.onloadend = () => {
      
      setBanner(e2.target.files[0]);
      Storage.put("Users/"+CurrentUser.username+"/Banner.png", file, {
        
        contentType: e2.target.files[0].type
      })
     
        .then(result =>ChangeFormState("SetUpUserPic"))
        .catch(err => console.log(err));
       
    };
   
  };
const ChangeFormState=async (status)=>{
   
    if(status ==="SetUpUserPic"){
      const ban = await Storage.get("Users/"+CurrentUser.username+"/Banner.png",{
            level:'public'
        })
         
          setBanner(ban)
            setFormState(status)
          
       }
        if(status==="createBio"){
        const img =    await   Storage.get("Users/"+CurrentUser.username+"/Pro.png",{
            
            level:'public'
        
        })
        setImage(img)
            setFormState(status)
        }
}

    const onProcessFile = e => {
      e.preventDefault();
      let reader = new FileReader();
      let file = e.target.files[0];
      try {
        reader.readAsDataURL(file);
      } catch (err) {
        console.log(err);
      }
      reader.onloadend = () => {
      
        setImage(e.target.files[0]);
        Storage.put("Users/"+CurrentUser.username+"/Pro.png", file, {
      
            contentType: e.target.files[0].type
          })
            .then(result =>ChangeFormState("createBio"))
            .catch(err => console.log(err));
            
      };
      
        
    };
      
       const getWallet =  async() =>{
        const {ethereum} = window;
    if(!ethereum){
      alert("Please install MetaMask")
    }else{
      try{
      const accounts =  await ethereum.request({method:'eth_requestAccounts'});
        console.log(accounts)
       Auth.updateUserAttributes(CurrentUser, {
          'custom:wallet': accounts[0]
        });
        const c = await DataStore.query(User, u => u.UserID("eq",props.username));
       
        await DataStore.save(
          User.copyOf(c, updated => {
            updated.Wallet= accounts[0]
          })
        );
      }catch(e){

      }
    }
      }   
       const updateUser = async() =>{
        if(UserImage && UserBanner){
          if(Userbio){
            const c = await DataStore.query(User, u => u.UserID("eq",CurrentUser.username));
            console.log(Userbio)
          Auth.updateUserAttributes(CurrentUser, {
            'custom:bio': Userbio
          }

         
          );
          await DataStore.save(
            User.copyOf(c[0], updated => {
              updated.bio= Userbio.toString()
            })
          );
          setFormState("DoneSettingUp")
          }else{
            alert('Please Fill in Bio')
          }
          
    }else{
      alert('Please Upload a profile Picture')
    }
    
       }







        if(formState === "SetUpBanner"){
  return(
    <div style={{height:'932px',width:'430px',margin:'auto',marginTop:'0px'}}>

    <div  className="mobileBannerSetUp" >
    <input
        type="file"
        style={{ display: 'none' }}
        onChange={onProcessFile2}
        ref={fileInput2}
   
    />
  <button
  className="mobileBannerButton"
  onClick={() => fileInput2.current.click()}
>
</button>
    </div>


    </div>
 
)
}
if(formState === "SetUpUserPic"){
    return(
      <div style={{height:'932px',width:'430px',margin:'auto',marginTop:'0px'}}>
  
    
     
      <div  className="mobileBannerUploaded" >
    <Image
      src={UserBanner}
      style={{ width:'430px',height:'246px'}}
  />
  
      </div>
    
  
   <div className="MobileuserAvatarHolder" >
      <input
          type="file"
          style={{ display: 'none' }}
          onChange={onProcessFile}
          ref={fileInput}
     
      />
    <button
    className="mobileProfileButton"
    onClick={() => fileInput.current.click()}
  >
  </button>
      </div>
    
      
  
      </div>
  )
  }

  if(formState==="createBio"){
    return(

   
            <div style={{height:'932px',width:'430px',margin:'auto',marginTop:'0px'}}>
        
          
           
            <div  className="mobileBannerUploaded" >
          <Image
            src={UserBanner}
            style={{ width:'430px',height:'246px'}}
        />
        
            </div>
          
        
         
            <div className="MobileuserAvatarUploaded" >
          <Image
            src={UserImage}
            style={{ height:'160px',width:'160px',marginRight:'56px',marginLeft:'56px',borderRadius:'100%'}}
        />
        
        
            </div>
          <div className="userDetailsMobile">

        <p id="userNameMobile"> {CurrentUser.attributes.name}</p>

       
          </div>
          <div className="userprefnameDetailsMobile">

<p id="userprefNameMobile"> {CurrentUser.attributes.preferred_username}</p>


  </div>

  <div className="userBioDetailsMobile">

<p id="userbioMobile">Add Bio*</p>


  </div>
  
  <div className="userMobileBioInput">


<textarea className="userMobileBioInputBox"  name="bio"  onChange={onChange}/>

  </div>
  <button className="userBioSubmitmobile" onClick={updateUser} >
      <p> Submit</p>  
      </button>

            </div>

            
        )
    
  }

if(formState ==="DoneSettingUp"){
 
  return (
  
  
  <div style={{height:'736px',width:'410px',margin:'auto',marginBottom:'116px',marginTop:'80px'}}>
    <div  className="mobile-modal-area-setUp" >
   
    <div className="mobile-modal-body-setUp">
    
    
    <div style={{width:'90%', margin:'auto',justifyContent:'space-between',marginTop:'1%',paddingBottom:'1%',}}>
    
  
      <div style={{width:'330px',height:'52px',margin:'auto',marginBottom:'28px',marginTop:'70px'}}>
      <p className='mobileHeadliner'>
      Account Was fully Set Up!
        </p>
      <p className='mobileHeader2'>
     
        </p>
      </div>

 
    
      <NavLink  to="/"   style={{textDecoration:'none'}}>
      <button  className='mobileConfirmSubmit' >
        <p>Continue</p>
      </button>
      </NavLink>
    </div>
  
    
    </div>
    </div>
    </div>
  
  )
}
}

export default MobileAccountSetUp;