
import React, {  useEffect, useState, lazy, Suspense} from 'react';

import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Route, Routes, useNavigate, Redirect, Link, Navigate,NavLink, ScrollRestoration } from 'react-router-dom';
//import Landing from './screens/Landing';


import Navigation from './Navigation';
import "./styles/Navbar.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import '@aws-amplify/ui-react/styles.css';
import "./styles/circles.css"
import ChatRoom from './screens/ChatRoom';
import AdminSettings from './screens/AdminSettings';
import ChatChannel from './screens/ChatChannel';
import Myaccount3 from './screens/Myaccount3';
import NewFooter from './screens/NewFooter';
import SignUp from './screens/SignUp';
import FAQ from './screens/FAQ';
import Login from './screens/Login';
import PrivacyPolicy from './screens/PrivacyPolicy';
import Terms from './screens/Terms';
import AccountsetUp from './screens/AccountsetUp';
import UserSettings from './screens/UserSettings';
import UserPage from './screens/UserPage'
import HowItWorks from './screens/HowItWorks';
import ClubLanding from './screens/ClubLanding';
import MintPage from './screens/MintPage';
import UserBugReports from './screens/components/UserBugReports';
import ContactChieve from './screens/components/ContactChieve';

//import MobileLanding from './mobilescreens/MobileLanding';
import MobileLogin from './mobilescreens/MobileLogin';
import MobileSignUp from'./mobilescreens/MobileSignUp';
import MobileMyaccount from './mobilescreens/MobileMyaccount';
import MobileUserSettings from './mobilescreens/MobileUserSettings';
import MobileExplore from './mobilescreens/MobileExplore';
import MobileFooter from './mobilescreens/MobileFooter';
import MobileAccountSetUp from './mobilescreens/MobileAccountSetUp';

import MobileClubPage from './mobilescreens/MobileClubPage';
//import MobileMint from './mobilescreens/MobileMint';

//import MobileUserPage from './mobilescreens/MobileUserPage';

import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';
import {  Nav, Container } from 'react-bootstrap';
import Image from 'react-bootstrap/Image';
import { FiKey} from 'react-icons/fi';
import useAnalyticsEventTracker from './useAnalyticsEventTracker';
import 'reactjs-popup/dist/index.css';
import ScrollToTop from './ScrollToTop';
import awsconfig from './aws-exports';
import { User ,Club,NFTCollection,PollsClub, ClubUser, Errors} from './models';
import { Amplify, Auth, Hub ,API,Logger,Storage} from "aws-amplify";
import { StreamChat } from 'stream-chat';
import { CDBBtn, CDBBtnGrp } from "cdbreact";
import { DataStore } from '@aws-amplify/datastore';
import { Web3Modal,Web3Button, useAccount,useNetwork, useSwitchNetwork  } from '@web3modal/react'
import { BiExit } from 'react-icons/bi';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import chat from './assets/icons/Outline/blue/chat.svg'
import {  ListGroup } from 'react-bootstrap';
import SplashScreen from './SplashScreen'
import ReactGA from "react-ga4";
import newBurger from './assets/icons/newBurger.svg'
import newLogo from './assets/banner/newLogo.svg'
import { ThirdwebProvider,ChainId, ConnectWallet, useAddress ,localWallet} from '@thirdweb-dev/react';
import BlueButton from './screens/components/BlueButton';
import UserImages from './screens/components/UserImages';
import axios from "axios"
import SplashScreenStart from './SplashScreenStart';
import AvatarBannerGenerator from './screens/components/AvatarBannerGenerator';
import { Menu, MenuButton, MenuItem, View } from '@aws-amplify/ui-react';
import MobileMyAcc from './mobilescreens/MobileMyAcc';
import UserSettings2 from './screens/UserSettings2';

Amplify.configure({...awsconfig, ssr: true});
Storage.configure({ track: true, level: "public" });
const logger = new Logger('My-Logger');

const api_key = "huhccc7rq7f3";
const api_secret = "d94n7udcrcjuvb2t45phy8fe7t7qyxdfjbqj6bhcm2b8hfskdhafsmx5w8d59dxv";

const serverClient = StreamChat.getInstance( api_key, api_secret);
const client = StreamChat.getInstance("huhccc7rq7f3");

const configWallet = {
  projectId:'991c9d3e99652a05f32025bbf5f7567a',
  theme: "dark",
  accentColor: "orange",
  ethereum: {
    appName: 'web3Modal',
    autoConnect: true,
    
  },

 
};

const MobileUserPage = lazy(()=>import('./mobilescreens/MobileUserPage'))
const Landing = lazy(()=>import('./screens/Landing'))
const MobileMint = lazy(()=>import('./mobilescreens/MobileMint'))
const MobileLanding  = lazy(()=>import('./mobilescreens/MobileLanding'))
export default function App() {
  
  const [CurrentUser, setUser] = useState(null);
  const [error, setError] = useState(null);
  const [AutoUser, setAutoUser] = useState(null);
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [hubLog, NewhubLog] = useState(null);
  const [UserImage, setImage] =useState(null);
  const [allClubLinks,setFeed]=useState([])
  const [allClubUser,setClubUser]=useState([])
  const [allUserLinks,setUserLinks]=useState([])
  const [allCollections,setColls]=useState([])
  const [allPolls,setPolls]=useState([])
  const [load,setLoad] =useState(true);
  const[userProImage,setProImage]=useState(null);
  const [admin, checkAdmin]= useState(false);
  const [showPopover, setPopover]= useState(false);
  const [wallet, setWallet] = useState(null);
  const newWallet = useAddress()
  const gaEventTracker = useAnalyticsEventTracker('App Sign in');
  useEffect(() => {
   
   
    if(CurrentUser != null){
      setAutoUser(true);
     
      Storage.get("Users/"+CurrentUser.username+"/Pro.png", {
      
    }).then((r) =>{
      if(r){
        setProImage(r)

      }
      }).catch((err)=>{
        Storage.get("userAlien.png",{
  
        }).then((usI)=>{
          setProImage(usI)
        }).catch((err)=>{
          console.log(err)
        })
      })
      DataStore.query(User, u => u.UserID("eq", CurrentUser.username)).then((info)=>{
    
        if(info[0].isAdmin){
         
          checkAdmin(true)
        }
        
      })
    }

    DataStore.query(User).then((userFeed)=>{
        setUserLinks(userFeed)

    }).catch(err=>console.log(err))
    DataStore.query(Club).then((feed)=>{
      setFeed(feed.filter((f)=>f.category == 'Gaming'))
      //setFeed(feed)
      DataStore.query(ClubUser).then((cUsers)=>{
        setClubUser(cUsers)
      }).catch(err=>console.log(err))
      DataStore.query(NFTCollection).then((colls)=>{
        setColls(colls)
      }).catch(err=>console.log(err))
      setTimeout(()=>{
        setLoad(false)
      }, 1500)
      
   //I added a new node version
   
  
   
   
    })
    Hub.listen('auth', listener);
    getUserData();
    function handleWindowResize() {
      setWindowSize(getWindowSize());
     
    }
    
    window.addEventListener('resize', handleWindowResize);
  
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    
    };
  }, []);
  useEffect(() => {
    window.localStorage.setItem('CurrentUser', CurrentUser);
   
  }, [CurrentUser]);
  const getUserData= ()=>{
 
      setError(null);
      
      Auth.currentAuthenticatedUser({
        bypassCache: true  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
      }).then(user => {
        
       if(user){ 
        setUser(user)
        const gaUser = user.attributes.preferred_username
        
        gaEventTracker( gaUser)
        client.connectUser(
          {
              id: CurrentUser ? CurrentUser.attributes.preferred_username :user.attributes.preferred_username,
              name:CurrentUser ?  CurrentUser.attributes.name :user.attributes.preferred_username,
              
          },
          client.devToken(CurrentUser ? CurrentUser.attributes.preferred_username :user.attributes.preferred_username),
      ).then((y)=>{
        DataStore.query(User, u => u.UserID("eq", CurrentUser? CurrentUser.username : user.username)).then(async(status)=>{
          await DataStore.save(
            User.copyOf(status[0], updated => {
              updated.onlineStatus= true
            }));
        }).catch((err)=>{console.log(err)})
          
          })
        Storage.get("Users/"+user.username+"/Pro.png", {
      
        }).then((r) =>{
          if(r){
            setProImage(r)
            console.log(r)
           client.upsertUser({
            id: CurrentUser ? CurrentUser.attributes.preferred_username :user.attributes.preferred_username,
              name:CurrentUser ?  CurrentUser.attributes.name :user.attributes.preferred_username,
              image:r
           })
           
          }
          }).catch((err)=>{
            Storage.get("userAlien.png",{
      
            }).then((usI)=>{
              setProImage(usI)
            }).catch((err)=>{
              console.log(err)
            })
          })
          DataStore.query(User, u => u.UserID("eq", user.username)).then((info)=>{
            if(info[0].isAdmin){
              checkAdmin(true)
            }
            var d = new Date();
            DataStore.save(
              User.copyOf(info[0],updated=>{
                updated.lastLogin = d.toLocaleDateString() +"-"+d.getHours()+":"+ d.getMinutes() 
              })
             ).then(()=>{
              console.log("User Signed In ", d.toLocaleDateString() +"-"+d.getHours()+":"+ d.getMinutes() )
            })
            if(!info[0].userEmail){
           DataStore.save(
            User.copyOf(info[0],updated=>{
              updated.userEmail = user.attributes.email
            })
           ).then(()=>{
            console.log("done")
          })
            }
            
          })
    window.localStorage.getItem('CurrentUser',CurrentUser)

  
        }
      }).catch(err => setError(err));
    
  
    if(CurrentUser !== null){
      setAutoUser(true);
      DataStore.query(User, u => u.UserID("eq", CurrentUser.username)).then((info)=>{
        if(info[0].isAdmin){
          checkAdmin(true)
        }
        if(!info[0].userEmail){
          DataStore.save(
           User.copyOf(info[0],updated=>{
             updated.userEmail = CurrentUser.attributes.email
           })
          ).then(()=>{
            console.log("done")
          })
           }
           
      })
      
    }else{
      setAutoUser(false);
    }
   
  }
  const signOut = ()=> {
    try {
         Auth.signOut({ global: true }).then(()=>{
          setAutoUser(false);
          setUser(null);
          client.disconnectUser().then(() =>{ 
            DataStore.query(User, u => u.UserID("eq", CurrentUser.username)).then(async(status)=>{
              await DataStore.save(
                User.copyOf(status[0], updated => {
                  updated.onlineStatus= false
                }));
            }).catch((err)=>{console.log(err)})
   
            
            console.log('connection closed')
          
          })
         })
    } catch (error) {
        alert('error signing out: ', error);
    }
}

  const styles = {
    wrapper: {
      padding: '0px',
      margin: '0px',
      width: windowSize.innerWidth
    }
  }
const reportError= (l)=>{
  console.log(l)
  
}
  const listener = (data) => {
    
      switch (data.payload.event) {
        
        case 'signIn':
              logger.info('user signed in');
            
              NewhubLog(logger)
              {getUserData()}
              
              break;
          case 'signUp':
              logger.info('user signed up');
              NewhubLog(logger)
              
              reportError(logger)
             
              {getUserData()}
              break;
          case 'signOut':
              logger.info('user signed out');
              NewhubLog(logger)
              
              reportError(logger)
              break;
          case 'signIn_failure':
              logger.error('user sign in failed');
              NewhubLog(logger)
              reportError(logger)
              break;
          case 'tokenRefresh':
              logger.info('token refresh succeeded');
              NewhubLog(logger)
              
              break;
          case 'tokenRefresh_failure':
              logger.error('token refresh failed');
              NewhubLog(logger)
              break;
          case 'autoSignIn':
              logger.info('Auto Sign In after Sign Up succeeded');
              {getUserData()}
              break;
          case 'autoSignIn_failure':
              logger.error('Auto Sign In after Sign Up failed');
              NewhubLog(logger)
              reportError(logger)
              break;
          case 'configured':
              logger.info('the Auth module is configured');
      }
  }
  
/*

Explore page not being used right now, saving code and routing for later

  <NavLink  to="/Explore" id="text" style={{textDecoration:'none'}}>Explore</NavLink >
<Route path="/Explore" element={<Explore  key={Math.random()} props={(CurrentUser)}/>} />  
    {!load &&
       allClubLinks.map((i) =>{
     
 return(
  <Route  path={"/User/"+i.preferred_username}  element={<UserPage props={i} />}/>
 )
  

})
     } 
**/

const newWalletSetUp = async ()=>{

  setLoad(true)
  try {
      
    setLoad(true)
    Auth.updateUserAttributes(CurrentUser, {
      'custom:wallet': newWallet
    });

    const newWalletConnection = await DataStore.query(User, u => u.UserID("eq", CurrentUser.username))
    await DataStore.save(
      User.copyOf(newWalletConnection[0], updated => {
        updated.Wallet= newWallet
      }));
      setWallet(newWallet)
   
   
      
       setLoad(false)
     
       
  } catch (e) {
    console.log(e)
    setLoad(false)
  }
}
if(load){
        return(
         <SplashScreen />
          )
}




if(windowSize.innerWidth > 440 && !load){

  

  const popover = ()=>{ 

                      if(CurrentUser !== null){
                  
                        DataStore.query(User, u => u.UserID("eq", CurrentUser.username)).then((info)=>{
                          if(info[0].isAdmin){
                            checkAdmin(true)
                          }
                          
                        })
                        
                      }
                      return(
                        <Popover id="popover-basic" style={{border:'0px',background:'transparent'}}  >
     
                        <Popover.Body >
                         
                      
                        
                         
                        <ListGroup style={{border:'0px',background:'transparent'}}   >
                          <ListGroup.Item className=""  style={{border:'0px',background:'#00000040',}}  >     
                               <NavLink  to="/Myaccount" onClick={()=>{handleClick()}}
                                   style={{textDecoration:'none',margin:'auto',border:'0px',color:'white',
                                   fontWeight:'bold',overflow:'hidden',}}
                                   
                                   >
                                    Profile
                  
                      </NavLink >
                  
                          </ListGroup.Item>
                         
                          <ListGroup.Item  className=""  style={{border:'0px',background:'#00000040',}}  >   
                               <NavLink  to="/AccountSettings" onClick={()=>{handleClick()}}
                                   style={{textDecoration:'none',margin:'auto',border:'0px',color:'white',
                                   fontWeight:'bold',overflow:'hidden',}}>
                                    Settings
                  
                      </NavLink >
                  
                          </ListGroup.Item>
                          {
                           admin === true &&
                            <ListGroup.Item  className=""  style={{border:'0px',background:'#00000040',}}  >   
                            <NavLink  to="/Admin" onClick={()=>{handleClick()}}
                                style={{textDecoration:'none',margin:'auto',border:'0px',color:'white',
                                fontWeight:'bold',overflow:'hidden',}}>
                                Manage Club
                  
                   </NavLink >
                  
                       </ListGroup.Item>
                       
                          }
                          
                     <ListGroup.Item style={{border:'0px',background:'#9a3333',}}    >   
                          
                  
                        
                        <NavLink  to="/"    >
                  <button onClick={signOut}  style={{border:'0px',color:'white',fontWeight:'bold',background:'transparent',}}>
                  <BiExit size="1.25rem"className='my-auto' />{' '}Log Out</button>
                  </NavLink>
                  
                  
                       </ListGroup.Item>
                          </ListGroup>
                         
                        </Popover.Body>
                      </Popover>
                      )
           
  
}
  const handleClick = () => {
    setPopover(!showPopover);
   
  };
  if(CurrentUser != null && !userProImage){
    setLoad(true)
    Storage.get("Users/"+CurrentUser.username+"/Pro.png",{
  
    }).then((usI)=>{
      setProImage(usI)
      setLoad(false)
    }).catch((err)=>{
      console.log(err)
      Storage.get("userAlien.png",{
  
      }).then((usI)=>{
        setProImage(usI)
        setLoad(false)
      }).catch((err)=>{
        console.log(err)
        setLoad(false)
      })
    })
  }
  const addDefaultSrc =(ev)=>{
    var randName = ["Kiki","Bella","Cleo","Molly","Nala","Snuggles","Sammy","Baby","Annie"];
                const randomSeed = Math.floor(Math.random() * randName.length);
    AvatarBannerGenerator(ev.target.src.slice(108,144))
      let tempUrl = 'https://api.dicebear.com/6.x/bottts-neutral/svg?seed='+randName[randomSeed]+''
    ev.target.src = tempUrl
    //ev.target.src.slice(108,144)
setProImage(tempUrl)
   }
 
  return (
  

    
<Suspense fallback={<SplashScreen />}>



    <div className='mx-auto'style={{ width: window.innerWidth,maxWidth:window.innerWidth,background:'#1c1f35',overflow:'hidden',
     }}>
         
         <ThirdwebProvider desiredChainId={ChainId.Polygon} >


      <Router forceRefresh={true} style={{ width: window.innerWidth,overflowX:'hidden',maxWidth:window.innerWidth }}>
      <Navbar collapseOnSelect expand="lg" className='mx-auto pt-2 px-2'
      style={{maxWidth:window.innerWidth,width:window.innerWidth,maxHeight:'80px',background:'transparent',overflow:'hidden',}}>
      <Container style={{maxWidth:"1320px",width:"100%",maxHeight:'80px',background:'#00000040',borderRadius:"10px",overflowX:'hidden'}}>
        <Navbar.Brand style={{float:'left'}} > 
        <NavLink exact  to="/" >
<Image
    alt=""
    src={newLogo}
    width="100%"
    height="100%"
    
    className="mx-0 my-auto"
    responsive="true"
  />
</NavLink>
</Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
     
           
          </Nav>
          {(CurrentUser !== null )&& 
            <Nav>

<CDBBtn className="me-2 my-auto" style={{background:'transparent', border:'0px',borderRadius:'8px',width:'120px',height:'40px'}} >
              <NavLink  to="/FAQ"  
               style={{textDecoration:'none', color:'white',fontWeight:'800'}}>
    
    FAQ

    </NavLink >
    </CDBBtn>
 
        <ConnectWallet  className='me-2' style={{width:'100px',border:'0px',height:'36px',backgroundColor:'#2942f4'}}/>
      

              <CDBBtn onClick={()=>{handleClick()}} style={{margin:'0 0 0 16px',background:'transparent',height:'55px',width:'55px',borderRadius:'100%',overflow:'hidden',border:'0px'}}>

              <OverlayTrigger placement="bottom" overlay={popover()}show={showPopover} >

          
                  <Image src={userProImage} alt="Profile" className="mx-auto"height="55px" width="55px" onError={addDefaultSrc}  style={{}}/>

   
</OverlayTrigger  >
              </CDBBtn>
            


            
           
            
          </Nav>
          ||

          <Nav>
            <CDBBtnGrp>
           
            <CDBBtn className="mx-2 my-auto" style={{background:'transparent', border:'0px',width:'156px',height:'45px'}} >
              <NavLink  to="/Login"  
               style={{textDecoration:'none', color:'white',fontWeight:'700',fontSize:'14px'}}>
    
    Log In 

    </NavLink >
    </CDBBtn>


    <CDBBtn className="mx-2" style={{background:'#00D4C5', border:'0px',borderRadius:'100px',width:'156px',height:'45px'}} >
           
            <NavLink  to="/SignUp" 
             style={{textDecoration:'none',color:'white'}}>
    Sign Up 

    </NavLink >

    </CDBBtn>
   
            </CDBBtnGrp>
        


          </Nav>


}
          
        </Navbar.Collapse>
      </Container>
    </Navbar>
<div>
<ScrollToTop>

   <Routes>
  
   <Route path='/' element={<Landing  props={(CurrentUser)}clubs={(allClubLinks)} screenSize={windowSize} />} />     

    

 
     
    
     <Route path="/Myaccount" element={<Myaccount3  props={(CurrentUser)}  screenSize={windowSize}/>} />
     <Route path="/SignUp" element={<SignUp screenSize={windowSize}/>} />
     <Route path="/AccountsetUp" element={<AccountsetUp props={(CurrentUser)} screenSize={windowSize}/>} /> 
     <Route path="/Login" element={<Login  props={(CurrentUser)} screenSize={windowSize}/>} />  
     <Route path="/Privacy" element={<PrivacyPolicy  props={(CurrentUser)} screenSize={windowSize} />} /> 
     <Route path="/Terms" element={<Terms  props={(CurrentUser)} screenSize={windowSize} />} /> 
     <Route path="/AccountSettings" element={<UserSettings  props={(CurrentUser)}/>} /> 
     <Route path="/FAQ" element={<FAQ  props={(CurrentUser)} screenSize={windowSize} />} /> 
     <Route path="/Admin" element={<AdminSettings  props={(CurrentUser)} screenSize={windowSize} />} /> 
     <Route path="/HowItWorks" element={<HowItWorks screenSize={windowSize} />} /> 
     <Route path="/GatedChat" element={<ChatRoom screenSize={windowSize} />} /> 
     <Route path="/Troubleshoot" element={<UserBugReports />} /> 
     <Route path="/ContactUs" element={<ContactChieve />} /> 
     {!load &&
          

       allClubLinks.map((i) =>{
     let urlCLub = i.name.replace(/ /g, '');
  let tempCollections = allCollections.filter(k=> k.clubID == i.id)
          let tempClubUser = allClubUser.filter(k => k.club.id == i.id)
          tempCollections = tempCollections.filter(ss=> ss.signupLive != false)
    let tempPolls= allPolls.filter(k=> k.club.id == i.id)
        if(tempPolls.length > 0){
          
          return(
          <>
          
          <Route  path={"/"+urlCLub}  element={<ClubLanding key={Math.random()} clubMembers={tempClubUser} clubInfo={i} polls={tempPolls} collections={tempCollections}  props={(CurrentUser)} screenSize={windowSize}/>}/>
             <Route  path={"/"+urlCLub+"/"+"Chat"}  element={<ChatChannel props={(CurrentUser)}chat={(client)} screenSize={windowSize}/>}/>
            
          </>
           
        
              )
        }else{
          return(
            <>
            <Route  path={"/"+urlCLub}  element={<ClubLanding key={Math.random()}  clubMembers={tempClubUser}  clubInfo={i} polls={tempPolls} collections={tempCollections}  props={(CurrentUser)} screenSize={windowSize}/>}/>
            <Route  path={"/"+urlCLub+"/"+"Chat"}  element={<ChatChannel props={(CurrentUser)} chat={(client)} clubInfo={i} screenSize={windowSize}/>}/>
            </>
              )
        }
            
        
    
  })

  

      
}
{!load &&
          

          allUserLinks.map((i) =>{
        let userInfo = i.preferred_username.replace(/ /g, '');
     
   
      
         return(
       <Route  path={"/"+userInfo}  element={<UserPage  Info={i}  props={(CurrentUser)} screenSize={windowSize}/>}/>
   
         )
     })
   
     
   
         
   }
      
     {!load &&
          

          allCollections.map((i) =>{
        let urlColl = i.name.replace(/ /g, '');
   
    return(
    
      <Route path={"/"+urlColl} element={<MintPage  props={(CurrentUser)}collectionInfo={i}  screenSize={windowSize} />} /> 
   
    )
     
   
   })

  }



   </Routes>
 
   </ScrollToTop>
   <div style={{background:'linear-gradient(-180deg,rgba(24,31,97,.02), rgba(0,212,197,.18))',width:windowSize.innerWidth,maxWidth:windowSize.innerWidth,margin:'auto',overflowX:'hidden'}}>
<NewFooter />
</div>
 </div>
 
</Router>

</ThirdwebProvider>

    </div>
    </Suspense>
  )
  }


/////////// Mobile App Code ///////////////

  if(windowSize.innerWidth <= 440  && !load){
    if(CurrentUser != null){
      Storage.get("Users/"+CurrentUser.username+"/Pro.png",{
  
      }).then((usI)=>{
        setProImage(usI)
      }).catch((err)=>{
        Storage.get("userAlien.png",{
  
        }).then((usI)=>{
          setProImage(usI)
        }).catch((err)=>{
          console.log(err)
        })
      })
    }


    const popover = ()=>{ 

      if(CurrentUser !== null){
  
        DataStore.query(User, u => u.UserID("eq", CurrentUser.username)).then((info)=>{
          if(info[0].isAdmin){
            checkAdmin(true)
          }
          
        })
        
      }
      return(
        <Popover id="popover-basic" style={{border:'0px',background:'transparent'}}>

        <Popover.Body >
         
      
        
         
            {(CurrentUser !== null )&& 
            
            <ListGroup style={{border:'0px',background:'transparent'}}   >
            <ListGroup.Item className=""  style={{border:'0px',background:'#000',}}  >   
            <NavLink  to="/Myaccount" onClick={()=>{handleClick()}}
                style={{textDecoration:'none',margin:'auto',border:'0px',color:'white',
                fontWeight:'bold',overflow:'hidden',}}>
                 Profile

   </NavLink >

       </ListGroup.Item>
      
       <ListGroup.Item className=""  style={{border:'0px',background:'#000',}}  >     
            <NavLink  to="/AccountSettings" onClick={()=>{handleClick()}}
                style={{textDecoration:'none',margin:'auto',border:'0px',color:'white',
                fontWeight:'bold',overflow:'hidden',}}>
                 Settings

   </NavLink >

       </ListGroup.Item>
 
       
    <ListGroup.Item style={{border:'0px',background:'#9a3333',}}    >   
                          
                  
                        
                        <NavLink  to="/"    >
                  <button onClick={signOut}  style={{border:'0px',color:'white',fontWeight:'bold',background:'transparent',}}>
<BiExit size="1.5rem"/>{' '}Log Out</button>
</NavLink>


    </ListGroup.Item>
            
            
            </ListGroup>
            
         ||
            
          <ListGroup>
            <ListGroup.Item  style={{border:'0px',background:'#000',}}   >   
            <NavLink  to="/Login" onClick={()=>{handleClick()}}
                style={{textDecoration:'none',margin:'auto',border:'0px',color:'white',
                fontWeight:'bold',overflow:'hidden',}}>
                Login
   </NavLink >

       </ListGroup.Item>
      
       <ListGroup.Item   style={{border:'0px',background:'#000',}}  >   
            <NavLink  to="/SignUp" onClick={()=>{handleClick()}}
                style={{textDecoration:'none',margin:'auto',border:'0px',color:'white',
                fontWeight:'bold',overflow:'hidden',}}>
                 Sign Up

   </NavLink >

       </ListGroup.Item>
 
 
            
            </ListGroup>
            
            }
     
       
         
        </Popover.Body>
      </Popover>
      )


}
const handleClick = () => {
setPopover(!showPopover);

};
    return(

      <div style={{ width: windowSize.innerWidth,overflow:'hidden',background:'#1c1f35' }}>
         <Suspense fallback={<SplashScreen />}>

   
         <ThirdwebProvider desiredChainId={ChainId.Polygon}>

         

      <Router forceRefresh={true} >
      <Navbar  expand="lg" style={{background:'#151b4e',maxWidth:'414px',width:windowSize.innerWidth,margin:'auto',maxHeight:'80px',background:'transparent'}}>
      <Container style={{maxWidth:"95%",width:"95%",maxHeight:'80px',background:'#00000040',borderRadius:"10px",overflowX:'hidden'}}>
        <Nav style={{display:'flex',flexDirection:'row', justifyContent:'space-between',width:window.innerWidth,maxWidth:window.innerWidth}}>


        <Navbar.Brand className='my-auto' style={{display:'flex'}}>
           <NavLink exact  to="/" style={{margin:'auto'}}>
<Image
    alt=""
    src={newLogo}
    width="100%"
    height="100%"
   
      style={{display:'flex',maxWidth:'150px'}}
    responsive="true"
  />
</NavLink>
</Navbar.Brand>
        

 
   <CDBBtn onClick={()=>handleClick()} className="ms-4 me-2 my-auto" style={{height:'25px',width:'25px', display:'flex',background:'transparent',border:'0px'}}>

<OverlayTrigger placement="bottom" overlay={popover()}show={showPopover} >


<Image src={newBurger} className="mx-auto" style={{height:'25px',width:'25px',maxHeight:'25px',display:'flex' }} />


</OverlayTrigger  >
</CDBBtn>


   

    


      




        </Nav>
      
      
      </Container>
    </Navbar>
<div>
<ScrollToTop>

   <Routes>
 
     <Route path='/' element={<MobileLanding  props={(CurrentUser)}clubs={(allClubLinks)} screenSize={windowSize} />} />

   {/* <Route path="/Myaccount" element={<MobileMyaccount  props={(CurrentUser)} screenSize={windowSize}/>} /> **/}  

   <Route path="/Myaccount" element={<MobileMyAcc  props={(CurrentUser)} screenSize={windowSize}/>} /> 
     <Route path="/SignUp" element={<MobileSignUp screenSize={windowSize}/>} />
     <Route path="/AccountSetUp" element={<MobileAccountSetUp props={(CurrentUser)} screenSize={windowSize}/>} />
     <Route path="/Login" element={<MobileLogin screenSize={windowSize} />} />  
     
     <Route path="/Explore" element={<MobileExplore  props={(CurrentUser)} screenSize={windowSize}/>} />  
     <Route path="/Privacy" element={<PrivacyPolicy  props={(CurrentUser)} screenSize={windowSize} />} /> 
     <Route path="/Terms" element={<Terms  props={(CurrentUser)} screenSize={windowSize} />} />
     <Route path="/FAQ" element={<FAQ  props={(CurrentUser)} screenSize={windowSize} />} /> 
   
     <Route path="/AccountSettings" element={<MobileUserSettings   props={(CurrentUser)}/>} /> 
     <Route path="/Troubleshoot" element={<UserBugReports />} /> 
     <Route path="/ContactUs" element={<ContactChieve />} /> 
     {!load &&
          

          allClubLinks.map((i) =>{
        let urlCLub = i.name.replace(/ /g, '');
     let tempCollections = allCollections.filter(k=> k.clubID == i.id)
   
      
         return(
          <>
               <Route  path={"/"+urlCLub}  element={<MobileClubPage  clubInfo={i} collections={tempCollections}  props={(CurrentUser)} screenSize={windowSize}/>}/>
       <Route  path={"/"+urlCLub+"/"+"Chat"}  element={<ChatChannel props={(CurrentUser)} chat={(client)} clubInfo={i} screenSize={windowSize}/>}/>
          </>
  
         )
     })
   
     
   
         
   }
       {!load &&
          

          allUserLinks.map((i) =>{
        let userInfo = i.preferred_username.replace(/ /g, '');
     
   
      
         return(
       
       <Route  path={"/"+userInfo}  element={<MobileUserPage  Info={i}  props={(CurrentUser)} screenSize={windowSize}/>}/>
  

         )
     })
   
     
   
         
   }  
        {!load &&
             
   
             allCollections.map((i) =>{
           let urlColl = i.name.replace(/ /g, '');
      
       return(
       
         <Route path={"/"+urlColl} element={<MobileMint  props={(CurrentUser)}collectionInfo={i}  screenSize={windowSize} />} /> 
 

       )
        
      
      })
   
     }
   </Routes>
 
   </ScrollToTop>
   <div style={{background:'linear-gradient(-180deg,rgba(24,31,97,.02), rgba(0,212,197,.18))',width:windowSize.innerWidth,position:'relative',
   bottom:0}}>
<MobileFooter />
</div>
 </div>
 
</Router>
<Web3Modal config={configWallet} />
</ThirdwebProvider>
</Suspense>
    </div>

    )
  }

}


function getWindowSize() {
  const { innerWidth, innerHeight } = window;

  return { innerWidth, innerHeight };
}




