import Popup from 'reactjs-popup';
import React, { useState, useEffect } from 'react';
import awsconfig from '../aws-exports';
import '../styles/setUp.css';
import { Amplify, Auth,API, Hub ,Logger,Storage } from "aws-amplify";
import Image from 'react-bootstrap/Image';
import { BrowserRouter as Router, Route, Routes, useNavigate, Redirect, Link, Navigate,NavLink } from 'react-router-dom';
import MobileAccountSetUp from './MobileAccountSetUp';
import { DataStore } from '@aws-amplify/datastore';
import { User } from '../models';
import{BiHide,BiShow} from 'react-icons/bi'
import signInButton from '../mobileAssets/images/signInButton.svg'
import { CDBBtn } from 'cdbreact';
import BlueButton from '../screens/components/BlueButton';
import AvatarGenerator from '../screens/components/AvatarGenerator';
Amplify.configure(awsconfig);
export default function MobileLogin(screenSize) {
  const navigate = useNavigate();
  const [formInputState, setformInputState]= useState({email: '', password: '',verificationCode: '',confirmEmail:'',newCode:'',confirmNewPassword:'',confirmNewEmail:'',NewPassword:''})
  const [formState, setFormState] = useState("LogIn");
  const [passwordShown, setPasswordShown] = useState(false);
  const[windowSize,setSize]= useState(screenSize.screenSize)
  useEffect(()=>{
    setSize(screenSize.screenSize)
  },[])
  function togglePassword  ()  {
    // When the handler is invoked
    // inverse the boolean state of passwordShown
    setPasswordShown(!passwordShown);
  };
  function onChange(e) {
    setformInputState({ ...formInputState, [e.target.name]: e.target.value })

  }
  function confirmSignUp() {

    var d = new Date();
    Auth.confirmSignUp(formInputState.email, formInputState.verificationCode).then((res)=>{
      
      alert("Email Confirmed, please log in")
      Auth.signIn(formInputState.email, formInputState.password).then(async (newUserAdded)=>{
        console.log(newUserAdded)
        await DataStore.save(
          new User({
       
          "name": newUserAdded.attributes.name,
      "preferred_username": newUserAdded.attributes.preferred_username,
      "UserID":newUserAdded.username,
      "userEmail":newUserAdded.attributes.email,
      "bio":' ' ,
      "earned":0,
      "wins":0,
      "staked":0,
      "isAdmin":false,
      "Wallet":"",
      "lastLogin":d.toLocaleDateString() +"-"+d.getHours()+":"+ d.getMinutes() 
         
        }));
        Storage.get("tempBanner.png", {
  
          download:true
      }).then((tempBanner)=>{
        console.log(tempBanner)
        Storage.put("Users/"+newUserAdded.username+"/Banner.png", tempBanner.Body, {
          
          contentType: tempBanner.Body.type
        })
          .then((result) =>{
            
            AvatarGenerator(newUserAdded.username)
            setFormState("Complete");
      
          })
          .catch(err => console.log(err));
      }).catch((err)=>{console.log(err)})
     

      }) }).catch((err)=>{

      alert(err.message );
   
      setFormState("AccountConfirm");
    })
    
    
    

}


  async function SignInForm() {
    try {
      const LoggedUser = await Auth.signIn(formInputState.email, formInputState.password);
      
                    console.log(LoggedUser)
         
      setFormState("Complete");
    } catch (err) {
      if(err.message == "User is not confirmed"){
        alert( err.message +" Please check your email to verify the account!") 
 
        Auth.resendSignUp(formInputState.email).then((x)=>{
          setFormState("AccountConfirm");
        }).catch((err)=>{console.log(err)})
      }else{
        alert( err.message) 
      }
      }
  }
  if(formState === "Complete"){
    navigate("/")
}
if(formState === "AccountConfirm"){
  return (
    <div style={{height:'736px',width:'410px',margin:'auto',}}> 
    <div  className="mobile-modal-area-confirm" >
   
<div className="mobile-modal-body-confirm">


<div style={{height:'736px',width:'410px',margin:'auto',marginBottom:'116px',marginTop:'80px'}}>

<div style={{width:'330px',height:'52px',margin:'auto',marginBottom:'28px',marginTop:'70px'}}>
      <p className='mobileHeadliner'>
       Confirm Email
        </p>
      <p className='mobileHeader2'>
        Please Check your Email inbox for the Verification code
        </p>
      </div>

<div className="mobileFormgroup" style={{margin:'auto',marginBottom:'28px',marginTop:'70px'}}>
  <label >Verification Code</label>
  <input
name="verificationCode"
    className="form-control inputs "
    onChange={onChange}
    placeholder=""
  />
  
</div>


<button  className="mobileConfirmSubmit"style={{margin:'auto',marginBottom:'28px',marginTop:'70px'}} onClick={confirmSignUp}>
<p>Confirm</p>
  </button>

</div>

</div>
</div>
</div>
  )
}
function forgotPass(){
  setFormState("resendEmail")
}
function senEmailCode(){

    Auth.forgotPassword(formInputState.confirmNewEmail)
    .then(data => {
      alert("Please Check your email for your reset code")
      setFormState("forgotPass")
    })
    .catch(err =>{ 
      alert('please enter valid email address')
      
      console.log(err)});
  

}
function resetPass(){
  if(formInputState.NewPassword == formInputState.confirmNewPassword){
    Auth.forgotPasswordSubmit(formInputState.confirmNewEmail, formInputState.newCode,formInputState.NewPassword)
    .then(data => {
      alert("Password Reset")
      setFormState("LogIn")
    })
    .catch(err => alert(err));
  }else{
    alert("Passwords do not match")
  }
 
}
if(formState === "forgotPass"){
  return(
    <div style={{height:'100%',width:'100%',margin:'auto',}}> 
      <div  className="mobile-modal-area" style={{maxWidth:'410px',margin:'auto',marginBottom:'80px',marginTop:'20px'}}>
            <NavLink  to="/"   style={{textDecoration:'none'}}>
  
         <button
           
              aria-label="Close Modal"
              aria-labelledby="close-modal"
              className="_mobile-modal-close"
            
            >
              <span id="close-modal" className="_hide-visual">
                Close
              </span>
              <svg className="_mobile-modal-close-icon" viewBox="0 0 40 40">
                <path d="M 10,10 L 30,30 M 30,10 L 10,30" />
              </svg>
            </button>
            </NavLink>
     <div className="mobile-modal-body">
  
     
      <div style={{width:'100%'}}>
  
        <div style={{width:'336px',height:'52px',marginLeft:'24px',marginRight:'24px',marginBottom:'28px',marginTop:'70px'}}>
        <p className='mobileHeadliner'>
          Reset Password
          </p>
        <p className='mobileHeader2'>
          Please fill in your Code and Password
          </p>
        </div>
        <div className="mobileFormgroup">
    <label >Code</label>
    <div className="mobileFormgroupInput">
    
        <input
          type="text"
          className="form-control"
          name="newCode"
          style={{background:'transparent',outlineWidth:'0px',height:'100%',width:'85%',margin:'auto',border:'0px transparent'}}
         placeholder="Email Code"
        
          onChange={onChange}
        />
       
       
        </div>
      </div>
        
        <div className="mobileFormgroup">
    <label htmlFor="Password">New Password</label>
    <div className="mobileFormgroupInput">
    
        <input
          type={passwordShown ? "text" : "password"}
          className="form-control"
          name="NewPassword"
          style={{background:'transparent',outlineWidth:'0px',height:'100%',width:'85%',margin:'auto',border:'0px transparent'}}
         placeholder="New Password"
         autoComplete="new-password"
          onChange={onChange}
        />
        <button  style={{border:'0px',width:'24px',height:'24px',margin:'auto'}} onClick={togglePassword}>
      {passwordShown ? 
        <BiHide  style={{color:"black", width:'24px',height:'24px',margin:'auto'}}/>
        :
        <BiShow style={{ color:"black",width:'24px',height:'24px',margin:'auto'}}/>
      }
        
        </button>
       
        </div>
      </div>
     
        <div className="mobileFormgroup">
    <label htmlFor="Password">Confirm New Password</label>
    <div className="mobileFormgroupInput">
    
        <input
          type={passwordShown ? "text" : "password"}
          className="form-control"
          name="confirmNewPassword"
          style={{background:'transparent',outlineWidth:'0px',height:'100%',width:'85%',margin:'auto',border:'0px transparent'}}
         placeholder="Confirm New Password"
         autoComplete="new-password"
          onChange={onChange}
        />
        <button  style={{border:'0px',width:'24px',height:'24px',margin:'auto'}} onClick={togglePassword}>
      {passwordShown ? 
        <BiHide  style={{color:"black", width:'24px',height:'24px',margin:'auto'}}/>
        :
        <BiShow style={{ color:"black",width:'24px',height:'24px',margin:'auto'}}/>
      }
        
        </button>
       
        </div>
      </div>
  
  
  
       
       
  <div className="mobileSubmit" >
  <CDBBtn  size="large" onClick={()=>{resetPass()}} className="mx-auto my-2">
          Submit
         </CDBBtn>
         
       
  </div>
 
      </div>
     
   
      </div>
      </div>
      </div>
  )
}

if(formState === "resendEmail"){
return(
  <div className="mx-auto"style={{height:window.innerHeight,width:window.innerWidth,overflowX:'hidden'}}>

      
  <div  className="modal-area mx-4 mt-4" style={{background:'#00000040',height:'439px',width:'350px',
  maxWidth:window.innerWidth,overflowX:'hidden',overflowY:'auto',}} >
        <NavLink  to="/"   style={{textDecoration:'none'}}>
       <button
         
            aria-label="Close Modal"
            aria-labelledby="close-modal"
            className="_mobile-modal-close"
          
          >
            <span id="close-modal" className="_hide-visual">
              Close
            </span>
            <svg className="_mobile-modal-close-icon" viewBox="0 0 40 40">
              <path d="M 10,10 L 30,30 M 30,10 L 10,30" />
            </svg>
          </button>
          </NavLink>
          <div className="modal-body mx-auto my-4">
   
          <div className="my-auto mx-auto" style={{width:'100%', justifyContent:'space-between',}} >
   
   <p style={{color:'white', fontFamily:'Poppins-Regular',fontSize:'24px',lineHeight:'36px',margin:'auto',
   fontWeight:'700',maxWidth:'263px'}}>Reset Password</p>
   <p style={{fontFamily: 'Poppins-Regular',color: 'white',  fontWeight: '400', fontSize: '14px',margin:'auto', width:'263px'}}>
   Please fill in your email 
     </p>
  

    
      

     <div className="form-group mx-auto" style={{display: 'flex',width:'263px', 
     flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', padding: '0px', gap: '8px',}}>
        <input
      name="confirmNewEmail"
      type="email"
         className="form-control"
           style={{borderBottom:'1px solid #00D4C5',borderTop:'0px',borderRight:'0px',borderLeft:'0px',borderRadius:'0px',background:'transparent',color:'white'}}
           onChange={onChange}
          
          placeholder="Example@example.com"
        />
        
      </div>



     
  
     

       
       <BlueButton   onClick={()=>{senEmailCode()}}  className="mx-auto">
    Submit
    </BlueButton>

<BlueButton onClick={()=>{setFormState("LogIn")}} className="mx-auto mt-4 w-75 bg-dark" >
        Cancel
       </BlueButton>
    </div>
   
 
    </div>
    </div>
    </div>
)
 
}
  if(formState==="LogIn"){
  return (
    <div className="mx-auto"style={{height:window.innerHeight,width:window.innerWidth,overflowX:'hidden'}}>

      
    <div  className="modal-area mx-4 mt-4" style={{background:'#00000040',height:'439px',width:"90%",
    maxWidth:window.innerWidth,overflowX:'hidden',overflowY:'auto',}} >
          <NavLink  to="/"   style={{textDecoration:'none'}}>

       <button
         
            aria-label="Close Modal"
            aria-labelledby="close-modal"
            className="_modal-close"
          
          >
            <span id="close-modal" className="_hide-visual">
              Close
            </span>
            <svg className="_modal-close-icon" viewBox="0 0 40 40">
              <path d="M 10,10 L 30,30 M 30,10 L 10,30" />
            </svg>
          </button>
          </NavLink>
   <div className="modal-body mx-auto">


 
   <div className="my-auto" style={{width:'100%', justifyContent:'space-between',}} >
   
   <p style={{color:'white', fontFamily:'Poppins-Regular',fontSize:'24px',lineHeight:'36px',margin:'auto',fontWeight:'700',width:'263px'}}>Welcome Back!</p>
   <p style={{fontFamily: 'Poppins-Regular',color: 'white',  fontWeight: '400', fontSize: '14px',margin:'auto', width:'263px'}}>
   Please fill in your details to access your account.
     </p>
  

    
      

     <div className="form-group mx-auto" style={{display: 'flex',width:'263px', 
     flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', padding: '0px', gap: '8px',}}>
    
        <input
       name="email"
       type="email"
           className="form-control"
           style={{borderBottom:'1px solid #00D4C5',borderTop:'0px',borderRight:'0px',borderLeft:'0px',borderRadius:'0px',background:'transparent',color:'white'}}
           onChange={onChange}
          
          placeholder="Email"
        />
        
      </div>



     
    
        <div className="form-group mx-auto" style={{display: 'flex',width:'263px', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', padding: '0px', gap: '8px',}}>
    
        <input
          type="password"
    
            className="form-control "
            style={{borderBottom:'1px solid #00D4C5',color:'white',borderTop:'0px',borderRight:'0px',borderLeft:'0px',borderRadius:'0px',background:'transparent'}}
            name="password"
            placeholder="password"
          onChange={onChange}
        />
     
       
        </div>
     
   
   
      <button onClick={()=>{forgotPass()}} style={{background:'transparent',textAlign:'center',color:'white',border:'0px',width:'200px',fontSize:'12px',fontWeight:'400'}} 
        className="mb-2 mx-auto">
      Forgot password?
      </button>
         
      
    <BlueButton  onClick={SignInForm}  className="mx-auto">
    Submit
    </BlueButton>

    
    <div className="mx-auto mt-5" style={{display:'block',width:'300px'}}>
      <p className="mx-auto"style={{color:'white',fontSize:'12px',display:'flex',width:'150px'}}>Dont have an account?</p>
      <button onClick={()=>{navigate("/SignUp")}} style={{background:'transparent',display:'flex',textAlign:'center',color:'white',border:'0px',width:'200px',fontSize:'14px',fontWeight:'400'}} 
        className="mx-auto">
     <p className="mx-auto" style={{color:'#00D4C5',display:'flex',fontWeight:'700'}}>Create account </p> 
      </button>
      </div>
  
     

     
    </div>
   
 
    </div>
    </div>
    </div>
  );
}
}