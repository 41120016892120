
import React, { useState, useEffect } from 'react';
import { Image } from 'react-bootstrap';
import { AiOutlineArrowDown } from 'react-icons/ai';






export default function HowItWorks(){



    return(
        <div className="my-4"style={{maxWidth:'1320px',display:'block',margin:'auto',padding:'5px',background:'white'}}>

        <ol className="my-4 mx-auto"  style={{width:'90%',height:'100%',justifyContent:'space-between',display:'block',margin:'auto',padding:'5px',}}>
            <li className="my-2 " style={{height:'fit-content'}}>
            <strong>   Sign Up – name, user name, email address, password  </strong> 
            </li>
            <li className="my-4 " >
            <strong>  Make sure to VERIFY your email, and be sure to open a separate tab and not close this page </strong> <br />
            <Image classsName="my-2"src={require("../assets/steps/step1.png")} style={{maxWidth:'500px'}}/>
            </li>
            <li className="my-2 " >
            <strong>  Create your profile – click on your profile avatar in the upper right corner. </strong>  <br />
            - Select Settings to change: your banner, upload your profile picture and in your Bio write
something cool about yourself
<br />
            <Image classsName="my-2"src={require("../assets/steps/step3.png")} style={{maxWidth:'500px'}}/>
            <br />
            <Image classsName="my-4"src={require("../assets/steps/step35.png")} style={{maxWidth:'500px'}}/>
            </li>
            <li className="my-2 " style={{height:'fit-content'}}>
       <strong>Explore the Clubs – Join the ones you like by clicking the Join button  </strong>      <br />
            - Explore the clubs upcoming NFT drops  <br />
            - Review the Drops Utility (what you get if you buy one) <br />
            - Explore Live Drops and purchase an NFT if you would like <br />
            - Connect with other members of the club <br />
            - Trade NFTs with other group members. <br />
            <br />
            <Image classsName="my-2"src={require("../assets/steps/step4.png")} style={{maxWidth:'500px'}}/>
            </li>
            <li className="my-4 " style={{height:'fit-content'}}>
            <strong>   If you are a Brand or Creator that has their own club:  </strong> <br/>
            - click your profile in upper right corner  <br />
            - Click Club settings <br />
            - Upload your Club Banner <br />
            - Write a cool bio for your club <br />
            - Post often in the feed to let users know of upcoming drops and events <br />
            </li>
        </ol>
        </div>
    )
}