import React, {  useEffect, useState,} from 'react';

import { DataStore } from '@aws-amplify/datastore';
import { User ,ClubUser,Club} from '../../models';
import { createAvatar } from '@dicebear/core';
import { botttsNeutral } from '@dicebear/collection';
import awsconfig from '../../aws-exports';

import axios from 'axios';

import { Amplify, Auth, Hub ,API,Logger,Storage} from "aws-amplify";
import UserImages from './UserImages';
import AvatarGenerator from './AvatarGenerator';

Storage.configure({ track: true, level: "public" });

export default   function AvatarBannerGenerator(userID){
          
    Storage.get("tempBanner.png", {
      
        download:true
    }).then((tempBanner)=>{
      console.log(tempBanner)
      Storage.put("Users/"+userID+"/Banner.png", tempBanner.Body, {
        
        contentType: tempBanner.Body.type
      })
        .then((result) =>{
          console.log("Banner ",result.key)
          AvatarGenerator(userID)
          
        })
        .catch(err => console.log(err));
    }).catch((err)=>{console.log(err)})
           
                
         

          
  
}
