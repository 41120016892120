import React, {useState, useEffect} from 'react';
import { DataStore } from '@aws-amplify/datastore';
import { User } from '../models';
import { Amplify, Auth, Storage } from "aws-amplify";
import awsconfig from '../aws-exports';
import {
  CDBListGroup,
  CDBListGroupItem,
  CDBContainer,
  CDBBtn,
  CDBCard,
  CDBCardBody,
  CDBInput,
} from "cdbreact";
import { Image } from 'react-bootstrap';
import '../styles/fonts/fonts.css'
import camera from '../assets/icons/Outline/white/camera.svg'
import Close from '../assets/icons/Outline/blue/Close.svg'
import feed from '../assets/icons/Outline/white/feed.svg'
import SplashScreen from '../SplashScreen';
Amplify.configure({...awsconfig, ssr: true});
Storage.configure({ track: true, level: "public" });


export default function UserSettings(props){
const [CurrentUser, setUser] = useState();
const[settingOptions,setOptions]=useState("main");

const [loading,setLoading] =useState(true)
const [Banner,setBanner] =useState()
const [NewBanner,setNewBanner] =useState()
const [ProfilePic,setProfilePic] =useState()
const [Bio,setBio] =useState('')
const [NewBio,setNewBio] =useState('')
const [newPost, setNewPost] = useState('');
const [newTitle, setNewTitle] = useState('');
const [members, getMembers]=useState([])
const [getPost, setPostImage] = useState(null);
const [isFilePicked2, setIsFilePicked2] = useState(false);
const [uploadType,setType] = useState('text')
useEffect(()=>{
    setLoading(true)

    getUserData()
  
},[])

async function changeView(selectedView){
    console.log(selectedView)
    setOptions(selectedView)
}

function getUserData(){
    Auth.currentAuthenticatedUser({
        bypassCache: true  
        // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
      }).then(user => {
        setUser(user)
        console.log(user)
        Storage.get("Users/"+user.username+"/Pro.png", {
            level: "public",
            
          }).then((r) => {
           
              setProfilePic(r)
        
            
          }).catch(err=>console.log(err))
          Storage.get("Users/"+user.username+"/Banner.png", {
            level: "public",
            
          }).then((r) => {
        
                setBanner(r)

            
          }).catch(err=>console.log(err))
            DataStore.query(User, u=> u.UserID("eq",user.username)).then((u)=>{
                setBio(u[0].bio)
             
            }).catch(err=> console.log(err))
            setLoading(false)
      }).catch((err)=>{ 
        setLoading(false)
        console.log(err)})
  
}
if(loading){
    return(
      <div style={{display:'flex',width:'90%', maxWidth:window.innerWidth,overflowX:'hidden'}} className="mx-auto">
<SplashScreen  />
      </div>
        
    )
}
const styles={
    buttonsmall: {
        "width": "109px",
        "height": "46px",
    
        "display": "flex",
        "flexDirection": "row",
        "justifyContent": "flex-start",
        "alignItems": "flex-start",
        "gap": "8px",
        "padding": "6px 16px",
        "borderRadius": "8px",
        marginRight:'5px',
        backgroundColor: "#0e16d9"
      },
      leave: {
    
        fontFamily: "Poppins-Regular",
        fontSize: "18px",
        fontWeight: "500",
        fontStretch: "normal",
        fontStyle: "normal",
        marginRight:'10px',
        letterSpacing: "normal",
        textAlign: "center",
        color: "#fff"
      },
}

if(!loading ){
    const viewer = ()=>{


  

        
            if( settingOptions == "main" ||settingOptions == "Banner"){
                let fileInput = React.createRef();
            
            const onOpenFileDialog = () => {
              fileInput.current.click();
            };
            
            
            const onProcessFile = e => {
              e.preventDefault();
              let reader = new FileReader();
              let file = e.target.files[0];
              console.log(file)
              try {
                reader.readAsDataURL(file);
              } catch (err) {
                console.log(err);
              }
              reader.onloadend = () => {
                setBanner(reader.result);
               
              };
              Storage.put("Users/"+CurrentUser.username +"/Banner.png", file, {
                contentType:  e.target.files[0].type
              }).then(result => {
                console.log(result)
              
                setOptions("main")
                alert("Banner Picture updated")
                setLoading(false)
              })
                .catch(err => {
                    setLoading(false)
                });
            
                
            };
            
                return(
                  <div style={{width:'898px',display:'block',padding:'16px 32px 32px'}}>
                  <CDBContainer style={{margin:'auto',maxWidth:'100%',borderRadius:'12px',border:'0px', boxShadow:'4px 4px 12px 0 rgba(0, 0, 0, 0.15)'}}>
                     <CDBCard style={{ width: '100%' ,maxWidth:'830px',borderRadius:'12px',border:'0px'}}>
                  <CDBCardBody className=" p-2" >
                    <h1 className="HeadingH6Semibold" style={{color:'#0e16d9'}}>Change Banner</h1>
                  <Image src={Banner} className="rounded border" width='100%' style={{maxWidth:'820px',}} />
             <div style={{display:'flex', marginTop:'16px'}}>
             <label   onChange={onProcessFile}  style={{...styles.buttonsmall, width:'fit-content'}}>
                    <input
                            type="file"
                          
                            ref={fileInput}
                            style={{display:'none'}}
                          />
                            <p className=" ms-2 my-auto" style={{...styles.leave,display:'flex'}}>
               <Image src={camera} className=" my-auto me-2" />
          change banner
            </p>
                    </label>
                      
                      
              
            
            
                      <CDBBtn  outline className="btn-block  ms-2" style={{width:'119px',background:'transparent',border:'1px solid #0e16d9', borderRadius:'8px'}}onClick={()=>{changeView("main")}}>
                       <p className="ParagraphP2Regular my-auto" style={{color:'#0e16d9'}}>
                        <Image src={Close} className="me-2"/>
                       cancel
                        </p> 
                      </CDBBtn>
             </div>
                
                   
                
            
            
            
                    </CDBCardBody>
                  </CDBCard>
                
                  </CDBContainer>
                    </div>
                )
            }
            

            if(settingOptions == "FeedBanner"){
    
                let fileInput2 = React.createRef();
            
                const onOpenFileDialog = () => {
                  fileInput2.current.click();
                };
                
                
                const onProcessFile2 = e => {
                  e.preventDefault();
                  let reader = new FileReader();
                  let file = e.target.files[0];
                  try {
                    reader.readAsDataURL(file);
                  } catch (err) {
                    console.log(err);
                  }
                  reader.onloadend = () => {
                    setProfilePic(reader.result);
                   
                  };
                  Storage.put("Users/"+CurrentUser.username +"/Pro.png", file, {
                    contentType:  e.target.files[0].type
                  }).then(result => {
                    setOptions("main")
                    alert("Profile Picture updated")
                    setLoading(false)
                  })
                    .catch(err => {
                        setLoading(false)
                    });
                
                    
                };
                
                    return(
                      <div style={{width:'898px',display:'block',padding:'16px 32px 32px'}}>
                      <CDBContainer style={{margin:'auto',maxWidth:'100%',borderRadius:'12px',border:'0px', boxShadow:'4px 4px 12px 0 rgba(0, 0, 0, 0.15)'}}>
                         <CDBCard style={{ width: '100%' ,maxWidth:'830px',borderRadius:'12px',border:'0px'}}>
                  <CDBCardBody className=" p-2" >
                    <h1 className="HeadingH6Semibold" style={{color:'#0e16d9'}}>Change Profile Picture</h1>
                  <Image src={ProfilePic} className="rounded border" height='100%' style={{maxWidth:'400px'}} />
             <div style={{display:'flex', marginTop:'16px'}}>
             <label   onChange={onProcessFile2}  style={{...styles.buttonsmall, width:'fit-content'}}>
                    <input
                            type="file"
                          
                            ref={fileInput2}
                            style={{display:'none'}}
                          />
                            <p className=" ms-2 my-auto" style={{...styles.leave,display:'flex'}}>
               <Image src={camera} className=" my-auto me-2" />
          change Pro pic
            </p>
                    </label>
                      
                      
              
            
            
                      <CDBBtn  outline className="btn-block  ms-2" style={{width:'119px',background:'transparent',border:'1px solid #0e16d9', borderRadius:'8px'}}onClick={()=>{changeView("main")}}>
                       <p className="ParagraphP2Regular my-auto" style={{color:'#0e16d9'}}>
                        <Image src={Close} className="me-2"/>
                       cancel
                        </p> 
                      </CDBBtn>
             </div>
                
                   
                
            
            
            
                    </CDBCardBody>
                  </CDBCard>
                
                  </CDBContainer>
                    </div>
                    )
            }


            if(settingOptions == "Bio"){
    
                function onChange(e){
                    setNewBio(e.target.value )
                }
                const updateBio  = async ()=>{
                    
                    if(NewBio !== ''){
                        setLoading(true)
                    const c = await  DataStore.query(User, c=> c.UserID("eq",CurrentUser.username))
                    Auth.updateUserAttributes(CurrentUser, {
                        'custom:bio': NewBio.toString()
                      }
                    
                     
                      );
                       
                       await DataStore.save(
                            User.copyOf(c[0], updated => {
                              updated.bio= NewBio.toString()
                            })
                          );
            setOptions("main")
            setBio(NewBio)
            setNewBio('')
            alert("bio updated")
            setLoading(false)
                        
                  
                    
                    }else{
                        alert('Bio cannot be empty')
                    }
                  }
                return(
                  <div style={{width:"664px",padding:'16px 32px 32px', boxShadow:'4px 4px 12px 0 rgba(0, 0, 0, 0.15)'}}>
                     <CDBContainer style={{width:"100%",display:'block',}}>
                    

                  <CDBCard style={{ width: '100%',display:'block',border:'0px' }}>
                  <div className="mx-auto"style={{width:'600px',textAlign:'left'}}>
                          <p className='HeadingH6Semibold'>
                             Update BIO:
                          </p>
                      </div>
                      <CDBInput type="textarea"  hint="Create New Bio" 
                 placeholder={"Current Bio : \n" + ( Bio)}
                 value={NewBio} 
                 size="lg" 
                 onChange={onChange}
                className="ParagrapghP2Semibold"
                style={{border:'0px', backgroundColor:'#f7fbff',borderRadius:'12px',}}
               />
             



    
            <div className="my-auto" style={{width:'50%',padding:'4px', display:'flex'}}>
            <CDBBtn style={{...styles.buttonsmall, width:'fit-content',height:'40px',fontWeight:'500'}} onClick={updateBio}>
            <Image src={feed} className="mx-auto" /> <p className="my-auto ParagraphP2Regular">update</p>
          </CDBBtn>
         
            </div>
                  </CDBCard>
                </CDBContainer>
                    </div>
                )
            }
            
    }
    return(
        <div style={{height:window.innerHeight,maxWidth:'1320px',width:"100%"}}>

            <div style={{display:'flex',margin:"auto",width:'100%',maxWidth:'1320px',flexDirection:'row'}}>
            <CDBContainer style={{marginTop:'30px',width:'325px'}}>
            <CDBListGroup style={{ width: "100%",justifyContent:'space-around' }}>

            <CDBBtn onClick={()=>{changeView("Banner")}} style={{background:'transparent',border:settingOptions== "Banner"? "1px solid #fff":'0px'}}>
              <CDBListGroupItem style={{background:'transparent',color:"#fff",border:'0px',fontSize:'16px'}}>
             
                Change Banner
              
                </CDBListGroupItem>
           
                </CDBBtn>

                <CDBBtn onClick={()=>{changeView("FeedBanner")}} style={{background:'transparent',border:settingOptions== "FeedBanner"? "1px solid #fff":'0px'}}>
              <CDBListGroupItem style={{background:'transparent',color:"#fff",border:'0px',fontSize:'16px'}}>
                Change Profile Picture
                </CDBListGroupItem>
                </CDBBtn>

                <CDBBtn onClick={()=>{changeView("Bio")}} style={{background:'transparent',border:settingOptions== "Bio"? "1px solid #fff":'0px'}}>
              <CDBListGroupItem style={{background:'transparent',color:"#fff",border:'0px',fontSize:'16px'}}>
                Update Bio
                </CDBListGroupItem>
                </CDBBtn>

          


            </CDBListGroup>
            
    </CDBContainer>
<div style={{display:'block',maxHeight:'431px',height:'431px',width:'890px',margin:'auto'}}>
{viewer()}
</div>
 
            </div>
 
        </div>
    )

}
}