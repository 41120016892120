import { loadingBar } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import Loader2 from './Loader2';
import styles from "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Sidebar,Search,
  Conversation,
  ConversationList,
  Avatar,
  Message,
  MessageInput,
  ConversationHeader
} from "@chatscope/chat-ui-kit-react";
const ChatRoom = () =>{

    const [loading, setLoading] = useState(true)

    useEffect(()=>{
        setLoading(true)

        setLoading(false)
        },[]  )

            const styles= {
                main:{
                    height:window.innerHeight,
                    width: window.innerWidth,
                    maxWidth:'1320px',
                    display:'block',
                    margin:'auto'
                },
                row:{
                    display:'flex',
                    width:'1320px',
                    flexDirection:'row'
                }
                

            }
        if(loading){
            return(
                <div>
                    <Loader2 />
                    </div>
            )
        }

        if(!loading){
            return(
                <div style={styles.main}>
                    <div className="mx-auto p-2  "style={styles.row}>
                        <div className="me-2 "style={{display:"flex",width:'300px',height:'50px',textAlign:'center', border:'1px solid #EBEBEB',borderRadius:'10px'}}>

                            <p className='mx-auto my-auto' style={{display:'flex',}}>Club Channels</p>
                        </div>
                        <div className="mx-auto "style={{display:"flex",width:'1000px',height:'50px',textAlign:'center', }}>
                                <p className='mx-auto my-auto ParagraphP1Semibold' style={{display:'flex',}}></p>

                        </div>

                    </div>


                    <div className="mx-auto p-2 my-2"style={styles.row}>
                       
                        <div className="mx-auto "style={{display:"block",width:'1300px',height:'500px',border:'1px solid #EBEBEB',boxShadow: "4px 4px 4px 4px #EBEBEB" ,}}>
                        
                        <MainContainer className="mx-auto p-2" responsive>
                        <Sidebar position="left" scrollable={false}>
                  <Search placeholder="Search..." />
                  <ConversationList>                                                     
                    <Conversation name="Fortnite" lastSenderName="vinay" info="Me too!">
                      <Avatar  src={require("../assets/images/fortnite-icon.png")}name="Lilly" status="available" />
                    </Conversation>
                    
                   
                    
                                                            
                  </ConversationList>
                </Sidebar>
    <ChatContainer>
    <ConversationHeader>
          <Avatar src={require("../assets/images/fortnite-icon.png")} name="Public Chat" />
          <ConversationHeader.Content userName="Public Chat" info="Active" />
                  
        </ConversationHeader>
      <MessageList>
        <Message
          model={{
            message: "Hey Everyone",
            sentTime: "just now",
            sender: "Jack",
          }}
          
        ><Message.Footer sender="Jack" sentTime="3 mins ago" />
            </Message>
            <Message
          model={{
            message: "Anyone Trying to play some squads, need 3!",
            sentTime: "1 min ago",
            sender: "Vinay",
          }}
          
        ><Message.Footer sender="Vinay" sentTime="1 min ago" />
            </Message>
            <Message
          model={{
            message: "Yup im on now, add me on Epic - Alex123",
            sentTime: "just now",
            sender: "Alex",
          }}
          
        ><Message.Footer sender="Alex" sentTime="just now" />
            </Message>

            <Message model={{
  message: "Vinay, add me also - reaper222",
  sentTime: "just now",
  sender: "Akane",
  direction: "outgoing",
  position: "normal"
}} />
 <Message
          model={{
            message: "ok will do!",
            sentTime: "just now",
            sender: "Vinay",
          }}
          
        ><Message.Footer sender="Vinay" sentTime="just now" />
            </Message>
      </MessageList>
      <MessageInput placeholder="Type message here" />
    </ChatContainer>
  </MainContainer>
                        </div>

                    </div>
                    </div>
            )
        }
  

}


export default ChatRoom;