import React, { useEffect, useState } from 'react';
import useLocalStorage from 'react-use-localstorage';
import SplashScreen from '../SplashScreen';
import { StreamChat } from 'stream-chat';
import {
    Chat,
    Channel,
    ChannelHeader,
    ChannelList,
    MessageList,
    MessageInput,
    Thread,
    Window,
    Avatar,
    VirtualizedMessageList,
    DateSeparator,
    Streami18n,
    useChannelStateContext
  } from 'stream-chat-react';
import 'stream-chat-react/dist/css/v2/index.css';
import { CDBBtn } from 'cdbreact';
import awsconfig from '../aws-exports';
import {Route, Link, Routes, useNavigate} from 'react-router-dom';
import { Amplify, Auth, Hub, API, Logger, Storage } from "aws-amplify";

import { DataStore } from '@aws-amplify/datastore';
import { User, Club, ClubUser,ClubPost,NFTCollection } from '../models';

Storage.configure({ track: true, level: "public" });
Amplify.configure({ ...awsconfig, ssr: true });

const client = StreamChat.getInstance( "huhccc7rq7f3");
const filters = { type: 'messaging' };
const options = { state: true, presence: true, limit: 10 };
const sort = { last_message_at: -1 };
const ChatChannel = (props) =>{

    const [loading, setLoading] = useState(true)
 
    const [CurrentUser,setUser] = useState(null)
    const [Clubinfo,setClub]=useState()
    const [connectedUser,setConnection]=useState(false)
    const [ChannelImage,setChannelImage]=useState(null)
    const navigate = useNavigate();

    useEffect(()=>{
      
    
        setLoading(true)
     
            setUser(props.props)
            setClub(props.clubInfo)
            getChannelState()
            console.log(props)
            //connectUsers()
          
       
        setLoading(false)
        },[]  )

        
        const channel =props.chat.channel('messaging', props.clubInfo.name.replaceAll(' ','')+'-public', {
            name: props.clubInfo.name,
        })
        console.log(channel)
       const getChannelState = async()=>{

      setLoading(true)
     

        const result = await channel.addMembers([CurrentUser ? CurrentUser.attributes.preferred_username:props.props.attributes.preferred_username]);
        console.log(result.channel.image)
        setChannelImage(result.channel.image)
        await channel.watch({ presence: true });
     
        setLoading(false)
   
          
         
        }

        const goBack = () => {
            navigate(-1);
        }
        /*
            const connectUsers=  ()=>{
                setLoading(true)
                if(CurrentUser || props.props){
                 
                        
                        client.connectUser(
                            {
                                id: CurrentUser ? CurrentUser.attributes.preferred_username :props.props.attributes.preferred_username,
                                name:CurrentUser ?  CurrentUser.attributes.name :props.props.attributes.preferred_username,
                               
                            },
                            client.devToken(CurrentUser ? CurrentUser.attributes.preferred_username :props.props.attributes.preferred_username),
                        ).then((y)=>{
                            console.log(y) 
                            setConnection(true).then(()=>{
                                console.log(channel)
                           
                                getChannelState()
                            })
                          
                            
                          
                           
                        }).catch((err)=>{
                            setLoading(false)
                        })
                    
                  
                }else{
                    
                    setLoading(false)
                }
           
            }
            **/
        const styles= {
            main:{
                height:window.innerHeight,
                minHeight:window.innerHeight,
                width: window.innerWidth,
                maxWidth:'1320px',
                display:'block',
                margin:'auto'
            },
            row:{
                display:'flex',
                width:'1320px',
                flexDirection:'row'
            }
            

        }

        if(loading){
                return(
                    <div style={styles.main}>
                        <SplashScreen />
                    </div>
                )
        }


        if(!loading &&  channel != ''){
            const i18nInstance = new Streami18n();
            const date = new Date();
        
            return (
                    <div className="mx-auto my-2" 
                    style={{minHeight:'fit-content',height:window.innerHeight,maxHeight:window.innerHeight,overflowY:'scroll',overflowX:'hidden',maxWidth:'1300px',borderRadius:'10px'}}>
                        <CDBBtn onClick={()=>{goBack()}} className="ms-2 my-2"color="danger" 
                        style={{display:'flex',position:'sticky',width:'120px',height:'40px',border:'0px',color:'white'}}>
                                Exit Chat
                        </CDBBtn>
                  <div style={{borderRadius:'10px',height:'70%',width:'100%',minHeight:'fit-content',overflow:'hidden'}}>
                  <Chat  client={client} i18nInstance={i18nInstance} >
 
 <Channel channel={channel} >
   <Window hideOnThread={true}>
     
     <ChannelHeader image={ChannelImage}/>
     <div className='my-2 pe-4' style={{maxHeight:window.innerHeight,height:'70%',}}>
         
     <VirtualizedMessageList hasMore={true} hideDeletedMessages={true} messageLimit={10}/>
    
    
         </div>
         <div style={{position:'sticky',display:'block'}}>
     <MessageInput  focus={true} />
     </div>
   </Window>
   
   <Thread />
  
   
 </Channel>


</Chat>
                  </div>

</div>   
            )
        }



}

export default ChatChannel;