

import React, { useState, useEffect } from 'react';
import { Auth, Storage } from 'aws-amplify';
import { AmplifyS3ImagePicker } from '@aws-amplify/ui-react/legacy';
import { BrowserRouter as Router, Route, Routes, useNavigate, Redirect, Link, Navigate,NavLink } from 'react-router-dom';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
import { Network, Alchemy } from "alchemy-sdk";
import { FiStar, FiHeart, FiShare, FiGlobe ,FiMenu, FiSettings} from 'react-icons/fi';
import 'reactjs-popup/dist/index.css';
import { DataStore } from '@aws-amplify/datastore';
import '../styles/fonts/fonts.css'
import { Web3Modal,Web3Button, useAccount  } from '@web3modal/react'
import { User ,ClubUser,Club} from '../models';
import { CDBBtnGrp,CDBBtn, CDBCard,CDBCardBody,CDBContainer } from 'cdbreact';
import { AiFillCheckSquare, AiFillWallet } from 'react-icons/ai';
import {
  View,
  Heading,
  Flex,
  Text,
  Card,
  Grid
} from '@aws-amplify/ui-react';
import Loader2 from '../screens/Loader2';
import metamask from "../assets/icons/Colored/metamask.svg"
import clubsLogo from "../assets/icons/Colored/clubsLogo.svg"
import collectibles from "../assets/icons/Colored/collectibles.svg"
import collection from "../assets/icons/Colored/collection.svg"
import NoCollectibles_placeholder from "../assets/icons/Colored/NoCollectibles_placeholder.svg"
import noClubs_placeholder from "../assets/icons/Colored/noClubs_placeholder.svg"
import { ConnectWallet,useAddress } from '@thirdweb-dev/react';
Storage.configure({ track: true, level: "public" });
const settings = {
  apiKey: "judyKIq12rtyO-zasR-253fFj13tXf6S",
    network: Network.MATIC_MAINNET,
};
const alchemy = new Alchemy(settings);

const styles = {
  main: {
    width: window.innerWidth,
    maxWidth:'430px',
    overflow: 'hidden',
  
    margin: 'auto'
  }
}
 function MobileMyaccount(props) {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [username, setUsername] = useState('');
  const [userFullname, setUserFullname] = useState('');
  const [CurrentUser, setUser] = useState();
  const [wallet, setWallet] = useState(null);
  const [Userbio, setBio] = useState(null);
  const [UserImage, setImage] = useState(null);
  const [UserNFTS, getNFTS] = useState(null);
  const [nftCount, MaxNFT] = useState(null);
  const [UserBanner, setBannerImage] = useState(null);
  const [UserMainNFTS, getMainNFTS] = useState(null);
 const [admin, checkAdmin]= useState(false);
const[walletConnected,walletConnectButton] = useState(false)
const [windowSize, setWindowSize] = useState(getWindowSize());
const [clubInfoList, getjoinedClubs]=useState([])
const [checkedWallet, nftBool]=useState(false)
const address = useAddress()
const tester = (w) => {
  //  const contractAddress = "0x818830D10979A21a7C061359AF7257C378E2a5E0";

  if(!checkedWallet){
    setLoading(true)
    alchemy.nft.getNftsForOwner(w).then((NftOwned)=>{
      let temp = NftOwned.ownedNfts.filter((n) => n.rawMetadata.image.indexOf("chieve22") !=  -1)
      console.log(w)

     
      if(temp.length ==0 ){
        getNFTS(null)
        
      }else{
        console.log(temp)
        getNFTS(temp)
      
      }
      nftBool(true)
      setLoading(false)
    }).catch(err=>{
      console.log(err)
      nftBool(true)
      setLoading(false)
    })
  }






  }
useEffect(()=>{
console.log(props)
  try {
    setError(null);
    
    setLoading(true);
    Auth.currentAuthenticatedUser({
      bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    }).then(user => {
      setUsername(user.attributes.preferred_username);
      setUserFullname(user.attributes.name);
      setUser(user)
      if (user) {
        DataStore.query(User, u => u.UserID("eq", user.username)).then((info)=>{
          if(info[0].isAdmin){
            checkAdmin(true)
          }
          
        }).catch((err)=> console.log(err))


        DataStore.query(ClubUser).then((info)=>{
          let tempClubs=[]
        info.filter((u)=> u.user.UserID== user.username).forEach((clubGet)=>{
          Storage.get("Clubs/"+clubGet.club.ClubStorageID+"/ClubLogo.png")
          .then((bannerLink)=>{
              let urlCLub = clubGet.club.name.replace(/ /g, '');
              
              
              tempClubs.push({
                  "img":bannerLink,
                  "clubName":clubGet.club.name,
                  "clubURL":urlCLub,
              })
              getjoinedClubs(tempClubs)
              console.log(tempClubs)
          }).catch(err=>console.log(err))
        })
          
        }).catch((err)=> console.log(err))


        DataStore.query(User, u => u.UserID("eq", user.username)).then((userWallet)=>{
          setWallet(userWallet[0].Wallet);
        //  tester(address)
        
        })
        Storage.get("Users/"+user.username+"/Pro.png").then((r) => {
          if (r) {
            setImage(r)
         
           
          }
        });

        Storage.get("Users/"+user.username+"/Banner.png", {
          level: "public",
          
        }).then((r) => {
          if (r) {
            setBannerImage(r)

          }
        }).catch((error)=>console.log('error ', error));

      }
      if (user.attributes["custom:bio"]) {
        setBio(user.attributes["custom:bio"]);
      }
      // TBD
    }).catch(err => setError(err));


  }
  catch (e) {
    setError(e);
  }
  finally {
    setLoading(false);
  }

},[])


useEffect(() => {
  function handleWindowResize() {
      setWindowSize(getWindowSize());
     
    }
    
  window.addEventListener('resize', handleWindowResize);
  return () => {
      window.removeEventListener('resize', handleWindowResize);
    
    };
}, []);
const getWallet = async () => {
  const { ethereum } = window;
  if (!ethereum) {
    alert("Please install MetaMask")
  } else {
    try {
      const accounts = await ethereum.request({ method: 'eth_requestAccounts' });
      console.log(accounts)
      Auth.updateUserAttributes(CurrentUser, {
        'custom:wallet': accounts[0]
      });
      walletConnectButton(true)
      const newWallet = await DataStore.query(User, u => u.UserID("eq", CurrentUser.username))
      await DataStore.save(
        User.copyOf(newWallet[0], updated => {
          updated.Wallet= accounts[0]
        }));
        setWallet(accounts[0])
       
        
         
      
         
    } catch (e) {

    }
  }
}

if(!loading){
 
  if(address ){
console.log(address)
tester(address)
  
    }
function nftGallery(nft) {
  return(
  <CDBCard style={{display:'block', width: "300px",borderRadius:'8px',overFlow:'hidden'}}>
    <Image src={nft.rawMetadata.image} className="img-fluid mx-auto " style={{maxWidth:'300px',  display:'flex'}}/>
    <CDBCardBody className="mx-auto " >
        <p style={{color:'white'}}>{nft.title}</p>
    </CDBCardBody>
  </CDBCard>
  
    
  )

 
}
  return (
   <div style={styles.main}>
     <CDBContainer style={{ background:'transparent',width: window.innerWidth,margin:'auto',border:'0px' }}>
          <CDBCard style={{ background:'transparent',width: '100%',maxWidth:'414px',margin:'auto',border:'0px' }}>
            <Image
              className="img-fluid"
              src={UserBanner}
              style={{margin:'auto'}}
            />
          
            <Image style={{marginTop:"-3rem", maxHeight:"100px",maxWidth:"100px",borderRadius:'100%',display:'flex'}} 
            className=" mx-auto" width="100%" src={UserImage} fluid/>
            <CDBCardBody className="d-flex flex-column align-items-center mb-4">
              <p style={{textAlign:'center',width:'90%',fontWeight:'bold',fontSize:'16px',color:"white"}}>{CurrentUser? CurrentUser.attributes.name : props.props.attributes.name}</p>
              <p style={{textAlign:'center',width:'90%',fontSize:'14px',color:"white"}}>{Userbio}</p>
           
              <CDBBtnGrp style={{margin:'10px',display:'block',justifyContent:'space-between'}}> 
             
              <CDBBtn size="large" className="mx-auto" style={{margin:'10px',background:'transparent',border:'0px',borderRadius:'20px',display:'flex'}}>
             <NavLink  to="/AccountSettings"  style={{textDecoration:'none',color:'white'}}>
          
            settings
          <FiSettings size='1.5em' style={{color:'white',margin:'5px'}}/>

  </NavLink >
  </CDBBtn>

              <ConnectWallet  />
              
              </CDBBtnGrp>
         
            </CDBCardBody>
          </CDBCard>
    </CDBContainer>
   
   <div style={{margin:'auto',display:'block',maxWidth:'414px',justifyContent:'space-around'}}>
   <div className="my-4 ms-4" style={{width:'70%',}} >
   <h5 className="HeadingH5Semibold" style={{color:"white"}}>
                Clubs
                </h5>
  </div>
    <div className="my-2 mx-auto" style={{display:'flex', maxWidth:'90%',overflowX:'scroll',flexDirection:'row'}}>
    {clubInfoList.length != 0 ?

clubInfoList.map((cI)=>{
    return(
      <NavLink className='my-auto me-2'   to={"/"+cI.clubURL}  style={{textDecoration:'none'}}>
      <div style={{display:'block',overflow:'hidden',maxWidth:'100px',width:'100px',height:'100px'}}>
        <Image 
        className='img-fluid my-auto mx-auto'
      
      
  src={cI.img} style={{width:'100%',aspectRatio:'3/2',objectFit:'contain',borderRadius:'8px'}} />
        </div>
        </NavLink>
    )
}
)
:
<div style={{display:'block',overflow:'hidden'}}>
       
<div style={{height:'200px',display:'flex',width:'100%'}}>
<Image src={noClubs_placeholder} height="100%" className="img-fluid mx-auto"style={{maxWidth: "300px",display:'flex'}} />
</div>
     
     <p className='my-auto' style={{margin:'auto',maxWidth:'330px'}}>
    <p className="ParagraphP1Medium " style={{color:'#2dccd3' , margin:'auto',maxWidth:'330px'}}>
    You haven't joined any clubs yet!
        </p> 
 
     
     </p>

     
</div>
}
    </div>
    <div style={{margin:'auto',height:'600px',display:'block',maxWidth:'414px',justifyContent:'space-around'}}>
 
 

 <div style={{   display:'block',
width:'90%',
maxWidth:'414px',
margin:'auto',
}}>
<h5 className="HeadingH5Semibold mt-4" style={{color:"white"}}>
   Loot
       </h5>
      
     {
      UserNFTS ?
      <>
     
       {UserNFTS.map((k)=>{
        console.log(k)
        return(
          <div className="mx-auto my-2" >
          {nftGallery(k)}
            </div>
       
        )
      })}
      
      </>
     
      :
      <>
         <div style={{height:'400px',display:'flex',width:'100%'}}>
  <Image src={NoCollectibles_placeholder} height="230px"style={{maxWidth: "240px",margin:'auto'}} />
  </div>
       
       <p className='ParagraphP1Medium my-auto mx-auto' style={{color:'#2dccd3' , margin:'auto',maxWidth:'330px'}}>
       No loot just yet 
       </p>
      </>
     }
      
       
       


       
</div>

</div>
   </div>


   </div>
  );
}

  if(loading){
    return(

      <div style={{height:window.innerHeight,width:window.innerWidth}}>

        <Image src={require('../assets/images/loadingGif2.gif')} style={{margin:'auto',height:'24px',width:'24px'}}/>
      </div>
    )
  }
}

function getWindowSize() {
  const { innerWidth, innerHeight } = window;

  return { innerWidth, innerHeight };
}

export default MobileMyaccount;