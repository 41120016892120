import { useState, useEffect } from 'react';
import awsconfig from '../aws-exports';
import '../styles/setUp.css';
import { Amplify, Auth, Storage } from "aws-amplify";
import { useNavigate, NavLink } from 'react-router-dom';
import { DataStore } from '@aws-amplify/datastore';
import { User ,Errors} from '../models';
import PasswordChecklist from "react-password-checklist"
import { AiOutlineCheckCircle } from 'react-icons/ai';
import { CDBBtn } from 'cdbreact';
import AvatarGenerator from './components/AvatarGenerator';
Amplify.configure(awsconfig);
export default function SignUp(screenSize) {
  const navigate = useNavigate();
  const [formState, setFormState] = useState("signUp");

  const [formInputState, setformInputState] = useState({ email: '', password: '', confirmPassword:'',preferred_username: '', name:'',verificationCode: '' });
  const [details,CheckSignIn] = useState({username: '', password: ''})
  const [Olddetails,returnSignIn] = useState()
  const [mouseHover,setHover] = useState(false)
  const[windowSize,setSize]= useState(screenSize.screenSize)
  useEffect(()=>{
    setSize(screenSize.screenSize)
  },[])
function onChange(e) {
  setformInputState({ ...formInputState, [e.target.name]: e.target.value })
  CheckSignIn({...details, [e.target.name]: e.target.value })
  
}
const setColor = ()=>{
  setHover(true)
}
const fixColor = ()=>{
  setHover(false)
}
function onChange2(e) {
 
returnSignIn({...Olddetails, [e.target.name]: e.target.value })
  
}


async function signUp() { 
  console.log()
  if(!(formInputState.preferred_username.indexOf(' ') >= 0)){
  if(formInputState.password === formInputState.confirmPassword){
    if(formInputState.name == ' ' || formInputState.name == '' ||formInputState.preferred_username == ' '||formInputState.preferred_username == ''){
      alert("Username and Name fields cannot be empty")
  }else{

  try {
 
    await Auth.signUp({
      username: formInputState.email,
      password: formInputState.password,
      attributes: {
        preferred_username: formInputState.preferred_username,
        name: formInputState.name,
        'custom:bio': ' '
      },
      autoSignIn: { // optional - enables auto sign in after user is confirmed
        enabled: true,
    }
    
    }
    );
 
    setFormState("confirmSignUp");
  } catch (err) { 
   DataStore.save(
      new Errors({
      "message": err.message,
      "userID": "failed to confirm",
      "userEmail": formInputState.email
    })
  ).then(()=>{
    if(err.message=="Username should be an email."){
      alert("Please enter a valid email")
    }else{
      alert(err.message );
    }
  }).catch((error)=>{
    if(err.message=="Username should be an email."){
      alert("Please enter a valid email")
    }else{
      alert(err.message );
    }
    
  })
 
  }

}

}else{
  alert('Passwords must match')
}
}else{
  alert('Username cannot contain a space')
}

}


async function confirmSignUp() {

  try {
    await Auth.confirmSignUp(formInputState.email, formInputState.verificationCode);
    
   {signIn()}
    
  } catch (err) {
     alert(err.message );
   
     setFormState("confirmSignUpFail")
     }

}



 function signIn() {
  var d = new Date();
     Auth.signIn(details.email, details.password).then(async (newUserAdded)=>{
      await DataStore.save(
        new User({
        "name": newUserAdded.attributes.name,
        "preferred_username": newUserAdded.attributes.preferred_username,
        "UserID":newUserAdded.username,
        "userEmail":newUserAdded.attributes.email,
        "bio":' ' ,
        "earned":0,
        "wins":0,
        "staked":0,
        "isAdmin":false,
        "Wallet":"",
        "lastLogin":d.toLocaleDateString() +"-"+d.getHours()+":"+ d.getMinutes()
        
       
      }));

      Storage.get("tempBanner.png", {
      
        download:true
    }).then((tempBanner)=>{
      console.log(tempBanner)
      Storage.put("Users/"+newUserAdded.username+"/Banner.png", tempBanner.Body, {
        
        contentType: tempBanner.Body.type
      })
        .then((result) =>{
          
          AvatarGenerator(newUserAdded.username)
    
        })
        .catch(err => console.log(err));
    }).catch((err)=>{console.log(err)})
    
      setFormState("accountSetUP");
     }).catch((err)=> console.log(err))
 
   
   
  
}


if (formState === "signUp") {
  return (
    <div style={{display:'block',margin:'auto',  position: 'relative',height:windowSize.innerHeight,width:"422px",padding:'8px',}}> 
    <div  className="modal-area" style={{background:'#00000040',width:'422px',margin:"auto"}}>
          <NavLink  to="/"   style={{textDecoration:'none'}}>

       <button
         
            aria-label="Close Modal"
            aria-labelledby="close-modal"
            className="_modal-close"
          
          >
            <span id="close-modal" className="_hide-visual">
              Close
            </span>
            <svg className="_modal-close-icon" viewBox="0 0 40 40">
              <path d="M 10,10 L 30,30 M 30,10 L 10,30" />
            </svg>
          </button>
          </NavLink>
   <div className="modal-body" >

   
    <div style={{width:'100%', justifyContent:'space-between',marginTop:'1px',paddingBottom:'1%'}}>
  
    <p style={{color:'white', fontFamily:'Poppins-Regular',
    fontSize:'24px',lineHeight:'36px',margin:'auto',fontWeight:'700',width:'263px'}}>Create an Account</p>
        <p style={{fontFamily: 'Poppins-Regular',color: 'white',  fontWeight: '400', fontSize: '14px',margin:'auto', width:'263px'}}>
        Please fill in your details to create an account.
          </p>
      <div className="form-group mx-auto mt-2"  style={{display: 'flex',width:'60%', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', padding: '0px', gap: '8px',}}>
       
        <input className="form-control "  
                    style={{borderBottom:'1px solid #00D4C5',borderTop:'0px',color:'white',borderRight:'0px',borderLeft:'0px',borderRadius:'0px',background:'transparent'}}

        name="name"  onChange={onChange}   placeholder="Name"/>
      </div>


      <div className="form-group mx-auto"style={{display: 'flex',width:'60%', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', padding: '0px', gap: '8px',}}>
      
        <input className="form-control" 
                    style={{borderBottom:'1px solid #00D4C5',borderTop:'0px',color:'white',borderRight:'0px',borderLeft:'0px',borderRadius:'0px',background:'transparent'}}

        name="preferred_username" onChange={onChange}   placeholder="Username"/>
      </div>



      <div className="form-group mx-auto"  style={{display: 'flex',width:'60%', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', padding: '0px', gap: '8px',}}>
      
        <input
      name="email"
      type="email"
          className="form-control "
            style={{borderBottom:'1px solid #00D4C5',
            borderTop:'0px',borderRight:'0px',borderLeft:'0px',color:'white',borderRadius:'0px',background:'transparent'}}

          onChange={onChange}
          placeholder="Email"
        />
        
      </div>



      <div className="form-group mx-auto" style={{display: 'flex',width:'60%', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', padding: '0px', gap: '8px',}}>
       
        <input
          type="password"
          className="form-control "
                      style={{borderBottom:'1px solid #00D4C5',borderTop:'0px',color:'white',borderRight:'0px',borderLeft:'0px',borderRadius:'0px',background:'transparent'}}

          name="password"
          placeholder="password"
          onChange={onChange}
        />
      </div>
      <div className="form-group mx-auto" style={{display: 'flex',width:'60%', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', padding: '0px', gap: '8px',}}>
       
        <input
          type="password"
          className="form-control"
       style={{borderBottom:'1px solid #00D4C5',borderTop:'0px',borderRight:'0px',color:'white',borderLeft:'0px',borderRadius:'0px',background:'transparent'}}

          name="confirmPassword"
          placeholder="confirm password"
          onChange={onChange}
        />
      </div>

    <PasswordChecklist
				rules={["minLength","number","capital","match"]}
				minLength={8}
				value={formInputState.password}
				valueAgain={formInputState.confirmPassword}
				onChange={(isValid) => {}}
        style={{color:'rgb(255,255,255)',marginTop:'15px', width:'75%',textAlign:'left', flexWrap:'noWrap',display:'block',padding:'5px',margin:"auto"}}
        iconSize={15}
        iconComponents={{ValidIcon: <AiOutlineCheckCircle style={{margin:'auto',color:'green',width:'1.5em',height:'24px'}}/>, 
        }}
      />
        <p className="terms"> 
        By logging in, I agree to the Chieve Terms of Service and Chieve Privacy Policy.
        </p>
<div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',}}>
  
        <CDBBtn className="form-group mx-auto" onMouseEnter={setColor} onMouseLeave={fixColor} 
        style={{background:'#00D4C5', border:'0px',borderRadius:'100px',width:'156px',height:'45px'}}  
        onClick={signUp}>
       
            Submit
       
        </CDBBtn>
   
     
</div>
     
    </div>
   
   
    </div>
    </div>
    </div>
  )
}




if (formState === "confirmSignUp") {
  return (
    <div className="my-auto" style={{height:'750px',width:'100%',}}> 
    <div  className="modal-area-confirm mx-auto my-5 "  
    style={{background:'#00000040',height:'439px',width:'422px'}}>
   
<div className="modal-body-confirm my-auto">


<div style={{width:'90%', margin:'auto',justifyContent:'space-between',paddingBottom:'1%',}}>

<h2 style={{color:'white', fontFamily:'Poppins-Regular'}}>Confirm Email</h2>
<p style={{fontFamily: 'Poppins-Regular',color: 'white', 
 fontWeight: '400', fontSize: '16px',display: 'block', }}>
  Please fill in the code that was sent to your email. <br/>
  <strong>Check Spam folder if not seen in main inbox.</strong><br />
  <strong>PLEASE DO NOT CLOSE THIS TAB </strong>
  </p>

<div className="form-group mx-auto" style={{display: 'flex',width:'60%',marginTop:'2%', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', padding: '0px', gap: '8px',}}>
  <label htmlFor="email">Verification Code</label>
  <input
name="verificationCode"
    className="form-control inputs "
    onChange={onChange}
    placeholder=""
  />
  
</div>

<div className="form-group mx-auto" style={{display: 'flex',width:'60%', flexDirection: 'column', 
justifyContent: 'center',  padding: '0px', gap: '8px',}}>
  <button className="form-control  subMitButton my-auto mx-auto" 
   style={{background:'#00D4C5', border:'0px',color:'white',borderRadius:'100px',width:'156px',height:'45px'}}   onClick={confirmSignUp}>
    Submit
  </button>
</div>
</div>


</div>
</div>
</div>
  )
}
if (formState === "confirmSignUpFail") {
  return (
    <div className="my-auto" style={{height:'750px',width:'100%',}}> 
    <div  className="modal-area-confirm mx-auto my-5 "  
    style={{background:'#00000040',height:'439px',width:'422px'}}>
   
<div className="modal-body-confirm my-auto">


<div style={{width:'90%', margin:'auto',justifyContent:'space-between',paddingBottom:'1%',}}>

<h2 style={{color:'white', fontFamily:'Poppins-Regular'}}>Confirm Email</h2>
<p style={{fontFamily: 'Poppins-Regular',color: 'white', 
 fontWeight: '400', fontSize: '16px',display: 'block', }}>
  Please fill in the code that was sent to your email. <br/>
  <strong>Check Spam folder if not seen in main inbox.</strong><br />
  <strong>PLEASE DO NOT CLOSE THIS TAB </strong>
  </p>

<div className="form-group mx-auto" style={{display: 'flex',width:'60%',marginTop:'2%', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', padding: '0px', gap: '8px',}}>
  <label htmlFor="email">Verification Code</label>
  <input
name="verificationCode"
    className="form-control inputs "
    onChange={onChange}
    placeholder=""
  />
  
</div>

<div className="form-group mx-auto" style={{display: 'flex',width:'60%', flexDirection: 'column', 
justifyContent: 'center',  padding: '0px', gap: '8px',}}>
  <button className="form-control  subMitButton my-auto mx-auto" 
   style={{background:'#00D4C5', border:'0px',color:'white',borderRadius:'100px',width:'156px',height:'45px'}}   onClick={confirmSignUp}>
    Submit
  </button>
</div>
</div>


</div>
</div>
</div>
  )
}


if (formState === "signIn") {
  return (
    <div>
      <input
        name="username"
        onChange={onChange}
      />
      <input
        name="password"
        onChange={onChange}
      />
      <button onClick={signIn}>Sign In</button>
    </div>
  )
}


if (formState === "accountSetUP") {

  return (
    <div style={{height:'750px',width:window.innerWidth,margin:'auto',marginBottom:'20px'}}>
    <div  className="modal-area" style={{background:'#00000040',height:'439px',width:'422px'}}>
   
    <div className="modal-body">
    
    
    <div className="mt-2" style={{width:'90%', margin:'auto',justifyContent:'space-between',marginTop:'1%',paddingBottom:'1%',}}>
    
    <h2 style={{color:'white', fontFamily:'Poppins-Regular'}}>You're all set!</h2>
    <p style={{fontFamily: 'Poppins-Regular',color: 'white',  fontWeight: '400', fontSize: '18px',display: 'flex', }}>
      Click below to Enter web3.
      </p>
    
 
    
    <div className="form-group" style={{display: 'flex',width:'60%', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', padding: '0px', gap: '8px',}}>
    <NavLink  to="/"   style={{textDecoration:'none'}}>
      <button className="form-control  subMitButton" style={{background:'white', border:'0px',borderRadius:'8px',width:'160px',height:'36px',color:'#2942f4',fontWeight:'bold',fontSize:'14px'}}  >
        Continue
      </button>
      </NavLink>
    </div>
    </div>
 
    
    </div>
    </div>
    </div>
  )
}



}