import React, {  useEffect, useState,} from 'react';

import { DataStore } from '@aws-amplify/datastore';
import { User, Club, ClubUser,ClubPost,NFTCollection,ReportBug } from '../../models';

import {ContactForm } from '../../ui-components';
export default function ContactChieve (){

return (
    <div className=" mx-auto my-4" style={{display:'block',height:window.innerHeight, width:window.innerWidth}}>
          <div className="mx-auto my-4  pt-4 w-75" style={{display:'block',height:"auto", background:'white',borderRadius:'20px'}}>
            
          <ContactForm className="w-100 mx-auto my-auto"  />
          </div>

    </div>
    
)

}