import React, { useEffect, useState, lazy, Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { Amplify } from 'aws-amplify';
import awsExports from './aws-exports';
import reportWebVitals from './reportWebVitals';
import ReactGA from 'react-ga4';
import SplashScreen from './SplashScreen';
import '@aws-amplify/ui-react/styles.css';
import SplashScreenStart from './SplashScreenStart';
import App from './App';

Amplify.configure(awsExports);
ReactGA.initialize('G-MZ8TY9S3H5');

const SendAnalytics = () => {
  ReactGA.send({
    hitType: 'pageview',
    page: window.location.pathname,
  });
};

const DelayedAppRendering = () => {
  const [showApp, setShowApp] = useState(false);

  useEffect(() => {
    const delay = 5000; // 5 seconds delay

    const timer = setTimeout(() => {
      setShowApp(true);
    }, delay);

    return () => clearTimeout(timer);
  }, []);

  if (!showApp) {
    return <SplashScreenStart />;
  }

  return <App />;
};

ReactDOM.render(
  <div className="mx-auto my-auto" style={{ overflowX: 'hidden', overflowY: 'hidden', maxWidth: window.innerWidth, display: 'flex' }}>
    
      <DelayedAppRendering />

  </div>,
  document.getElementById('root')
);

reportWebVitals(SendAnalytics);
