import React, { useEffect, useState } from 'react';

import {CDBFooter, CDBBtn,CDBBox, CDBIcon, CDBContainer } from 'cdbreact';
import footerLogo from '../assets/images/footerLogo.svg'
import { BrowserRouter as Router, Route, Routes, useNavigate, Redirect, Link, Navigate,NavLink } from 'react-router-dom';
import Image from 'react-bootstrap/Image';
import newLogo from '../assets/banner/newLogo.svg'
export default function MobileFooter() {
 
  return (
    <CDBFooter color="transparent" style={{margin:'auto'}}>
    <CDBBox display="block" flex="column" className="mx-auto py-5" style={{ width: '90%' ,margin:'auto'}}>
    <CDBBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        style={{ width: '100%' }}
        className="mx-auto mt-4 mb-4"
      >
      
          <a href="https://www.linkedin.com/company/chieve/"  target="_blank" rel="noopener noreferrer"style={{color:'white',textDecoration:'none',background:'transparent'}}>
        <CDBBtn flat color="transparent" style={{background:'transparent',border:'0px'}} >
      

        <CDBIcon fab icon="linkedin" size="lg"/>

         
        </CDBBtn>
        </a>

        
        <a href="https://twitter.com/Chieve14" 
        target="_blank" rel="noopener noreferrer" style={{color:'white',textDecoration:'none',background:'transparent'}}>
        <CDBBtn flat color="transparent" style={{background:'transparent',border:'0px'}} >
        
          <CDBIcon fab icon="twitter" size="lg"/>
        
        </CDBBtn>
        </a>
        <a href="https://www.instagram.com/chieve99/"  target="_blank" rel="noopener noreferrer" style={{color:'white',textDecoration:'none',background:'transparent'}}>
        <CDBBtn flat color="transparent" style={{background:'transparent',border:'0px'}}>
   
          <CDBIcon fab icon="instagram" size="lg"/>
        
        </CDBBtn>

        </a>
      </CDBBox>
      <CDBBox display="block" justifyContent="between" className="flex-wrap">
   
        <CDBBox>
         
          <CDBBox flex="column" display="flex" style={{ cursor: 'pointer', paddingTop: '5px',margin:'auto' }}>
            <a href="/FAQ" style={{color:'white',textDecoration:'none',margin:'auto',marginTop:'5px'}}>FAQ</a>
            
            <a href="/ContactUs" style={{color:'white',textDecoration:'none',margin:'auto',}}>Contact</a>

            <a href="https://www.chieve.xyz/" target="_blank" rel="noopener noreferrer" style={{color:'white',textDecoration:'none',margin:'auto',}}>More Info</a>
          </CDBBox>
        </CDBBox>
        <CDBBox>
        
          <CDBBox display="flex" flex="column" style={{ cursor: 'pointer', paddingTop: '5px',margin:'auto', }}>
            <a href="/Privacy" style={{color:'white',textDecoration:'none',margin:'auto',marginTop:'5px'}} >Privacy Policy</a>
            <a href="/Terms" style={{color:'white',textDecoration:'none',margin:'auto',marginTop:'5px'}}>Terms Of Service</a>
            <a href="/Troubleshoot" style={{color:'white',textDecoration:'none',margin:'auto',marginTop:'5px'}}>Troubleshoot</a>
          </CDBBox>
      
        </CDBBox>
        
      </CDBBox>
    
      <p className="mx-auto mt-4" style={{ width: '90%' ,color:'white',textAlign:'center',fontSize:'12px'}}>
           Copyright &copy; 2023 Chieve. All Rights Reserved.
        </p>
    </CDBBox>
  </CDBFooter>
  );
}