import React, { useState, useEffect } from 'react';

import '../styles/setUp.css';
import Image from 'react-bootstrap/Image';
import awsExports from '../aws-exports';
import { DataStore } from '@aws-amplify/datastore';
import { Amplify, Auth,API, Hub ,Logger,Storage } from "aws-amplify";
import { BrowserRouter as Router, Route, Routes, useNavigate, Redirect, Link, Navigate,NavLink } from 'react-router-dom';
import { User,Club,ClubUser } from '../models';
Storage.configure({ track: true, level: "public" });
Amplify.configure(awsExports);



const AccountsetUp = (props) =>{
 
  const [formState, setFormState] = useState("SetUpBanner");
    const [CurrentUser, setUser] = useState();
    const [Newusername, setUsername] = useState('');
    const [wallet, setWallet] =useState(null);
    const [Userbio, setBio] =useState(null);
    const [UserImage, setImage] =useState(null);
    const [UserBanner, setBanner] =useState(null);
    const [GetUserImage, getImage] =useState(null);
    const[windowSize,setSize]= useState(props.screenSize)
    useEffect(() => {
      console.log(props.screenSize)
      setSize(props.screenSize)
      try {
     
  
        Auth.currentAuthenticatedUser({
          bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        }).then(user => {
          setUsername(user.attributes.preferred_username);
          setUser(user)
         
          if(user.attributes["custom:wallet"]){
            setWallet(user.attributes["custom:wallet"]);

          }
          if(formState ==="SetUpUserPic"){
            Storage.get("Users/"+user.username+"/Banner.png",{
                   level:'public'
               }).then((r) =>{
                 if(r){
                   setBanner(r)
                 }
               })}
               if(formState==="createBio"){
                   Storage.get("Users/"+user.username+"/Pro.png",{
                   
                   level:'public'
               
               }).then((r) =>{
                 if(r){
                   setImage(r)
                 }
               })}
          
          // TBD
        }).catch(err => console.log(err));
      }
      catch (e) {
        console.log(e);
      }
     
      
    }, []);
    function onChange(e) {
      
        setBio(e.target.value )
        console.log("submit ",Userbio)
      }
    

     
    let fileInput = React.createRef();
    let fileInput2 = React.createRef();
const onOpenFileDialog = () => {
  fileInput.current.click();
};

const onOpenFileDialog2 = () => {
  fileInput2.current.click();
};

const onProcessFile2 = e2 => {
  e2.preventDefault();
  let reader = new FileReader();
  let file = e2.target.files[0];
  try {
    reader.readAsDataURL(file);
  } catch (err) {
    console.log(err);
  }
  reader.onloadend = () => {
    
    setBanner(e2.target.files[0]);
    Storage.put("Users/"+CurrentUser.username+"/Banner.png", file, {
      
      contentType: e2.target.files[0].type
    })
   
      .then(result =>ChangeFormState("SetUpUserPic"))
      .catch(err => console.log(err));
     
  };
 
};
    const onProcessFile = e => {
      e.preventDefault();
      let reader = new FileReader();
      let file = e.target.files[0];
      try {
        reader.readAsDataURL(file);
      } catch (err) {
        console.log(err);
      }
      reader.onloadend = () => {
      
        setImage(e.target.files[0]);
        Storage.put("Users/"+CurrentUser.username+"/Pro.png", file, {
      
            contentType: e.target.files[0].type
          })
            .then(result =>ChangeFormState("createBio"))
            .catch(err => console.log(err));
            
      };
      
    };
    const ChangeFormState=async (status)=>{
   
      if(status ==="SetUpUserPic"){
        const ban = await Storage.get("Users/"+CurrentUser.username+"/Banner.png",{
              level:'public'
          })
           
            setBanner(ban)
              setFormState(status)
            
         }
          if(status==="createBio"){
          const img =    await   Storage.get("Users/"+CurrentUser.username+"/Pro.png",{
              
              level:'public'
          
          })
          setImage(img)
              setFormState(status)
          }
  }
       const getWallet =  async() =>{
        const {ethereum} = window;
    if(!ethereum){
      alert("Please install MetaMask")
    }else{
      try{
      const accounts =  await ethereum.request({method:'eth_requestAccounts'});
        console.log(accounts)
       Auth.updateUserAttributes(CurrentUser, {
          'custom:wallet': accounts[0]
        });
        const c = await DataStore.query(User, u => u.UserID("eq",props.username));
       
        await DataStore.save(
          User.copyOf(c, updated => {
            updated.Wallet= accounts[0]
          })
        );
      }catch(e){

      }
    }
      }   
       const updateUser = async() =>{
        if(UserImage){
          if(Userbio){
            const c = await DataStore.query(User, u => u.UserID("eq",CurrentUser.username));
            console.log(Userbio)
          Auth.updateUserAttributes(CurrentUser, {
            'custom:bio': Userbio
          }

         
          );
          await DataStore.save(
            User.copyOf(c[0], updated => {
              updated.bio= Userbio.toString()
            })
          );
          setFormState("DoneSettingUp")
          }else{
            alert('Please Fill in Bio')
          }
          
    }else{
      alert('Please Upload a profile Picture')
    }
    
       }







        if(formState === "SetUpBanner"){
  return(
    <div style={{height:windowSize.innerHeight,width:windowSize.innerWidth,margin:'auto',marginTop:'0px'}}>

    <div  className="webBannerSetUp" style={{width:windowSize.innerWidth,  }} >
    <input
        type="file"
        style={{ display: 'none' }}
        onChange={onProcessFile2}
        ref={fileInput2}
   
    />
  <button
  className="webBannerButton"
  onClick={() => fileInput2.current.click()}
>
  <h3 style={{fontWeight:'bold'}}>   1.Upload a banner picture </h3>
 
</button>
    </div>


    </div>
);
}

if(formState === "SetUpUserPic"){
  return(
    <div style={{height:windowSize.innerHeight,width:windowSize.innerWidth,margin:'auto',marginTop:'0px'}}>

    <div  className="webBannerUploaded" style={{width:windowSize.innerWidth,}} >
    <Image
      src={UserBanner}
      style={{ width:windowSize.innerWidth,maxHeight:'340px'}}
  />
    </div>

    <div className="webuserAvatarHolder" >
    <div style={{background:'rgba(0,0,0,.8)', borderRadius:'0px',padding:'2px'}}>
      <h3 style={{margin:'auto',color:'white',fontWeight:'bold',flexWrap:'nowrap'}}>2. Upload Profile pic</h3>
      </div>
      <input
          type="file"
          style={{ display: 'none' }}
          onChange={onProcessFile}
          ref={fileInput}
     
      />
     
      
    <button
    className="webProfileButton"
    onClick={() => fileInput.current.click()}
  >
  
  </button>
      </div>
    </div>
  )
}

if(formState==="createBio"){
  return(
    <div style={{height:windowSize.innerHeight,width:windowSize.innerWidth,margin:'auto',marginTop:'0px'}}>

    <div  className="webBannerUploaded" style={{width:windowSize.innerWidth,}} >
    <Image
      src={UserBanner}
      style={{ width:windowSize.innerWidth,maxHeight:'340px'}}
  />
    </div>

    <div className="webuserAvatarUploaded"  >
    
    
    <Image
      src={UserImage}
      style={{ display:'flex',width:'200px',maxHeight:'200px'}}
  />
  
      </div>
      <div className="userDetailsMobile">

<p id="userNameMobile"> {CurrentUser.attributes.name}</p>


  </div>
  <div className="userprefnameDetailsMobile">

<p id="userprefNameMobile"> {CurrentUser.attributes.preferred_username}</p>


</div>

<div className="userBioDetailsMobile">

<p id="userbioMobile">Add Bio*</p>


</div>
<div style={{margin:'auto',wdith:'fit-content',display:'block'}}>
<div className="userwebBioInput">


<textarea className="userwebBioInputBox"  name="bio"  onChange={onChange}/>


</div>

</div>
<button className="userBioSubmitweb " onClick={updateUser} >
<p> Submit</p>  
</button>


    </div>
  )
}
if(formState ==="DoneSettingUp"){
 
  return (
  <div  className="modal-area-setUp" style={{margin:'auto',height:windowSize.innerHeight}}>
   
  <div className="modal-body-setUp">
  
  
  <div style={{width:'50%', margin:'auto',justifyContent:'space-between',marginTop:'1%',paddingBottom:'1%',}}>
  
  <h2 style={{color:'white', fontFamily:'Poppins-Regular'}}>All Set!</h2>
  <p style={{fontFamily: 'Poppins-Regular',color: 'white',  fontWeight: '400', fontSize: '18px',display: 'flex', }}>
    Click below to finish set Up.
    </p>
  

  
  <div className="form-group" style={{display: 'flex',width:'60%', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', padding: '0px', gap: '8px',}}>
  <NavLink activeClassName="active" to="/"   style={{textDecoration:'none'}}>
    <button className="form-control btn subMitButton"  >
      Continue
    </button>
    </NavLink>
  </div>
  </div>
  <div className="formContainerConfirm">
     
      
      
      </div>
  
  </div>
  </div>
  )
}
}

export default AccountsetUp;

/*
<div  className="modal-area-setUp" >
   
    <div className="modal-body-setUp">
    
    
    <div style={{width:'90%',margin:'auto', justifyContent:'space-between',marginTop:'1%',}}>
    
    <h2 style={{color:'white', fontFamily:'Poppins-Regular'}}>Lets get set up</h2>
    <p style={{fontFamily: 'Poppins-Regular',color: 'white',  fontWeight: '400', fontSize: '18px',display: 'flex', }}>
      Please fill out all Required(*) fields.
      </p>
    
    <div className="form-group" style={{display: 'flex',width:'60%',marginTop:'2%', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', padding: '0px', gap: '8px',}}>
      <label >Choose a Profile Picture *</label>
      <input
        type="file"
        className="form-control inputs-ProPic" 
        onChange={onProcessFile}
       
       
     />
     {UserImage ?  <Image height='49px' width='53px' src={UserImage} /> : "Please Upload a Picture"}
    </div>



    <div className="form-group" style={{display: 'flex',width:'50%',marginTop:'2%', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', padding: '0px', gap: '8px',}}>
      <label >Create a bio *</label>
      <textarea className="inputsBio"  name="bio"  onChange={onChange}/>
      
    </div>










    <div className="form-group" style={{display: 'flex',width:'60%', flexDirection: 'column', justifyContent: 'center', }}>
      <button className="walletButton" onClick={getWallet} >
        <Image height='49px' width='53px' src={require('../assets/images/metaMaskSymbol.png')} />
        Connect Wallet
      </button>
    </div>


    <div className="form-group" style={{display: 'flex',width:'60%', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', padding: '0px', gap: '8px',}}>
      <button className="form-control btn subMitButton" onClick={updateUser} >
        Submit
      </button>
    </div>
    </div>
    
    
    </div>
    </div>

    **/