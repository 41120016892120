import React, {  useEffect, useState,} from 'react';

import { CDBBtn, CDBBtnGrp } from "cdbreact";



export default function BlueButton ({children,onClick,className,background,width,height}){

return(

    <CDBBtn className={className} onClick={onClick} style={{background:background?background:'#00D4C5', border:'0px',borderRadius:'100px',width:width ?width:'156px',height:'45px'}} >
           
    
        {children}


</CDBBtn>
)

}