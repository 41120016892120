import React, {  useEffect, useState,} from 'react';

import { DataStore } from '@aws-amplify/datastore';
import { User ,ClubUser,Club} from '../../models';

import awsconfig from '../../aws-exports';


import { Amplify, Auth, Hub ,API,Logger,Storage} from "aws-amplify";



export default async function UserImages(userID){
    try{
        const links = await  Storage.get("Users/"+userID.username+"/Pro.png",{

        })
        console.log(links)
        return links
    }catch(err){
        return null
    }

     
    }