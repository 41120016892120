import React, { useState, useEffect } from "react";
import '../splash.css'

import { Image } from 'react-bootstrap';
import { Loader } from '@aws-amplify/ui-react';

export default function Loader2(){


    return(
        <div className="mx-auto my-auto" style={{width:'1300px',display:'flex',height:window.innerHeight, maxWidth:window.innerWidth - 10,overflow:'hidden'}}>
      <div    className="mx-auto my-auto" style={{display:'flex',boxShadow: "14px 14px 14px 14px #EBEBEB",width:'400px',marginTop:'100px' }}>
 <div    className="mx-auto my-auto py-2" style={{display:'block',width:'200px',height:'100px' }}>
 <Image
    alt=""
    src={require('../assets/images/Chieve_logodark_small.png')}
    width="100%"
    style={{maxWidth:'400px',display:'flex',}}
    
    className="mx-auto mt-2"
    responsive
  />
          <div className=" mx-auto my-2" style={{display:'block',justifyContent:'center',width:'200px',height:'20px',textAlign:'center'}}>
          <Loader 
 size="small"
 variation="linear"
 emptyColor="#006E94"
 filledColor="#35CED1"
/>
            </div>
 </div>
 </div>
    </div>
    )
}