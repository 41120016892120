import React, { useEffect, useState } from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { BrowserRouter as Router, Route, Routes, useNavigate, Redirect, Link, Navigate,NavLink } from 'react-router-dom';
import { Flex, View } from "@aws-amplify/ui-react";
export default function MobileExplore() {
 
  return (
   <div>


   </div>
  );
}