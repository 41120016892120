import React, {  useEffect, useState,} from 'react';

import { DataStore } from '@aws-amplify/datastore';
import { User ,ClubUser,Club} from '../../models';
import { createAvatar } from '@dicebear/core';
import { botttsNeutral } from '@dicebear/collection';
import awsconfig from '../../aws-exports';

import axios from 'axios';

import { Amplify, Auth, Hub ,API,Logger,Storage} from "aws-amplify";
import UserImages from './UserImages';

Storage.configure({ track: true, level: "public" });

export default   function AvatarGenerator(userID){
          
                var randName = ["Kiki","Bella","Cleo","Molly","Nala","Snuggles","Sammy","Baby","Annie"];
                const randomSeed = Math.floor(Math.random() * randName.length);
                axios.get('https://api.dicebear.com/6.x/bottts-neutral/svg?seed='+randName[randomSeed]+'') // Adjust the URL as needed (e.g., male/female, options)
                .then((response)=>{
                    const avatarData = response.data;

                    Storage.put("Users/"+userID+"/Pro.png",avatarData, {
                        contentType: 'image/svg+xml'
                    }).catch((err)=>{
                      console.log(err)
                    }).then(result => {
                        console.log('Avatar uploaded successfully!');
                        console.log('Public URL:', result.key);
                        UserImages(userID)
                      })
                      .catch(error => {
                        console.error('Error uploading the avatar:', error);
                      });
                }) .catch(error => {
                    console.error('Error fetching the avatar:', error);
                  });
                
                 
           
                
         

          
  
}
