import React, {useState, useEffect} from 'react';
import { DataStore } from '@aws-amplify/datastore';
import { User } from '../models';
import { Amplify, Auth, Storage } from "aws-amplify";
import awsconfig from '../aws-exports';

import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../styles/fonts/fonts.css'
import '@aws-amplify/ui-react/styles.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import SplashScreen from '../SplashScreen';

Amplify.configure({...awsconfig, ssr: true});
Storage.configure({ track: true, level: "public" });




function UserSettings2(props){

    const [loading,setLoading]=useState(true)
    const [currentUser,setUser]=useState(null)
    const [currentUserProfilePic,Setcurrentpropic]=useState(null)
    const [currentUserBanner,setCurrentUserBanner]=useState(null)
    const [currentBio,setCurrentBio] = useState('')
    useEffect(()=>{
        setLoading(true)
    
        getUserData()

      
    },[])

    function getUserData(){
        setUser(props.props)
        Auth.currentAuthenticatedUser({
            bypassCache: true  
            // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
          }).then(user => {
            setUser(user)
            console.log(user)
            Storage.get("Users/"+user.username+"/Pro.png", {
                level: "public",
                
              }).then((r) => {
               
                  Setcurrentpropic(r)
            
                
              }).catch(err=>console.log(err))
              Storage.get("Users/"+user.username+"/Banner.png", {
                level: "public",
                
              }).then((r) => {
            
                    setCurrentUserBanner(r)
    
                
              }).catch(err=>console.log(err))
                DataStore.query(User, u=> u.UserID("eq",user.username)).then((u)=>{
                    setCurrentBio(u[0].bio)
                 
                }).catch(err=> console.log(err))
                setLoading(false)
          }).catch((err)=>{ 
            setLoading(false)
            console.log(err)})
      
    }

    return(
    <div className="mx-auto my-auto"style={{display:'block',width:window.innerWidth,height:window.innerHeight}}>

          <Container className="mx-auto my-4"style={{maxWidth:'1300px',dispay:'flex'}}>
            <Row className="mx-auto my-4 p-4"style={{dispay:'flex',background:'red'}}>
              <Col  className="mx-auto px-2 bg-dark col-2"style={{dispay:'flex',width:'25%'}}>
             

              </Col>

              <Col className="mx-auto px-2 bg-light col col"style={{dispay:'flex',width:'75%'}}>
                <div className="mx-auto my-auto"style={{height:'529px',width:'529px',borderRadius:'8px',background:'#00000040'}}>

                </div>

              </Col>
            </Row>
          </Container>

    </div>

    
    )


}

export default UserSettings2;