import React, { useEffect, useState,Suspense } from 'react';
import SplashScreen from '../SplashScreen';
import { DataStore } from '@aws-amplify/datastore';
import { User, Club, ClubUser,ClubPost,NFTCollection,TourneySignUp } from '../models';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
import { SelectField } from '@aws-amplify/ui-react';
import { Badge, ListGroup, Modal, ModalFooter } from 'react-bootstrap';
import { BrowserRouter, Route, Routes, useNavigate, Redirect, Link, NavLink } from 'react-router-dom';
import { CDBBtn, CDBContainer,CDBIframe, CDBRating, CDBSpinner ,CDBView,CDBCard,CDBCardBody, CDBBtnGrp, CDBNavbar, CDBNavItem, CDBProgress, CDBInput} from "cdbreact";
import { Amplify, Auth, Hub, API, Logger, Storage } from "aws-amplify";
import { Network, Alchemy } from "alchemy-sdk";
import BlueButton from '../screens/components/BlueButton';
import NoCollectibles_placeholder from "../assets/icons/Colored/NoCollectibles_placeholder.svg"
import '../styles/fonts/fonts.css'
import awsconfig from '../aws-exports';
import polygonicon from '../assets/images/polygonicon.svg';
import wallet from '../assets/icons/Outline/white/wallet.svg'
import FirstPlaceMedal from '../assets/icons/FirstPlaceMedal.svg'
import '@aws-amplify/ui-react/styles.css';
import Popup from 'reactjs-popup';
Amplify.configure({ ...awsconfig, ssr: true });
Storage.configure({ track: true, level: "public" });
export default function MintPage(props){
    const[load, setLoad]= useState(true)
    const [collectionData,setData]=useState([])
    const [clubData,setClubData]=useState([])
    const [collectionSecret,setSecret]=useState()
    const [logo,setLogo]=useState()
    const [currentUser,setUser] = useState()
    const [userMember,setMember] = useState(false)
    const [userMember2,setMember2] = useState(false)
    const [userJoined,setJoined] = useState(false)
    const [payLink,setLink]=useState(null)
    const [investors,setOwners]=useState(null)
    const [formInputState, setformInputState] = useState({ console: '',console_username: '' });
    const [modalShow, setModalShow] = useState(false);
    const [consoleType,setConsole] = useState('')
useEffect(()=>{
    console.log(props)
setLoad(true)
getCollData()

 },[])
 const navigate = useNavigate();
 function onChange(e) {
    setformInputState({ ...formInputState, [e.target.name]: e.target.value })

    console.log(formInputState)
  }
 const settings = {
    apiKey: "judyKIq12rtyO-zasR-253fFj13tXf6S", // Replace with your Alchemy API Key.
    network: Network.MATIC_MAINNET, // Replace with your network.
  };
  //1cafdf8d-3ff9-444c-9a19-84d3e184c7ec

  // club addef8b4-7c6e-403d-a1b5-ba922e375894
  const alchemy = new Alchemy(settings);
async function getCollData(){
    setData(props.collectionInfo)
    setLink(props.collectionInfo.payoutLink)
 
   DataStore.query(Club, cID => cID.id('eq', props.collectionInfo.clubID)).then((data)=>{
       
        setClubData(data[0])

      
        Auth.currentAuthenticatedUser({ bypassCache: true }).then((userInfo)=>{
             
            if(userInfo){ 
                       setUser(userInfo)
                       DataStore.query(ClubUser).then((checker)=>{
                        if(props.props != null){
                          checker.forEach((k)=>{
                            if(k.club.ClubStorageID == data[0].ClubStorageID){
                            if(k.user.UserID == userInfo.username){
                       
                                setMember(true)
                              
                                
                            }
                        }
                        if(k.club.id.toLowerCase() == "be357ced-b89c-4e80-99b2-a4765bc3c7b3"){
                            if(k.user.UserID == userInfo.username){
                       
                                setMember2(true)
                              
                                
                            }
                        }
                        })
                    
                        }
                      
                   })
                   
    DataStore.query(TourneySignUp,ts => ts.tourneyName('eq', props.collectionInfo.name)).then( (ch)=>{
      
  
            let tempOwners = []
            ch.forEach((enters)=>{
                    if(enters != null){
                        console.log(enters)
                        tempOwners.push(enters)
                        if(enters.userID == userInfo.attributes.sub){
                            setJoined(true)
                        }
                        setOwners(tempOwners)
                    }
            })
 
    
  
      
    }).catch((err)=>{
        setLoad(false)
        console.log(err)
    })
                }
                    
          }).catch((err)=>{
            DataStore.query(TourneySignUp,ts => ts.tourneyName('eq', props.collectionInfo.name)).then( (ch)=>{
      
  
                let tempOwners = []
                ch.forEach((enters)=>{
                        if(enters != null){
                            console.log(enters)
                            tempOwners.push(enters)
                          
                            setOwners(tempOwners)
                        }
                })
     
        
      
          
        })
            console.log(err)
          })
            Storage.get("Clubs/"+data[0].ClubStorageID+"/Collections/"+props.collectionInfo.name.replaceAll(' ','')+"/Secret.png",{}).then((secret)=>{
                setSecret(secret)
            }).catch((err)=>console.log(err));
            Storage.get("Clubs/"+data[0].ClubStorageID+"/ClubLogo.png",{}).then((cL)=>{
                setLogo(cL)
            }).catch((err)=>console.log(err));
            setLoad(false)
   }).catch((err)=>{
    console.log(err)
    setLoad(false)
   })
}

const joinE4c = ()=>{
    alert("Please join the E4c: Final salvation club to join this tournament")
    navigate("/E4CFinalSalvation")
}

const enterTourney=()=>{
    if(!userMember2 && collectionData.id.toLowerCase() == "1cafdf8d-3ff9-444c-9a19-84d3e184c7ec"){
          alert("Please join the E4c: Final salvation club to join this tournament")
            navigate("/E4CFinalSalvation")
    }else{
        if(props.props || currentUser){
            if(consoleType!= ''){
                setLoad(true)
        
                DataStore.save(
                     new TourneySignUp({
                         "clubName": clubData? clubData.name:'',
                         "tourneyName":collectionData? collectionData.name: props.collectionInfo.name,
                         "userID":currentUser? currentUser.attributes.sub: props.props.attributes.sub,
                         "userEmail": currentUser? currentUser.attributes.email: props.props.attributes.email,
                         "Username": currentUser? currentUser.attributes.preferred_username: props.props.attributes.preferred_username,
                         "console": consoleType,
                         "consoleUsername": formInputState.console_username,
                         "WalletAddress": currentUser? currentUser.attributes["custom:wallet"]: props.props.attributes["custom:wallet"],
                     })
                 ).then(()=>{
                     alert("You have signed up!")
                     setJoined(true)
                     if(investors){
                                let k = investors
                                k.push({
                                    "clubName": clubData? clubData.name:'',
                                    "tourneyName":collectionData? collectionData.name: props.collectionInfo.name,
                                    "userID":currentUser? currentUser.attributes.sub: props.props.attributes.sub,
                                    "userEmail": currentUser? currentUser.attributes.email: props.props.attributes.email,
                                    "Username": currentUser? currentUser.attributes.preferred_username: props.props.attributes.preferred_username,
                                    "console": consoleType,
                                    "consoleUsername": formInputState.console_username,
                                    "WalletAddress": currentUser? currentUser.attributes["custom:wallet"]: props.props.attributes["custom:wallet"],
                                })
                                setOwners(k)
                     }else{
                        setOwners([{
                            "clubName": clubData? clubData.name:'',
                            "tourneyName":collectionData? collectionData.name: props.collectionInfo.name,
                            "userID":currentUser? currentUser.attributes.sub: props.props.attributes.sub,
                            "userEmail": currentUser? currentUser.attributes.email: props.props.attributes.email,
                            "Username": currentUser? currentUser.attributes.preferred_username: props.props.attributes.preferred_username,
                            "console": consoleType,
                            "consoleUsername": formInputState.console_username,
                            "WalletAddress": currentUser? currentUser.attributes["custom:wallet"]: props.props.attributes["custom:wallet"],
                        }])
                     }
                    
                     setLoad(false)
                 }).catch((err)=>{
                     alert("Error Signing up, please try again")
                     setLoad(false)
                 })
            }else{
            alert('please fill out console type')
            }
        }else{
            alert("Please Sign up/ login to enter the tournament")
        }
    }
   
 
 
}

function userList(){
 
if(investors){
    return(
        <ul className="mt-2"style={{height:'100%',display:'block'}}>
        {investors.map((ut)=>{
         
            return(
                <>
                <li className="ParagraphP2Regular" style={{color:'white'}}>{ut.consoleUsername}</li>
               
                </>
    
            )
        })

        }
            
        </ul>
    )
}else{
    return(
        <ul style={{paddingTop:'15px',paddingBottom:'40px',borderTop:'1px solid #00D4C5' ,}}>
       
        <li className="ParagraphP2Regular" style={{color:'white'}}>Be the first to enter!</li>
        
            
        </ul>
    )
}


}
const setModalShower=(val)=>{
       
    setModalShow(val)
  }


const styles = {
            main:{
                width:window.innerWidth,
                maxWidth:'1320px',
                height:'100%',
                display:'block',
                margin:'auto'
            },
            rows:{
                width:'100%',
                maxWidth:'1320px',
                display:'flex',
                flexDirection:'row',
                
            },
            cols:{
                width:'100%',
                maxWidth:'670px',
                display:'block',
                flexDirection:'column',
                height:'568px',
                maxHeight:'568px',
               
                margin:'64px 40px 40px '
            },
            colsUtil:{
                width:'100%',
                maxWidth:'1300px',
                display:'block',
                flexDirection:'column',
                
               
                margin:'84px 40px 40px '
            },
        imgContainer:{
            maxWidth: '345x',
             maxHeight: '345px',
             display:'flex',
            margin:'64px 129px 53px 46px',
            
        
        },
      line:{
        width:'620px',
        height:'1px',
        backgroundColor:'#00D4C5'
      },
      buttonsmall: {
        "width": "109px",
        "height": "46px",
    
        "display": "flex",
        "flexDirection": "row",
        "justifyContent": "flex-start",
        "alignItems": "flex-start",
        "gap": "8px",
        "padding": "6px 16px",
        "borderRadius": "8px",
        border:'0px',
        marginRight:'5px',
        backgroundColor: payLink? "#0e16d9":"#0e16d9"
      },

        }

        if(load){
            return(
                <SplashScreen />
            )
        }
      
        if(!load){
        
            return(
                <div style={styles.main}>
                    <div style={styles.rows}>
                    <div style={styles.cols}>
                            <div style={styles.imgContainer}  >
                            <Image src={collectionSecret} className="mx-auto my-auto" style={{maxHeight:'345px' , maxWidth:'345px',display:'flex'}}/>
                            
                            </div>
                            {collectionData.winners ?
                            
                        
                            <div className="p-2 my-4 "style={{display:'block',height:'auto',maxHeight:'245px',overflowY:'auto', width:'345px',border:'0px', background:'#00000040',borderRadius:'20px'}}>
                            <h6 style={{color:'white'}} className="HeadingH6Semibold mt-2 ms-2">Winners</h6>
                                    <ol style={{width:'100%',}}>
                                 {  collectionData.winners.map((ut,index)=>{
                                    
                                  
                                    return(
                                       
                                   
                                            <li className="ParagraphP2Regular" style={{color:'white',display:'flex'}}>
                                        {index == 0 ?   <>
                                            <Image src={FirstPlaceMedal} className='my-auto me-2' style={{display:'flex',objectFit:'contain',width:'20px'}}/> <strong style={{fontWeight:'bold'}}> {ut.name} </strong> 
                                        </>
                            : 
                            <>
                        {index+1}: {ut.name} 
                        </>
                        }
                                            </li>
                                            
                                        
                                                )
                                   
                                     
                                    })
                                    }
                                     
                                    </ol>
                            </div> 
                            :
                            ''
                        }
                            
                        </div>

                        <div style={styles.cols}>
                        <div style={{height:'60px',width:'75%',display:'flex'}}>
                                <Image src={logo} className="me-2"style={{maxWidth:'60px', maxHeight:'60px',borderRadius:'12px'}}/>
                                <p style={{color:'white'}} className="ms-2 my-auto ParagraphP1Semibold">{clubData.name}</p>
                        </div>
                      
                        <div className="mt-4" style={{width:'620px',display:'block',borderTop:'1px solid #00D4C5',}}>
                        <h5 style={{color:'white'}} className="HeadingH5Semibold mt-4" >{collectionData.name}</h5>
                        <h6 className="HeadingH6Semibold mt-4" style={{width:'90%',fontSize:'14px',fontWeight:'400px',color:'#00D4C5', }}>About</h6>
                       
                                <p style={{color:'white',width:'620px'}} className="ParagraphP2Regular">{collectionData.description}</p>         

                               
                        </div>
                        <div className="mt-5 p" style={{width:'620px',display:'flex',borderBottom:'1px solid #00D4C5',borderTop:'1px solid #00D4C5',paddingTop:'40px',paddingBottom:'40px' }}>
                        <div className="my-auto " style={{width:'40%',height:'32px',display:'flex', }}>
                        <h5 style={{color:'white'}} className="HeadingH5Semibold my-auto " >Entry fee</h5>
                                <Image src={polygonicon} className="my-auto ms-5 me-2" style={{maxHeight:'32px', maxWidth:'37px',display:'flex'}}/>
                                <h5 style={{color:'white'}} className="HeadingH5Semibold my-auto " >{collectionData.price}</h5>
                            </div>
                          
                          {collectionData.finished ?     <a className=" ms-auto my-auto"  
                        style={{textDecoration:'none', color:'white'}}>
           

             <CDBBtn  style={{...styles.buttonsmall,background:'rgba(128,128,128,.18)'}} color="light" onClick={()=>{alert("Sorry! This tournament has already ended.")}} >
                        <p style={{color:'black', display:'flex'}} className=" ParagraphP2Semibold mx-auto my-auto">
                                    Enter
                               
                         </p>
                       </CDBBtn>
             </a >  :    
              userJoined ?
                    
                <a className=" ms-auto my-auto"  
                style={{textDecoration:'none', color:'white'}}>
     
     <CDBBtn  style={{...styles.buttonsmall,background:''}} color="success" onClick={()=>{alert("already entered")}} >
                <p style={{color:'white', display:'flex'}} className=" ParagraphP2Semibold mx-auto my-auto">
                           
                       Entered
                 </p>
               </CDBBtn>
     </a >
                
         :
         (!userMember2 && collectionData.id.toLowerCase() == "1cafdf8d-3ff9-444c-9a19-84d3e184c7ec" ?
         
         
         
         <CDBBtn className=" ms-auto my-auto" style={styles.buttonsmall} onClick={()=>joinE4c()} >
         <p style={{color:'white', display:'flex'}} className="ParagraphP2Semibold mx-auto my-auto">
                    
                Enter 
          </p>
        </CDBBtn> 
         
         
         
         
         :
         
         <>

<CDBBtn className=" ms-auto my-auto" style={styles.buttonsmall}  onClick={() => setModalShower(true)} >
         <p style={{color:'white', display:'flex'}} className="ParagraphP2Semibold mx-auto my-auto">
                    
                Enter 
          </p>
        </CDBBtn> 


        <Modal    
        show={modalShow}
        onHide={() => setModalShower(false)}
        centered
        scrollable
        style={{background:'#000000E5',width:'100%',}}
        >
 <Modal.Header  closeVariant='white' closeButton style={{background:'#000000E5'}}>         
  <Modal.Title style={{color:'white'}}>{collectionData.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body   style={{display:'block',background:'#000000E5',height:'400px',}}>
                <p className='mx-auto my-2 ' style={{width:'90%',color:'white',textAlign:'center'}}>
                {collectionData.description}
                </p>
        <label className=" font-weight-light">Console</label>
          
          <SelectField
          label="Console"
          labelHidden
          name="console"
          placeholder="Select your console"
          value={consoleType}
        style={{background:'white'}}
          onChange={(e) => setConsole(e.target.value)} >
                {collectionData.platforms.map((op)=>{
                    return(
                        <option value={op.type} label={op.type}>{op.type}</option>
                    )
                }
                   
                )}
          </SelectField>
          <label className=" font-weight-light">Console username</label>
          <CDBInput material hint="Username (on console)" name="console_username" type="text"onChange={onChange} />


        </Modal.Body>
        <Modal.Footer style={{background:'#000000E5'}}>
        {!currentUser || !userMember ? 

<BlueButton onClick={()=>{alert("Only club members may join")}}>
Enter
    </BlueButton>

:
<BlueButton onClick={()=>{enterTourney()}}>
Enter
</BlueButton>
        }
               
        </Modal.Footer>
      </Modal>
         </>
        
     
         )
              
              

                
             
             
             }
                               
                        </div>
                        </div>
                        </div>


                        {/** row 2 */}
                        <div style={styles.rows} className={collectionData.winners?"mt-4" : "mt-2" }>
                     

                        <div className="p-2"style={{...styles.colsUtil,height:'249px',overflowY:'auto', width:'650px',border:'0px', background:'#00000040',borderRadius:'20px'}}>
                        <h6 style={{color:'white'}} className="HeadingH6Semibold mt-2 ms-2">Rules</h6>
                                <ul style={{width:'100%',}}>
                                {collectionData.utils != null ? collectionData.utils.map((ut)=>{
                               
                                    return(
                            <li className="ParagraphP2Regular" style={{color:'white'}}>{ut.desc}</li>
                                    )
                                })

                                :
                              
                                <li className="ParagraphP2Regular" style={{color:'white'}}>TBA! Be Sure to check for updates in the club feed!</li>
                                }
                                    
                                </ul>
                        </div>
                        <div className="p-2 "style={{...styles.colsUtil,height:'249px', overflowY:'auto',width:'650px',border:'0px', background:'#00000040',borderRadius:'20px'}}>
                        <h6 style={{color:'white'}} className="HeadingH6Semibold mt-2 ms-2">Participants</h6>
                        
                              {userList()}
                        </div>
                        </div>
                </div>
            )
        }
   
}

