import React, { Component, useEffect, useState } from'react';
import { BrowserRouter as Router, Route, Routes, useNavigate, Redirect, Link, Navigate,NavLink } from'react-router-dom';
import { CDBAccordion, CDBContainer,CDBListGroup,CDBListGroupItem } from "cdbreact";
import '../assets/fonts/Poppins-Regular.ttf'
import 'bootstrap/dist/css/bootstrap.css';
import '@aws-amplify/ui-react/styles.css';

import { Alert, Divider, Expander, ExpanderItem , Placeholder, useTheme} from '@aws-amplify/ui-react';
export default function FAQ(props){
    const [windowSize,setWindowSize] = useState(props.screenSize);
    const[maxWide,setmax] = useState()
    useEffect(()=>{
      setWindowSize(props.screenSize)
      if(windowSize.innerWidth < 440){
        setmax('414px')
      }else{
        setmax('1440px')
      }
    },[])

    const styles={
        main:{
          maxWidth:maxWide,
          width:windowSize.innerWidth,
            minHeight:window.innerHeight,
            background:'white',
          margin:'auto',
          display:'block',
          padding:'10px',
          
        },
        header:{
            width:'90%',
            height:'150px',
            fontFamily:'Poppins-Regular',
            padding:'40px',
            margin:'auto',
            textAlign:'center',
          
        },
        acc:{
            width:'70%',
            padding:'10px',
            
            border:'1px solid rgba(0,0,0,.1)',
            margin:'auto',
            fontFamily:'Poppins-Regular',
            fontSize:'18px',
            borderRadius:'8px'
        }

    }
    const data = [
        {
            title:"What is Chieve ?",
              content:`Chieve is focused on web3 game discovery. Our social tournament platform
              helps to bridge web2 users into web3 native gamers which increases user acquisition for game
              developers and brands looking to enter web3. One place to be your online persona, list your
              achievements, see game updates and meet cool people that are into the same games as you are.
              Find your true squad, enter tournaments, get whitelisted and receive rewards. We’ve removed the
              blockchain barriers that exist so gamers can focus on connecting, socializing and deepening the
              experience.`
            },
        {
          title:"About Us",
          content:`We are a group of gamers, entrepreneurs, artists, and friends that came together to build something special. 
          We wanted to create spaces where people can feel connected to a group that has a similar interest as they do, a place where people can share who they are and what they like and not be judged. 
          But also, a place for people, fans to support the games they love and that makes them feel good. But web3 is a two-way street. Game developers and Brands need to give back to their fans and customers to complete the circle. 
          And this can be accomplished via Token utility.`
        
        },
       {
            title: "What is Web3 game discovery?",
          content:`We want to bring new web3 games to gamers and
          fans. We want to introduce them to the latest fun and exciting games where they can meet new
          friends and enjoy themselves.`
        },
       {
            title: "Do I need a Crypto Wallet?",
            content:`You don't need a Crypto Wallet to enter the site and view all the clubs. If
            it’s a free to enter tournament you still do not need a crypto wallet. But if
            it’s a pay to enter tournament you would need a crypto wallet because
            the tournaments are Gated and you would need to have the Tournament
            Token in your Crypto wallet to be allowed to enter the game. Tokens can
            be purchased on the tournaments page.`
          },
       {
            title: "How are prizes paid out?",
            content:"If it is a cash prize the currency that you would be paid out in is MATIC which is the currency for the Polygon Blockchain which is the blockchain Chieve uses. But depending on the country the tournament is located in and the tournament organizer cash prizes can be paid out in the local currency by the tournament organizer themselves."
        },
        
        {
          title: "Gas Fees and service charges",
          content:"Chieve is not responsible for covering gas fees or any extra charges the wallet provider may charge for transfers or conversions."
      },
   
        
    ]
    return(
        <div style={styles.main}>
            <h2 style={styles.header}>Frequently Asked Questions</h2>
             <CDBContainer style={styles.acc}>
          <CDBAccordion data={data} />
       
          <Expander className='my-2' type="multiple" style={{borderRadius:'0px',boxShadow:'0px 0px 0px 0px transparent',background:'transparent',border:'0px'}}>
  <ExpanderItem title="How do I set up Meta Mask?" value="demo-item-1" >

          <CDBListGroup >
              <CDBListGroupItem  style={{border:'0px'}}><strong style={{fontWeight:'bold'}}>1.</strong> Go to the Metamask website 
                {' '}(<a href="https://metamask.io" target="_blank" rel="noopener noreferrer" >https://metamask.io</a>)
                and click on "Download" to get the extension for your browser.</CDBListGroupItem>
              <CDBListGroupItem  style={{border:'0px'}}><strong style={{fontWeight:'bold'}}>2.</strong> Follow the installation instructions and click "Add Extension" when prompted.</CDBListGroupItem>
              <CDBListGroupItem  style={{border:'0px'}}><strong style={{fontWeight:'bold'}}>3.</strong> Once installed, click on the Metamask icon on your browser toolbar and follow the instructions to create a new account.</CDBListGroupItem>
              <CDBListGroupItem  style={{border:'0px'}}><strong style={{fontWeight:'bold'}}>4.</strong> After you create your account, you can start using Metamask to manage your cryptocurrency transactions.</CDBListGroupItem>
              
            </CDBListGroup>
  </ExpanderItem>
  <ExpanderItem title="How do I add the Polygon Network to my Metamask?" value="demo-item-2">
  <CDBListGroup>
      <CDBListGroupItem  style={{border:'0px'}}><strong style={{fontWeight:'bold'}}>
        1.</strong> Click on the Metamask icon on your browser toolbar and click on the three dots in the top right corner of the Metamask window.
        </CDBListGroupItem>
        <CDBListGroupItem  style={{border:'0px'}}><strong style={{fontWeight:'bold'}}>
        2.</strong> Select "Settings" from the drop-down menu.
        </CDBListGroupItem>
        <CDBListGroupItem  style={{border:'0px'}}><strong style={{fontWeight:'bold'}}>
        3.</strong> In the Settings menu, click on "Networks."
        </CDBListGroupItem>
        <CDBListGroupItem  style={{border:'0px'}}><strong style={{fontWeight:'bold'}}>
        4.</strong> Scroll down to the bottom of the page and click on "Add Network."
        </CDBListGroupItem>
        <CDBListGroupItem  style={{border:'0px'}}><strong style={{fontWeight:'bold'}}>
        5.</strong> Enter the following information to add the Polygon network:<br/>
        <strong className="ms-5" style={{fontWeight:'bold'}}>Network Name: </strong>Polygon Mainnet<br/>
        <strong className="ms-5" style={{fontWeight:'bold'}}>RPC URL: </strong> https://polygon-rpc.com/<br/>
        <strong className="ms-5" style={{fontWeight:'bold'}}>Chain ID: </strong>137<br/>
        <strong className="ms-5" style={{fontWeight:'bold'}}>Symbol: </strong>MATIC<br/>
        <strong className="ms-5" style={{fontWeight:'bold'}}>Block Explorer URL: </strong>https://polygonscan.com<br/>
        <strong className="ms-5" style={{fontWeight:'bold'}}>Click "Save" to add the Polygon network to your Metamask wallet.</strong>
        </CDBListGroupItem>
        <CDBListGroupItem  style={{border:'0px'}}><strong style={{fontWeight:'bold'}}>
        6.</strong> To switch to the Polygon network, click on the network dropdown in the Metamask window and select "Polygon" from the list of networks.
        </CDBListGroupItem>
      
            </CDBListGroup>
  </ExpanderItem>
  <ExpanderItem
    title="How do I convert MATIC token to USD/local currency?"
    value="demo-item-3"
  >
    <CDBListGroup>
    <CDBListGroupItem  style={{border:'0px'}}>
    You will need to convert your MATIC tokens to a cryptocurrency that is supported by a cryptocurrency exchange that offers bank withdrawals (Sites like coinbase offer withdrawals to apps like coinbase). 
    Once you have converted your tokens, you can withdraw the funds to your bank account.
        </CDBListGroupItem>
    <CDBListGroupItem  style={{border:'0px'}}><strong style={{fontWeight:'bold'}}>
        1.</strong> Log in to your cryptocurrency exchange account that supports MATIC, such as Binance, Kraken, or Coinbase.
        </CDBListGroupItem>
        <CDBListGroupItem  style={{border:'0px'}}><strong style={{fontWeight:'bold'}}>
        2. For PayPal</strong> Convert your MATIC tokens to a cryptocurrency that is supported by an exchange that offers PayPal withdrawals, such as Bitcoin or Ethereum.
        </CDBListGroupItem>
        <CDBListGroupItem  style={{border:'0px'}}><strong style={{fontWeight:'bold'}}>
        3.</strong> Once you have converted your tokens, withdraw the funds to your exchange account.
        </CDBListGroupItem>
        <CDBListGroupItem  style={{border:'0px'}}><strong style={{fontWeight:'bold'}}>
        4.</strong> From your exchange account, withdraw the funds to your bank account by following the exchange's instructions for bank withdrawals
        </CDBListGroupItem>
        <CDBListGroupItem  style={{border:'0px'}}>
        <strong style={{fontWeight:'bold'}}>
        It's important to note that Chieve does not cover any cryptocurrency exchange fees or Bank withdrawal fees that may apply. 
        Additionally, the availability of PayPal withdrawals may vary depending on your country of residence and the exchange you use.
        </strong>
        </CDBListGroupItem>
    </CDBListGroup>
  </ExpanderItem>
</Expander>
       
    </CDBContainer>
        </div>
    )
}