import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import '../assets/fonts/Poppins-Regular.ttf'



export default function Terms(props){
console.log(props)
const [windowSize,setWindowSize] = useState(props.screenSize);
const[maxWide,setmax] = useState()
useEffect(()=>{
  setWindowSize(props.screenSize)
  if(windowSize.innerWidth < 440){
    setmax('414px')
  }else{
    setmax('1440px')
  }
},[])
  const styles={
    main:{
      maxWidth:maxWide,
      width:windowSize.innerWidth,
     
      margin:'auto',
      display:'block',
      padding:'10px',
  
    },
    body:{
      width:'100%',
      margin:'auto',
     
      padding:'10px',
      height:'90%'
    },
    header:{
      width:'100%',
      height:'150px',
      fontFamily:'Poppins-Regular',
      padding:'40px',
      margin:'auto',
      textAlign:'center',
      background:'transparent'
    },
    terms:{
      width:'90%',
     
      fontFamily:'Poppins-Regular',
      padding:'20px',
      margin:'auto',
      textAlign:'center',
     
    },
    sections:{
      width:'90%',
      fontWeight:'bold',
      textAlign:'left',
      fontFamily:'Poppins-Regular',
      color:'white',
      marginTop:'10px',
      marginBottom:'10px'
    },
    para:{
      width:'70%',
      fontSize:'17px',
     
      fontWeight:'500',
      textAlign:'left',
      color:'white',
      fontFamily:'Poppins-Regular',
    }
  }
    return(
      <div style={styles.main}>
        <div style={styles.body}>
          <div style={styles.header}>
          <h1 style={{color:'white',bottom:'0'}}>Terms and Conditions</h1>
          </div>
      <div style={styles.terms}>
      <h3 style={styles.sections}>Intro</h3>
        <p style={styles.para}>
        These Terms of Service (“Terms”) apply when you are accessing any of the Symbiotic Wealth
         (“SW”) products and services including the Chieve website and the Chieve application (the “Services”).

        </p>
      <h3 style={styles.sections}>Acceptance of Terms</h3>
      <p style={styles.para}>
      By accessing or using the Service, you agree that you have read and understood and accept to be bound by the Terms. 
      You also acknowledge and agree to our Privacy Policy available {' '}
      <NavLink  to="/Privacy"   style={{textDecoration:'none'}}>
          https://www.chieve.xyz/Privacy.
         </NavLink>
 {' '}If you don’t agree to the Terms, you should not use the Services. SW reserves the right, in its sole discretion, to modify, alter or otherwise change these Terms at any time. Changes and/or modifications shall become effective immediately upon the posting. You are solely responsible to review the Terms periodically.

        </p>

      <h3 style={styles.sections}>Eligibility and Compliance with laws</h3>

      <p style={styles.para}>
      You must be 18 years or older to use the Services. You hereby represent and warrant that you are fully able and competent to enter into the terms, conditions, obligations, representations and warranties set forth in these Terms and to abide by and comply with these Terms. You further represent and warrant that you will comply with all applicable laws, at all times while using the Services. 
      SW is not liable for your compliance with such laws. You are responsible for keeping your SW account credential confidential, secret and secured.
</p>
      <h3 style={styles.sections}>Intellectual Property</h3>

      <p style={styles.para}>
      Other than the content you own, under these Terms, SW and/or its licensors own all the intellectual property rights and materials 
      contained in the Service (“SW Content”). You are granted limited license only for purposes of viewing the material contained on this Service.
</p>
      <h3 style={styles.sections}>Restrictions</h3>
      <p style={styles.para}>
      You are specifically restricted from all the following:
      <li>
     Selling, sublicensing and/or otherwise commercializing any SW Content;
      </li>

<li>
     	Using the Service in any way that impacts other users access to the Services;  </li>
<li>
     	Using the Service in any way that may cause harm to SW or to any other person or business entity;  </li>
<li>
     	Disassemble, decompile or otherwise reverse engineer any software or other technology included in the content or used to provide the Services;  </li>
<li>
     	Transmit, collect, or access personally identifiable information about other users without the consent of those users and SW;  </li>
<li>
     	Engage in unauthorized spidering, “scraping,” data mining or harvesting of content, or use any other unauthorized automated means to gather data from or about the Services;  </li>
<li>
     	Impersonate any person or entity or otherwise misrepresent your affiliation or the origin of materials you transmit;  </li>
<li>
     	Remove, avoid, interfere with, or otherwise circumvent any access control measures for the Services, including password-protected area; and  </li>
<li>
     	Access any portion of the Services that we have not authorized you to access (including password-protected areas), link to password-protected areas, attempt to access or use another user’s account or information, or allow anyone else to use your account or access credentials.  </li>

</p>

      <h3 style={styles.sections}>Your Content</h3>
      <p style={styles.para}>
      “Your Content” shall mean any audio, video, text, images or other material you choose to display on the Service. By displaying or uploading Your Content, you grant SW a non-exclusive, worldwide irrevocable, sub licensable license to use, reproduce, adapt, publish, translate and distribute it in any and all media. Your Content must be your own and must not be invading any third-party's rights. You must not use any of Your Content in any manner that is, attempts to, or is likely to be libelous, defamatory, indecent, vulgar or obscene, pornographic, sexually explicit
or sexually suggestive, racially, culturally, or ethnically offensive, harmful, harassing,
intimidating, threatening, hateful, objectionable, discriminatory, or abusive. SW reserves the right to remove any of Your Content from the Service at any time without notice.

</p>

      <h3 style={styles.sections}>Links</h3>
      <p style={styles.para}>
      The Services provide, or third parties may provide, links to other World Wide Web or accessible sites, applications or resources. Because SW has no control over such sites, applications and resources, you acknowledge and agree that SW is not responsible for the availability of such external sites, applications or resources, and does not endorse and is not responsible or liable for any content, advertising, products or other materials on or available from such sites or resources. SW is not responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or services available on or through any such site or resource.
</p>

      <h3 style={styles.sections}>No warranties</h3>
      <p style={styles.para}>
      Your access to and use of the Services or any content thereof are at your own risk. You understand and agree that the Services are provided to you on an “AS IS” and “AS AVAILABLE” basis. Without limiting the foregoing, to the maximum extent permitted under applicable law, SW and its affiliates, related companies, officers, directors, employees, agents, representatives, partners, and licensors DISCLAIM ALL WARRANTIES AND CONDITIONS, WHETHER EXPRESS OR
IMPLIED, OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-
INFRINGEMENT. SW and its affiliates, related companies, officers, directors, employees, agents, representatives, partners, and licensors make no warranty or representation and disclaim all responsibility and liability for: (i) the completeness, accuracy, availability, timeliness, security or reliability of the Services or any content thereof; (ii) any harm to your computer system, loss of data, or other harm that results from your access to or use of the Services or any content thereof; (iii) the deletion of, or the failure to store or to transmit, any content and other communications maintained by the Services; and (iv) whether the Services will meet your requirements or be available on an uninterrupted, secure, or error-free basis. No advice or information, whether oral or written, obtained from SW will create any warranty or representation not expressly made herein.

</p>

      <h3 style={styles.sections}>Limitation of liability</h3>
      <p style={styles.para}>
      TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, SW AND ITS AFFILIATES, DIRECTORS AND OFFICERS SHALL NOT BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR PUNITIVE DAMAGES, OR ANY LOSS OF PROFITS OR REVENUES, WHETHER INCURRED DIRECTLY OR INDIRECTLY, OR ANY LOSS OF DATA, USE, GOODWILL, OR OTHER INTANGIBLE LOSSES, RESULTING FROM (i) YOUR ACCESS TO OR USE OF OR INABILITY TO ACCESS OR USE THE SERVICES; (ii) ANY CONDUCT OR CONTENT OF ANY THIRD PARTY ON THE
SERVICES, INCLUDING WITHOUT LIMITATION, ANY DEFAMATORY, OFFENSIVE OR ILLEGAL CONDUCT OF OTHER USERS OR THIRD PARTIES; (iii) ANY CONTENT OBTAINED FROM THE SERVICES; OR (iv) UNAUTHORIZED ACCESS, USE OR ALTERATION OF YOUR TRANSMISSIONS OR CONTENT. IN NO EVENT SHALL THE AGGREGATE LIABILITY OF SW, ITS AFFILIATES, DIRECTORS AND OFFICERS EXCEED THE GREATER OF ONE HUNDRED U.S. DOLLARS (U.S. $100.00) OR THE
AMOUNT YOU PAID SW, IF ANY, IN THE PAST SIX MONTHS FOR THE SERVICES GIVING RISE TO THE CLAIM. THE LIMITATIONS OF THIS SUBSECTION SHALL APPLY TO ANY THEORY OF LIABILITY, WHETHER BASED ON WARRANTY, CONTRACT, STATUTE, TORT (INCLUDING NEGLIGENCE) OR OTHERWISE

</p>

      <h3 style={styles.sections}>Indemnification</h3>

      <p style={styles.para}>
      You hereby indemnify to the fullest extent SW from and against any and/or all liabilities, costs, demands, causes of action, damages and expenses arising in any way related to your breach of any of the provisions of these Terms.
</p>
      <h3 style={styles.sections}>Severability</h3>
      <p style={styles.para}>
      If any provision of these Terms is found to be invalid under any applicable law, such provisions shall be deleted without affecting the remaining provisions herein.
</p>

      <h3 style={styles.sections}>Assignment</h3>
      <p style={styles.para}>
      SW is allowed to assign, transfer, and subcontract its rights and/or obligations under these Terms without any notification. However, you are not allowed to assign, transfer, or subcontract any of your rights and/or obligations under these Terms.
</p>

      <h3 style={styles.sections}>Entire Agreement</h3>
      <p style={styles.para}>
      These Terms constitute the entire agreement between SW and you in relation to your use of the Service and supersede all prior agreements and understandings.
</p>

      <h3 style={styles.sections}>Governing Law & Jurisdiction</h3>
      <p style={styles.para}>
      These Terms will be governed by and interpreted in accordance with the laws of the State of New York, and you submit to the non-exclusive jurisdiction of the state and federal courts located in New York for the resolution of any disputes.
</p>
      <h3 style={styles.sections}>Contact Us</h3>
      <p style={styles.para}>
      If you have any questions about the Terms or any other legal questions, please contact us at {' '}
      <a href="mailto:legal@chieve.xyz">
      legal@chieve.xyz
        </a>{' '}or mail us at Symbiotic Wealth, 2 Greenwich Office Park, Suite 300, Greenwich, CT, 06831, United States.
The explanations and information provided herein are only general and high-level explanations, information and samples. You should not rely on this article as legal advice or as recommendations regarding what you should actually do. We recommend that you seek legal advice to help you understand and to assist you in the creation of your Terms.

</p>
      </div>

        </div>
      </div>

    )

}