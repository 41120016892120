import React from 'react';
import {CDBFooter, CDBBtn,CDBBox, CDBIcon, CDBContainer } from 'cdbreact';
import footerLogo from '../assets/images/footerLogo.svg'
import { BrowserRouter as Router, Route, Routes, useNavigate, Redirect, Link, Navigate,NavLink } from 'react-router-dom';
import Image from 'react-bootstrap/Image';
import newLogo from '../assets/banner/newLogo.svg'
export default function NewFooter() {
  return (
  
       <CDBFooter color="transparent">
      <CDBBox display="flex" flex="column" className="mx-auto py-5" style={{ width: '90%' }}>
        <CDBBox display="flex" justifyContent="between" className="flex-wrap">
        <CDBBox display="flex" flex="column" style={{ cursor: 'pointer', padding: '0' }}>
        <small className="text-center" style={{ width: '100%' ,color:'white'}}>
             Copyright &copy; 2023 Chieve. All Rights Reserved.
          </small>
           </CDBBox>
          <CDBBox display="flex" flex="row" style={{ cursor: 'pointer', padding: '0' }}>
           
            
            
             
              <a className="mx-4"href="/Privacy" style={{color:'white',textDecoration:'none'}} >Privacy</a>
              <a  className="mx-4" href="/Terms" style={{color:'white',textDecoration:'none'}}>Terms</a>
              <a className="mx-4" href="/FAQ" style={{color:'white',textDecoration:'none'}}>FAQ</a>
              <a className="mx-4" href="/Troubleshoot" style={{color:'white',textDecoration:'none'}}>Troubleshoot</a>
                            <a className="mx-4"href="https://www.chieve.xyz/" target="_blank" rel="noopener noreferrer" style={{color:'white',textDecoration:'none'}}>More Info</a>
              <a href="/ContactUs" style={{color:'white',textDecoration:'none'}}>Contact</a>
              </CDBBox>
             
        
          
           
              <CDBBox display="flex" flex="row" style={{ cursor: 'pointer', padding: '0',width:'200px' }}>
              <a className="me-2" href="https://www.linkedin.com/company/chieve/"  target="_blank" rel="noopener noreferrer"style={{color:'white',textDecoration:'none'}}>
              <CDBBtn flat style={{borderRadius:'100px',overflow:'hidden',border:'0px',background:'#0EA0A2',width:'37px',height:'37px',display:'flex'}} >
        

          <CDBIcon fab icon="linkedin"  size="lg" className="mx-auto my-auto" style={{display:'flex'}}/>
  
           
          </CDBBtn>
          </a>


          <a className="me-2"  href="https://twitter.com/Chieve14" target="_blank" rel="noopener noreferrer" style={{color:'white',textDecoration:'none'}}>
          <CDBBtn flat style={{borderRadius:'100px',overflow:'hidden',border:'0px',background:'#0EA0A2',width:'37px',height:'37px',display:'flex'}} >
          
            <CDBIcon fab icon="twitter" size="lg" className="mx-auto my-auto" style={{display:'flex'}}/>
          
          </CDBBtn>
          </a>
          <a className="me-2"  href="https://www.instagram.com/chieve99/"  
          target="_blank" rel="noopener noreferrer" style={{color:'white',textDecoration:'none'}}>
          <CDBBtn flat style={{borderRadius:'100px',overflow:'hidden',border:'0px',background:'#0EA0A2',width:'37px',height:'37px',display:'flex'}} >
     
            <CDBIcon fab icon="instagram" size="lg" className="mx-auto my-auto" style={{display:'flex'}}/>
          
          </CDBBtn>

          </a>

          </CDBBox>
        </CDBBox>
    
      </CDBBox>
    </CDBFooter>
  );
};
